import useSWR from "swr";
import api from "../services/newServer";

export function useFetchSwr<Data = any, Error = any>(
	url: string,
	header: string
) {
	const { data, error } = useSWR<Data, Error>(url, async (url) => {
		const response = await api.get(url, {
			headers: { id: header },
		});

		return response.data;
	});

	return { data, error };
}
