import React, { useContext, useMemo, useState } from "react";
import {
	GoogleMap,
	LoadScript,
	Marker,
	StandaloneSearchBox,
} from "@react-google-maps/api";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Geocode from "react-geocode";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import useGeoLocation from "../hooks/useGeoLocation";
interface GoogleMapsProps {
	header: string;
	googleKey: string;
	handleBackGoogleMaps(): void;
	onClose(): void;
}

const containerStyle = {
	width: "100wh",
	height: "100vh",
};
const libraries = ["places"];
const GoogleMaps: React.FC<GoogleMapsProps> = ({
	header,
	googleKey,
	handleBackGoogleMaps,
	onClose,
}) => {
	const loc = useGeoLocation();
	const { handleAdressConfirm, handleGetDelivery, position } =
		useContext<OrderContextType>(OrderContext);
	const memoizedDefaultBounds = useMemo(() => {
		const defaultBounds = {
			north: loc.coordinates ? loc.coordinates.lat + 0.1 : position.latitude,
			south: loc.coordinates ? loc.coordinates.lat - 0.1 : position.latitude,
			east: loc.coordinates ? loc.coordinates.lng + 0.1 : position.longitude,
			west: loc.coordinates ? loc.coordinates.lng - 0.1 : position.longitude,
		};
		return defaultBounds;
	}, [loc, position]);

	const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
	const [map, setMap] = useState<google.maps.Map>();
	const [marker, setMarker] = useState<google.maps.Marker>();

	const handleAction = async () => {
		const adressGeocode = await getReverseGeocodingData(
			map.getCenter().lat(),
			map.getCenter().lng()
		);
		const local = {
			latitude: map.getCenter().lat(),
			longitude: map.getCenter().lng(),
		};
		const resp = await handleGetDelivery(header, local);
		if (resp) {
			handleAdressConfirm(adressGeocode.address_components);
			onClose();
		}
	};

	const onMapLoad = (map: google.maps.Map) => {
		setMap(map);
	};

	const onMarkerLoad = (marker: google.maps.Marker) => {
		setMarker(marker);
	};

	const onLoad = (ref: google.maps.places.SearchBox) => {
		setSearchBox(ref);
	};

	const getReverseGeocodingData = async (lat, lng) => {
		try {
			let address;
			Geocode.setApiKey(googleKey);
			await Geocode.fromLatLng(lat, lng).then(
				(response) => {
					address = response.results[0];
				},
				(error) => {
					console.error(error);
				}
			);
			return address;
		} catch (err) {
			console.log(err);
		}
	};

	const onPlacesChanged = () => {
		const places = searchBox!.getPlaces();
		const place = places![0];
		const location = {
			lat: place?.geometry?.location?.lat() || 0,
			lng: place?.geometry?.location?.lng() || 0,
		};
		marker.setPosition(location);
		map?.panTo(location);
	};
	return (
		<div className="container-googlemaps">
			<LoadScript
				googleMapsApiKey={googleKey}
				//@ts-ignore
				libraries={libraries}
			>
				<div className="container-input-autocomplet">
					<StandaloneSearchBox
						onLoad={onLoad}
						onPlacesChanged={onPlacesChanged}
						bounds={memoizedDefaultBounds}
					>
						<input placeholder="Digite um endereço" autoComplete="off"></input>
					</StandaloneSearchBox>
				</div>
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={
						loc.coordinates
							? loc.coordinates
							: { lat: position.latitude, lng: position.longitude }
					}
					zoom={17}
					onLoad={onMapLoad}
					onDrag={() => marker.setPosition(map.getCenter())}
				>
					<Marker position={map?.getCenter()} onLoad={onMarkerLoad}></Marker>
				</GoogleMap>
				<div className="container-button-autocomplet">
					<button
						className="btn btn-googlemaps back"
						onClick={handleBackGoogleMaps}
					>
						<AiOutlineArrowLeft size={30} className="icon-input" />
					</button>
					<button className="btn btn-googlemaps" onClick={handleAction}>
						CONFIRMAR ENDEREÇO
					</button>
				</div>
			</LoadScript>
		</div>
	);
};

export default React.memo(GoogleMaps);
