import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";

import Alert from "../components/Alert";
import Loading from "../components/Loading";
import QuestionItem from "../components/QuestionItem";
import IncreaseDecreaseButtons from "../components/IncreaseDecreaseButtons";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import ComboContext, { ComboContextType } from "../contexts/ComboContext";
import ProductContext, { ProductContextType } from "../contexts/ProductContext";

import "./styles.css";
import { animationSwing, formatValue, incryptText, decryptText, calculeTotal } from "../utils/functions";
import HeaderDefault from "../components/HeaderDefault";
import FloatingContainer from "../components/FloatingContainer";
import ComposicaoDetailsProduct from "../components/ComposicaoDetailsProduct";
import ListHeader from "../components/ListHeader";
import { Answer } from "../utils/types";

const ProductDetails: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header, id, combo, tipo, defaultId, random, edit, editar } = useParams();

	const {
		info,
		ficha,
		getInfo,
		indexHorario,
		globalError,
		createGlobalError,
		clearGlobalErrors,
	} = useContext<AppContextType>(AppContext);
	const {
		product,
		productContextError,
		getProductById,
		getProductByIdPergunta,
		clearProduct,
		clearProductContextError,
	} = useContext<ProductContextType>(ProductContext);
	const { editProductToComboItens, addProductToComboItens, itens } =
		useContext<ComboContextType>(ComboContext);
	const { addProductToCart } = useContext<OrderContextType>(OrderContext);

	const [quant, setQuant] = useState<number>(1);
	const [compTotal, setCompTotal] = useState<number>(0);
	const [obs, setObs] = useState<string>("");
	const [isRestaurantClose, setIsRestaurantClose] = useState<boolean>(false);
	const [showOnlyCardapio, setShowOnlyCardapio] = useState<boolean>(false);
	const [showAmountModal, setShowAmountModal] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);

	const handleAction = async () => {
		setLoading(true);
		const response = await getInfo(header);
		if (response.deliveryDate[indexHorario]?.aberto && !(ficha && info.apenas_visualizar_produtos_smart > 0)) {
			if (response.disponivel) {
				let productByQuestion = [];
				let aswersWithProduct: Answer[] = [];
				let productIncorporado: Answer[] = [];
				const observations =
					product.observacoes.length > 0
						? product.observacoes.filter((item) => item.isChecked)
						: [];
				const questions =
					product.perguntas.length > 0
						? product.perguntas.filter(
							(item) => item.id_resposta_selecionada > 0
						)
						: [];
				let notAnsweredQuestions;
				let idPerguntaObrigatoria;
				for (const item of product.perguntas) {
					const test = item.answers.filter((item2) => {
						return item2.isChecked;
					});

					let aswersUtilizaProduct = test.filter(
						(aswer) => aswer.utiliza_produto > 0
					);

					const newAswers = aswersUtilizaProduct.map((aswer) => {
						if (combo) { // Sempre que for combo vai ser incorporado
							aswer.incorporar = 1
						} else if (item.incorporar > 0) {
							aswer.incorporar = item.incorporar;
						}
						return aswer;
					});

					aswersWithProduct = [...newAswers, ...aswersWithProduct];
					if (
						(test.length > 0 && test.length >= item.minimo_escolhas) ||
						!item.obrigatorio
					) {
						notAnsweredQuestions = true;
					} else {
						notAnsweredQuestions = false;
						idPerguntaObrigatoria = item.id_pergunta;
						break;
					}
				}
				if (aswersWithProduct.length > 0) {
					for (const aswer of aswersWithProduct) {
						const respProduct = await handleAddProductByQuestion(
							aswer,
							product.id
						);
						if (respProduct.tipo) {
							productIncorporado = [...productIncorporado, respProduct];
						} else {
							productByQuestion = [...productByQuestion, respProduct];
						}
					}
				}
				if (notAnsweredQuestions || product.perguntas?.length === 0) {
					if (combo) {
						if (edit) {
							let newProduct: any = product;
							newProduct.adicionais = [...newProduct.adicionais, ...productIncorporado]

							editProductToComboItens(
								newProduct,
								combo,
								quant,
								random,
								compTotal,
								obs,
								observations,
								questions,
							);
							clearProduct();
						} else {
							let newProduct: any = product;
							newProduct.adicionais = [...newProduct.adicionais, ...productIncorporado]

							addProductToComboItens(
								newProduct,
								combo,
								quant,
								random,
								compTotal,
								obs,
								observations,
								questions,
							);
						}
						history.push(`/${header}/combo/${tipo}/${defaultId}`);
					} else {
						addProductToCart(
							product,
							quant,
							compTotal,
							obs,
							observations,
							questions,
							productByQuestion,
							productIncorporado
						);
						history.push(`/${header}/+itens/1`);
						localStorage.setItem("@CHEF:scroll", `item-${product.id}`);
					}
				} else {
					animationSwing("pergunta-" + idPerguntaObrigatoria);
				}
				setLoading(false);
			} else {
				createGlobalError(
					`No momento não estamos conseguindo atender novos pedidos. Tente novamente mais tarde.`
				);
			}
		} else if (ficha && info.apenas_visualizar_produtos_smart > 0) {
			setLoading(false);
			setShowOnlyCardapio(true);
		} else {
			setLoading(false);
			setIsRestaurantClose(true);
		}
	};

	const handleAddProductByQuestion = async (answer: Answer, id: number) => {
		let produtoIncorporado;
		const product = await getProductByIdPergunta(
			header,
			String(answer.id_produto)
		);
		let temp: any = { ...product };

		if (answer.preco_fixo > 0) {
			temp.valor = answer.preco_produto;
			temp.total = answer.preco_produto;
		} else {
			temp.total = temp.valor;
		}
		if (answer.incorporar > 0) {
			produtoIncorporado = {
				descricao_insumo: product.nome,
				id_produto: id,
				id_produto_insumo: product.id,
				isChecked: true,
				tipo: "ADICIONAL",
				valor: temp.total,
				prefixo_resposta: answer.prefixo_impressao
			};
			return produtoIncorporado;
		}
		temp.quant = answer.qtdade ? answer.qtdade : 1;
		temp.comp_total = 0;
		temp.custom_obs = "";
		temp.observacoes = [];
		temp.questions = [];
		return temp;
	};

	const handleToggle = (value: number, removed: boolean, qtdade?: number) => {
		if (qtdade)
			value = value * qtdade;

		if (removed) {
			//evita que retire se o valor for zero
			if (compTotal !== 0) setCompTotal((prev) => prev - value);
		} else {
			setCompTotal((prev) => prev + value);
		}
	};

	useEffect(() => {
		(async () => {
			await getInfo(header);
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const handleGoBack = () => {
		clearGlobalErrors();
		history.push(`/${header}`);
	};

	const handleGoBackInformacoes = () => {
		setIsRestaurantClose(false);
		history.push(`/${header}/Informacoes`);
	};

	const handleIncrease = (onIncrease: boolean) => {
		if (onIncrease) {
			if (product?.unidade?.toLocaleUpperCase() === "KG") {
				setQuant(Number((quant + 0.100).toFixed(3)));
			} else {
				setQuant(quant + 1);
			}
		} else {
			if (product?.unidade?.toLocaleUpperCase() === "KG") {
				setQuant(quant === 0.100 ? 0.100 : Number((quant - 0.100).toFixed(3)));
			} else {
				setQuant(quant === 1 ? 1 : quant - 1);
			}
		}
		animationSwing("valueAnimation");
	};

	const handleSetQuantity = (value: any) => {
		if (value > 0)
			setQuant(parseInt(value));
	}

	const handleNewAmount = (value: any) => {
		console.log(value);
		if (value < 0.100) {
			setQuant(0.1);
		} else {
			setQuant(Number(Number(value).toFixed(3)));
		}
		animationSwing("valueAnimation");
	}

	useEffect(() => {
		//Se estiver editando cria o session estorage
		if (combo) {
			sessionStorage.setItem("@CHEF:itensCombo", incryptText(JSON.stringify(itens)));
			const alreadyHaveItens = sessionStorage.getItem("@CHEF:itensCombo");
			if (alreadyHaveItens && edit) {
				const decryptedItens = decryptText(alreadyHaveItens);
				const itensComboObj = JSON.parse(decryptedItens);
				const itemSelected = itensComboObj.filter((value) => Number(value.id) === Number(id));
				setObs(itemSelected[0]?.custom_obs);
			}
		}
		const checkIsEditando = () => {
			let editando = null;
			if (editar) {
				editando = {
					editando: true,
					item: editar
				}
				sessionStorage.setItem("@CHEF:editando", incryptText(JSON.stringify(editando)));
				//Recupera as informacoes do carrinho
				const cartItens = sessionStorage.getItem("@CHEF:cart");
				if (cartItens) {
					const cartItensObj = JSON.parse(decryptText(cartItens));
					const arrayIdProdutoVinculado = []; //Array de ids vinculados
					const answersIndexesArray = [];
					//Percorre as perguntas e respostas e adiciona os ids que estiverem marcados
					cartItensObj[editar].perguntas.forEach(pergunta => {
						if (pergunta.incorporar === 0) {
							pergunta.answers.forEach(resposta => {
								if (resposta.isChecked) arrayIdProdutoVinculado.push(resposta.id_produto);
							});
						}
					});
					//percorre e verifica se o array de ids inclui o id vinculado
					cartItensObj.forEach((item, index) => {
						if (arrayIdProdutoVinculado.includes(item.id)) {
							answersIndexesArray.push(index);
						}
					});
					setQuant(cartItensObj[editar].quant);
					setObs(cartItensObj[editar].custom_obs);
					const editandoWithIndexes = { ...editando, answersIndexes: answersIndexesArray }
					if (answersIndexesArray.length > 0) sessionStorage.setItem("@CHEF:editando", incryptText(JSON.stringify(editandoWithIndexes)));
				}
			} else {
				if (!tipo) {
					sessionStorage.removeItem("@CHEF:editando");
					sessionStorage.removeItem("@CHEF:itensCombo");
				}
			}
		};
		//FIM

		(async () => {
			try {
				if (combo) {
					getProductById(header, id, combo);
				} else {
					getProductById(header, id);
				}
				checkIsEditando();
			} catch (err) {
				createGlobalError(err);
			}
		})();
		return () => {
			clearProduct();
			clearGlobalErrors();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="Voltar"
					onClose={handleGoBack}
				/>
			</div>
		);
	}
	return (
		<div className="ProductDetails">
			{loading && <Loading />}
			{!product && <Loading />}
			<HeaderDefault
				label="DETALHES DO PRODUTO"
				onClick={() => history.goBack()}
			/>
			{productContextError && (
				<Alert
					message={productContextError}
					danger={true}
					button="Ok"
					onClose={clearProductContextError}
				/>
			)}

			{isRestaurantClose && (
				<Alert
					message={"No momento estamos fechados!"}
					danger={true}
					button="Ok"
					onClose={handleGoBackInformacoes}
				/>
			)}
			{showOnlyCardapio && (
				<Alert
					message={"Envio de pedidos disponível apenas para delivery."}
					danger={false}
					button="Ok"
					onClose={() => setShowOnlyCardapio(false)}
				/>
			)}
			<div
				className={`container ${product?.perguntas.length === 0 && product?.descricao === ""
					? "all"
					: "default"
					}`}
			>
				<div className="col">
					{product?.url_imagem && !combo ? (
						<img
							src={product?.url_imagem}
							className="image"
							alt={product?.descricao}
						/>
					) : null}

					<div className="content">
						<p className="nome-product">{product?.nome}</p>
						{product?.descricao !== "" && (
							<p className="description">{product?.descricao}</p>
						)}
						<p className="valor">{!combo ? formatValue(product?.preco_voamenu > 0 ? product.preco_voamenu : product?.valor) : formatValue(product?.valor)}</p>
					</div>

					{product?.perguntas && product?.perguntas.length > 0 && (
						<>
							{product?.perguntas.map(
								(item, index) =>
									item.answers.length > 0 && (
										item.incorporar > 0 ?
											<ComposicaoDetailsProduct
												key={index.toString()}
												product={item}
												label={item.descricao}
												onToggle={handleToggle}
											/> :
											<QuestionItem
												key={index.toString() + 1}
												item={item}
												index={index}
											/>
									)
							)}
						</>
					)}
				</div>

				<div className="col">
					{product?.opcionais && product?.opcionais.length > 0 && (
						<ComposicaoDetailsProduct
							product={product.opcionais}
							label="OPCIONAIS"
							label2="Remova aquilo que você não deseja"
							onToggle={handleToggle}
						/>
					)}
					{product?.adicionais && product?.adicionais.length > 0 && (product?.adicionais?.filter((e) => e.disponivel_voamenu === 1)?.length > 0 || ficha) && (
						<ComposicaoDetailsProduct
							product={ficha ? product.adicionais : product.adicionais.filter((e) => e.disponivel_voamenu === 1)}
							label="ADICIONAIS"
							onToggle={handleToggle}
						/>
					)}
					{product?.observacoes && product?.observacoes.length > 0 && (
						<ComposicaoDetailsProduct
							product={product.observacoes}
							label="OBSERVAÇÕES"
							onToggle={handleToggle}
						/>
					)}
					{(!ficha || info?.apenas_visualizar_produtos_smart !== 1) && <>
						<ListHeader label1="ALGUM COMENTÁRIO?" details={true} />
						<div className="content">
							<div className="textarea">
								<textarea
									id="description"
									placeholder="(Opcional)"
									value={obs?.toUpperCase()}
									onChange={(event) => {
										if (event.target.value.length <= 200)
											setObs(event.target.value)
									}}
								></textarea>
							</div>
						</div>
					</>}
				</div>
			</div>
			{showAmountModal &&
				<div className="Alert">
					<div className="box kg-modal">
						<span>Quantidade ({product?.unidade}):</span>
						<input
							type="number"
							placeholder={quant.toString()}
							onChange={(e) => handleNewAmount(e.target.value)}
						/>
						<button className="success" style={{ backgroundColor: info?.cor_padrao }} onClick={() => setShowAmountModal(false)}>Confirmar</button>
					</div>
				</div>
			}
			{(!ficha || info?.apenas_visualizar_produtos_smart !== 1) &&
				<FloatingContainer
					disable={loading}
					onClick={product ? () => handleAction() : () => { }}
					label={combo ? "ESCOLHER" : "ADICIONAR"}
					total={!combo ? calculeTotal(product, quant, compTotal) : ((product?.valor > 0 ? product.valor : 0) + compTotal) * quant}
					animation="valueAnimation"
				>
					{!combo ? (
						<IncreaseDecreaseButtons
							unidade={product?.unidade}
							setShowAmountModal={() => setShowAmountModal(!showAmountModal)}
							value={quant}
							onIncrease={() => handleIncrease(true)}
							onDecrease={() => handleIncrease(false)}
							changeQuantity={handleSetQuantity}
						/>
					) : (
						<div></div>
					)}
				</FloatingContainer>
			}
		</div>
	);
};

export default ProductDetails;
