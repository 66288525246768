import React from "react";
import { Category } from "../utils/types";

interface MenuProps {
	products: Category[];
	activeLink: number;
	onClickActive(id: number): void;
}

const Menu: React.FC<MenuProps> = ({
	products,
	activeLink,
	onClickActive
}) => {
	return (
		<div className="menu" id="#menu">
			{products
				.filter((item) => item.products.length > 0)
				.map((item, index) => (
					<a
						id={`menu${item.id_grupo}`}
						href={`#${item.id_grupo}`}
						className={`nav-link ${activeLink === item.id_grupo ? "active" : null
							}`}
						key={index.toString()}
						onClick={() => onClickActive(item.id_grupo)}
					>
						{item.alias || item.descricao}
					</a>
				))}
		</div>
	);
};

export default Menu;
