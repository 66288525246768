import { Cart, Combo, Order } from "./types";
import CryptoJS from "crypto-js";

//Functions de criptografia
export const incryptText = (text:string) => {
		if(!text || text === "") return null;
        // Encrypt
        const ciphertext = CryptoJS.AES.encrypt(text, 'voatro123voamenu').toString();
        return ciphertext;    
}

export const decryptText = (ciphertext:string) => {
		if(!ciphertext || ciphertext === "") return null;
        // Decrypt
        const bytes  = CryptoJS.AES.decrypt(ciphertext, 'voatro123voamenu'); // a frase enha precisa ser igual a do incrypt
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
}
//FIM

export const formatValue = (value: number = 0): string => {
	return value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
};

export const formatHour = (time: string): string => {
	const formated = time.substr(0, 5);
	return formated.replace(":", "h");
};
export const formatPhoneWhatsapp = (numberPhone: string): string => {
	const number = numberPhone
		?.replace("(", "")
		.replace(")", "")
		.replace(" ", "")
		.replace("-", "");
	return number;
};

export const phoneMask = (value: string): string => {
	let r = value.replace(/\D/g, "");
	r = r.replace(/^0/, "");

	if (r.length > 10) {
		r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
	} else if (r.length > 5) {
		r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
	} else if (r.length > 2) {
		r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
	} else {
		r = r.replace(/^(\d*)/, "($1");
	}

	return r;
};

export const getCurrentTime = (): string => {
	const date = new Date();

	var secs: any = date.getSeconds();
	var minu: any = date.getMinutes();
	var hour: any = date.getHours();

	if (secs < 10) secs = "0" + secs;
	if (minu < 10) minu = "0" + minu;
	if (hour < 10) hour = "0" + hour;

	return `${hour}:${minu}:${secs}`;
};

export const createCartArray = (cart: Array<Cart>): Promise<Array<Cart>> => {
	return new Promise((resolve) => {
		const local = cart.map((el) => {
			let el_novo: Cart = {
				...el,
				adicionais: [],
			};

			if (el_novo.custom_obs) {
				el_novo.custom_obs = "";
			}
			if (el.perguntas) {
				el.perguntas.forEach((question) => {
					let compare_id = 0;
					question.answers.forEach((answer) => {
						if (answer.isChecked) {
							el_novo.custom_obs += `${
								question.incorporar > 0 ?
									!answer.prefixo_impressao
										? !answer.id_produto && `${answer.descricao_resposta}`
										: `${answer.prefixo_impressao} ${answer.descricao_resposta}`
								:
								compare_id !== question.id_pergunta
									? `${
											!answer.prefixo_impressao
												? `${question.descricao}${
														question.obrigatorio ? "*" : ""
												  }${"" + answer.descricao_resposta}`
												: `${answer.prefixo_impressao} ${answer.descricao_resposta}`
									  }`
									: ` ${answer.descricao_resposta}`
							
								}${question.incorporar > 0 && !answer.prefixo_impressao ?"" : `\n`}`;
							compare_id = question.id_pergunta;
						}
					});
				});
			}
			if (el.custom_obs) {
				el_novo.custom_obs += `OBS.: ${el.custom_obs}\n`;
			}
			if (el.observacoes) {
				el.observacoes.forEach((item) => {
					el_novo.custom_obs += `OBS.: ${item.descricao}\n`;
				});
			}
			if (el.opcionais) {
				el.opcionais.forEach((item) => {
					if (!item.isChecked) {
						el_novo.custom_obs += `REMOVER: ${item.descricao_insumo}\n`;
					}
				});
			}
			if (el.adicionais) {
				for (const add of el.adicionais) {
					if (add.isChecked && (add.prefixo_resposta === "" || !add.prefixo_resposta)) {
						el_novo.custom_obs += `ADICIONAR: ${add.qtdade ? add.qtdade : 1}x ${add.descricao_insumo}\n`;
						el_novo.adicionais.push(add);
					}
				}
			}
			return el_novo;
		});
		return resolve(local);
	});
};

export const createComboArray = (
	combos: Array<Combo>
): Promise<Array<Combo>> => {
	return new Promise((resolve) => {
		const local = combos.map((el) => {
			if (el.custom_obs) {
				el.custom_obs = `OBS.: ${el.custom_obs}\n`;
			}
			const getSaboresId = sessionStorage.getItem("@CHEF:saboresId") ? JSON.parse(sessionStorage.getItem("@CHEF:saboresId")) : null;
			const saboresPizza = el.itens.filter((sabor) => sabor.combo_id === getSaboresId[0].id);
			
			el.itens.forEach((item) => {
				const originalObs = item.custom_obs ? item.custom_obs : "";
				if (item.custom_obs) {
					item.custom_obs = "";
				}

				//Confere se e borda ou sabor e fraciona a quantidade
				if(el.tipo.toLocaleUpperCase() === "PIZZA" && getSaboresId){
					let saboresIds = []
					getSaboresId.forEach(sabor => {
						saboresIds.push(sabor.id.toString());
					});
					item.quant = saboresIds.includes(item.combo_id.toString()) ? 
					//item.quant / getSaboresId.length 
					item.quant / saboresPizza.length
					: item.quant;
				}

				if (item.perguntas) {
					item.perguntas.forEach((question) => {
						question.answers.forEach((answer) => {
							if (answer.isChecked) {
								if(!answer.prefixo_impressao && !answer.utiliza_produto){
									item.custom_obs += `${answer.descricao_resposta}\n`;
								}else if(answer.prefixo_impressao){
									item.custom_obs += `${answer.prefixo_impressao}: ${answer.descricao_resposta}\n`;
								}
							}
						});
					});
				}
				if (originalObs !== "") {
					item.custom_obs += `OBS.: ${originalObs}\n`;
				}
				if (item.observacoes) {
					item.observacoes.forEach((obs) => {
						el.custom_obs += `OBS.: ${obs.descricao}\n`;
					});
				}
				if (item.opcionais) {
					item.opcionais.forEach((comp) => {
						if (!comp.isChecked)
							item.custom_obs += `REMOVER: ${comp.descricao_insumo}\n`;
					});
				}
				if (item.adicionais) {
					item.adicionais.forEach((comp) => {
						if (comp.isChecked && (comp.prefixo_resposta === "" || !comp.prefixo_resposta)){
							item.custom_obs += `ADICIONAR: ${comp.qtdade ? comp.qtdade : 1}x ${comp.descricao_insumo}\n`;
						}
					});
				}
			});

			return el;
		});

		return resolve(local);
	});
};

export const checkOrder = (params: Order): Promise<void> => {
	return new Promise((resolve, reject) => {
		const phoneRegex =
			// eslint-disable-next-line no-useless-escape
			/^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{4,6}$/;

		const { user, deliveryType, payment, total, change, type, number } = params;
		const {
			name,
			phone,
			address,
			addressNumber,
			complement,
			city,
			id_city,
			neighborhood,
			id_neighborhood,
		} = user;

		if (type && number) return resolve();

		if (!phone) return reject("Preencha o número de telefone!");
		if (!phoneRegex.test(phone))
			return reject(
				"Preencha o número de telefone corretamente! Ex.: (00) 00000-0000"
			);
		if (!name) return reject("Preencha o seu nome!");

		if (deliveryType === "ENTREGA") {
			if (!payment) return reject("Informe o método de pagamento!");
			if (payment === "DINHEIRO" && change > 0) {
				if (change < total)
					return reject(
						"O valor do troco é menor que o total do pedido! Tente informar um valor maior"
					);
			}

			if (!address) return reject("Preencha o logradouro!");
			if (!addressNumber) return reject("Preencha o número!");
			if (!complement)
				return reject("Preencha o complemento! Ex.: Casa, referência, etc...");
			if (!city || !id_city || id_city === "none")
				return reject("Preencha a cidade!");
			if (
				!neighborhood ||
				!id_neighborhood ||
				id_neighborhood === "none" ||
				neighborhood === "none"
			)
				return reject("Preencha o bairro!");
		}

		return resolve();
	});
};

export const checkAddress = (
	address: string,
	addressNumber: string,
	addressComplement: string
): Promise<void> => {
	return new Promise((resolve, reject) => {
		if (address === "") return reject("Informe o endereço!");
		if (addressNumber === "") return reject("Preencha o número!");
		if (addressComplement === "") return reject("Preencha o complemento!");
		return resolve();
	});
};

export function justNumbers(text: any) {
	var numbers = text.replace(/[^0-9]/g, "");
	return parseInt(numbers);
}

export function minToHour(min: any) {
	var minute:any = Number(min);
	var data: any = new Date();
	var hora1 = new Date( data.getTime() + minute*60000);

	var h:any = hora1.getHours();
	var m:any = hora1.getMinutes();
	if(h < 10) h = '0'+h;
	if(m < 10) m = '0'+m;
	var hora2 = h + ":" + m
	return hora2;
}

export function formatedTime (time: any){
	const sliced = time.split(":");
	const m1 = Number(sliced[0]) <= 9 ? "0"+sliced[0]: sliced[0];
	const m2 =  Number(sliced[1]) <= 9 ? "0"+sliced[1]: sliced[1];
	return m1+":"+m2;
}

export function getDayMonth(type: any, value: any){
	var actualDate: any;
	if(type === "minutes") {
		var minute:any = Number(value);
		var data: any = new Date();
		actualDate = new Date( data.getTime() + minute*60000);
	}
	if(type === "date") {
		actualDate = new Date(value);
	} 
	
	var d = actualDate.getDate() <= 9 ? "0"+actualDate.getDate() : actualDate.getDate();
	var m = actualDate.getUTCMonth() + 1 <= 9 ? "0"+(actualDate.getUTCMonth() + 1) : (actualDate.getUTCMonth() + 1);

	var formated: any;
	formated = " "+d + "/" + m+"/"+actualDate.getFullYear()+" ";
	if(type === "minutes") formated = " "+d + "/" + m+" ";
	return formated ;
}

export function getHourAndMinutes(date: any){
	const newDate = new Date(date);
	const hora = newDate.getHours();
	const minutos = newDate.getMinutes();

	return hora + ':' + minutos;
}

export function animationSwing(id: string) {
	const el = document.getElementById(id);
	el?.scrollIntoView({ block: "center" });
	el?.animate(
		[
			// keyframes
			{ transform: "translate3d(-1px, 0, 0)" },
			{ transform: "translate3d(2px, 0, 0)" },
			{ transform: "translate3d(-4px, 0, 0)" },
			{ transform: "translate3d(4px, 0, 0)" },
			{ transform: "translate3d(-4px, 0, 0)" },
			{ transform: "translate3d(4px, 0, 0)" },
			{ transform: "translate3d(-4px, 0, 0)" },
			{ transform: "translate3d(4px, 0, 0)" },
			{ transform: "translate3d(-4px, 0, 0)" },
			{ transform: "translate3d(2px, 0, 0)" },
			{ transform: "translate3d(-1px, 0, 0)" },
		],
		{
			// timing options
			duration: 1000,
			iterations: 1,
		}
	);
}

export function capitalizeFirstLetter(string:string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const calculeTotal = (product: any, quant: any, compTotal?:any) => {
	let total: number = 0;
	let compTotalValue = compTotal ? compTotal : 0;
	if (product) {
		total = (product?.valor > 0 ? product.valor + compTotalValue : 0 + compTotalValue) * quant;
		if(product.preco_voamenu > 0){
			if(product.comp_total > 0) {
				total = (product.preco_voamenu + product.comp_total) * quant;
			}else{
				total = (product.preco_voamenu + compTotalValue) * quant;
			}
		}
		if (product.preco_utilizar_1 > 0) {
			if (quant <= product.qtd_itens_preco2) {
				switch (product.preco_utilizar_qtdade_itens) {
					case 'PREÇO 1':
						total = (product.valor + compTotalValue) * quant;
						break;
					case 'PREÇO 2':
						if(product?.comp_total > 0)
						total = (product.preco_prazo + product.comp_total) * quant;
						else
						total = (product.preco_prazo + compTotalValue) * quant;
						break;
					case 'PREÇO 3':
						if(product?.comp_total > 0)
						total = (product.preco3 + product.comp_total) * quant;
						else
						total = (product.preco3 + compTotalValue) * quant;
						break;
					case 'PREÇO 4':
						if(product?.comp_total > 0)
						total = (product.preco4 + product.comp_total) * quant;
						else
						total = (product.preco4 + compTotalValue) * quant;
						break;
					default:
						break;
				}

			}
		}

		if (product.preco_utilizar_2 > 0) {
			if (quant <= product.qtd_itens_preco2_menor && quant > product.qtd_itens_preco2_maior) {
				switch (product.preco_utilizar_qtdade_itens_2) {
					case 'PREÇO 1':
						total = (product.valor + compTotalValue) * quant;
						break;
					case 'PREÇO 2':
						if(product?.comp_total > 0)
						total = (product.preco_prazo + product.comp_total) * quant;
						else
						total = (product.preco_prazo + compTotalValue) * quant;
						break;
					case 'PREÇO 3':
						if(product?.comp_total > 0)
						total = (product.preco3 + product.comp_total) * quant;
						else
						total = (product.preco3 + compTotalValue) * quant;
						break;
					case 'PREÇO 4':
						if(product?.comp_total > 0)
						total = (product.preco4 + product.comp_total) * quant;
						else
						total = (product.preco4 + compTotalValue) * quant;
						break;
					default:
						break;
				}

			}
		}

		if (product.preco_utilizar_3 > 0) {
			if (quant <= product.qtd_itens_preco3_menor && quant > product.qtd_itens_preco3_maior) {
				switch (product.preco_utilizar_qtdade_itens_3) {
					case 'PREÇO 1':
						total = (product.valor + compTotalValue) * quant;
						break;
					case 'PREÇO 2':
						if(product?.comp_total > 0)
						total = (product.preco_prazo + product.comp_total) * quant;
						else
						total = (product.preco_prazo + compTotalValue) * quant;
						break;
					case 'PREÇO 3':
						if(product?.comp_total > 0)
						total = (product.preco3 + product.comp_total) * quant;
						else
						total = (product.preco3 + compTotalValue) * quant;
						break;
					case 'PREÇO 4':
						if(product?.comp_total > 0)
						total = (product.preco4 + product.comp_total) * quant;
						else
						total = (product.preco4 + compTotalValue) * quant;
						break;
					default:
						break;
				}

			}
		}

		if (product.preco_utilizar_4 > 0) {
			if (quant > product.qtd_itens_preco4) {
				switch (product.preco_utilizar_qtdade_itens_4) {
					case 'PREÇO 1':
						total = (product.valor + compTotalValue) * quant;
						break;
					case 'PREÇO 2':
						if(product?.comp_total > 0)
						total = (product.preco_prazo + product.comp_total) * quant;
						else
						total = (product.preco_prazo + compTotalValue) * quant;
						break;
					case 'PREÇO 3':
						if(product?.comp_total > 0)
						total = (product.preco3 + product.comp_total) * quant;
						else
						total = (product.preco3 + compTotalValue) * quant;
						break;
					case 'PREÇO 4':
						if(product?.comp_total > 0)
						total = (product.preco4 + product.comp_total) * quant;
						else
						total = (product.preco4 + compTotalValue) * quant;
						break;
					default:
						break;
				}

			}
		}
	}

	return total;
}

export const generateHash = () => {
	const timeStamp = new Date().getTime();
	const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomLetters = '';

    for (let i = 0; i < 10; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        randomLetters += alphabet[randomIndex];
    }

	return timeStamp+randomLetters;
}
