import React, { useContext, useEffect, useState } from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { BsCheckCircle, BsCheckCircleFill, BsCircle, BsCircleFill } from "react-icons/bs";
import AppContext, { AppContextType } from '../contexts/AppContext';
import OrderContext, { OrderContextType } from '../contexts/OrderContext';
import server from "../services/newServer";

interface PaymentProps {
    amount: number;
    onlineOnChange: any;
    localValue: any;
    localDisabled: any;
    localOnChange: any;
    paymentList: any;
    deliveryType: string;
}

const PaymentBrick: React.FC<PaymentProps> = ({
    amount,
    localValue,
    localDisabled,
    localOnChange,
    paymentList,
    onlineOnChange,
    deliveryType,
}) => {

    const [checkPix, setCheckPix] = useState<boolean>(false);
    const [checkCredit, setCheckCredit] = useState<boolean>(false);
    const [checkDebit, setCheckDebit] = useState<boolean>(false);

    const [listaPMSaved, setListaPMSaved] = useState([]);

    const [flags, setFlags] = useState<any>([]);
    const [selectedFlag, setSelectedFlag] = useState<string>('');

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [onlinePaymentMethods, setOnlinePaymentMethods] = useState<any>([])

    const {
        updateCardInfo,
        cardNumber,
        cardCode,
        cardExpiration,
        cardHolderName,
        cardCpf
    } = useContext<OrderContextType>(OrderContext);
    const {
        info
    } = useContext<AppContextType>(AppContext);
    initMercadoPago('TEST-ec8c845b-7a44-4cab-b404-3ce919056a47');

    useEffect(() => {
        switch (localValue) {
            case 'PIX ONLINE':
                setCheckPix(true);
                setCheckCredit(false);
                setCheckDebit(false);
                break;
            case 'CREDITO ONLINE':
                setCheckPix(false);
                setCheckCredit(true);
                setCheckDebit(false);
                checkCardIsValid('', 'credit_card');
                break;
            case 'DEBITO ONLINE':
                setCheckPix(false);
                setCheckCredit(false);
                setCheckDebit(true);
                checkCardIsValid('', 'debit_card');
                break;
            default:
                setCheckPix(false);
                setCheckCredit(false);
                setCheckDebit(false);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localValue])

    useEffect(() => {
        const onlinePayList = paymentList.filter((item) => {
            if (item.descricao_ecf === 'PIX' || item.descricao_ecf === 'CARTAO CREDITO') {
                if (item.disponivel_mercado_pago > 0)
                    return item
            }
        });
        if (onlinePayList?.length > 0)
            setOnlinePaymentMethods(onlinePayList);
    }, [])


    const formatExpiration = (text: string) => {
        if (text.length <= 2) {
            return text;
        }
        const parte1 = text.slice(0, 2);
        const parte2 = text.slice(2);

        return `${parte1}/${parte2}`;
    };

    const formatCard = (numeroString: string) => {
        numeroString = numeroString.replace(/\s/g, '');

        const grupos = [];
        for (let i = 0; i < numeroString.length; i += 4) {
            grupos.push(numeroString.substring(i, i + 4));
        }

        return grupos.join(' ');
    }

    function formatCPF(cpf: string) {
        cpf = cpf.replace(/\D/g, '');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

        return cpf;
    }

    const checkCardIsValid = async (numeroCartao: string, tipo: string) => {
        updateCardInfo('METHOD', '');
        setSelectedFlag('');
        const resp: any = listaPMSaved.length <= 0 ? await server.post("/process_payment/payment_methods", {
            auth: info?.key_mercadopago
        }) : listaPMSaved;

        const listaPM = listaPMSaved.length <= 0 ? resp.data : resp
        const meios = listaPM?.filter(f => f.payment_type_id === tipo);
        const flagsArray = [];
        let foundCard = false;
        for (const c of meios) {
            if (c?.status === "active")
                flagsArray.push(c.thumbnail);
            // eslint-disable-next-line
            c.settings.forEach(setting => {
                const pattern = new RegExp(setting.bin.pattern);
                const exclusion_pattern = new RegExp(setting.bin.exclusion_pattern);

                if (pattern.test(numeroCartao) && !exclusion_pattern.test(numeroCartao) && c?.status === 'active') {
                    updateCardInfo('METHOD', c.id);
                    setSelectedFlag(c.thumbnail);
                    foundCard = true;
                }
            });
        }

        if (!foundCard && numeroCartao?.length > 0)
            setErrorMessage('Numero de cartão invalido.')
        else
            setErrorMessage('');

        setListaPMSaved(listaPM);
        setFlags(flagsArray.sort());
    };


    return (
        <div className='payment-brick-container'>
            <div className={'payment-brick-title'}>
                <h1>{(checkPix || checkCredit) ? <BsCircleFill size={10} /> : <BsCircle size={10} />} Pagar agora</h1>
                <span>Aprovação na hora.</span>
            </div>
            <div className={info?.liberar_pedido_mediante_pagamento ? 'payment-brick-payments payment-brick-payments-2' : 'payment-brick-payments'}>
                <div>
                    {onlinePaymentMethods?.filter((e) => e.descricao_ecf === 'PIX')?.length > 0 &&
                        <div>
                            <button onClick={() => {
                                setCheckPix(!checkPix);
                                if (!checkPix)
                                    onlineOnChange('PIX ONLINE')
                                else {
                                    if (info?.liberar_pedido_mediante_pagamento)
                                        onlineOnChange('');
                                    else
                                        onlineOnChange('SELECIONAR');
                                }
                            }}>
                                <span>PIX</span>
                                {!checkPix ?
                                    <BsCheckCircle size={20} color={'#7E7E7E'} style={{ marginRight: 5 }} />
                                    : <BsCheckCircleFill size={20} color={'#7E7E7E'} style={{ marginRight: 5 }} />
                                }
                            </button>
                        </div>
                    }
                    {onlinePaymentMethods?.filter((e) => e.descricao_ecf === 'CARTAO CREDITO')?.length > 0 &&
                        <div>
                            <button onClick={() => {
                                setCheckPix(!checkCredit);
                                if (!checkCredit)
                                    onlineOnChange('CREDITO ONLINE')
                                else {
                                    if (info?.liberar_pedido_mediante_pagamento)
                                        onlineOnChange('');
                                    else
                                        onlineOnChange('SELECIONAR');
                                }
                            }}>
                                <span>CARTÃO DE CREDITO</span>
                                {!checkCredit ?
                                    <BsCheckCircle size={20} color={'#7E7E7E'} style={{ marginRight: 5 }} />
                                    : <BsCheckCircleFill size={20} color={'#7E7E7E'} style={{ marginRight: 5 }} />
                                }
                            </button>
                        </div>
                    }
                    {/*<div>
                        <button onClick={() => {
                            setCheckPix(!checkDebit);
                            if (!checkDebit)
                                onlineOnChange('DEBITO ONLINE')
                            else {
                                if (info?.liberar_pedido_mediante_pagamento)
                                    onlineOnChange('');
                                else
                                    onlineOnChange('DINHEIRO');
                            }
                        }}>
                            <span>CARTÃO DE DEBITO</span>
                            {!checkDebit ?
                                <BsCheckCircle size={20} color={'#7E7E7E'} style={{ marginRight: 5 }} />
                                : <BsCheckCircleFill size={20} color={'#7E7E7E'} style={{ marginRight: 5 }} />
                            }
                        </button>
                        </div>*/}
                </div>
            </div>

            {(checkCredit || checkDebit) &&
                <div className="credit-card-form">
                    <h3>Dados do cartão</h3>
                    <label>
                        Número do Cartão:
                    </label>
                    {flags.map((item: any, index: any) => (
                        <img
                            className={selectedFlag ? item !== selectedFlag ? 'card-flags card-not-selected' : 'card-flags' : 'card-flags'}
                            key={index}
                            src={item}
                            alt='Bandeira de cartao'
                        />
                    ))}
                    <input
                        type="numeric"
                        value={formatCard(cardNumber)}
                        onChange={(e) => {
                            const numeroString = e.target.value.replace(/\D/g, '');

                            if (numeroString.length <= 16)
                                updateCardInfo('NUMBER', numeroString);
                        }}
                        onBlur={() => {
                            checkCardIsValid(cardNumber, checkCredit ? 'credit_card' : 'debit_card')
                        }}
                    />
                    {errorMessage && <p className='card-error-message'>{errorMessage}</p>}

                    <div className="inline-fields">
                        <label>
                            Data de Vencimento:
                            <input
                                type="numeric"
                                value={cardExpiration}
                                onChange={(e) => {
                                    const expiration = e.target.value.replace("/", "");
                                    if (expiration === '') {
                                        updateCardInfo('EXPIRATION', '')
                                    } else if (/^\d+$/.test(expiration) && expiration.length <= 4) {
                                        updateCardInfo('EXPIRATION', formatExpiration(expiration))
                                    }
                                }}
                            />
                        </label>

                        <label>
                            Código de Segurança:
                            <input
                                type="numeric"
                                value={cardCode}
                                onChange={(e) => {
                                    const numeroString = e.target.value.replace(/\D/g, '');
                                    updateCardInfo('CODE', numeroString)
                                }}
                            />
                        </label>
                    </div>

                    <label>
                        Nome Completo:
                        <input
                            type="text"
                            value={cardHolderName}
                            onChange={(e) => updateCardInfo('HOLDER', e.target.value)}
                        />
                    </label>

                    <label>
                        CPF:
                        <input
                            type="text"
                            value={formatCPF(cardCpf)}
                            onChange={(e) => {
                                const cpf = e.target.value.replace(/\D/g, '');

                                if (cpf.length <= 11)
                                    updateCardInfo('CPF', cpf)
                            }}
                        />
                    </label>
                </div>
            }

            {!info?.liberar_pedido_mediante_pagamento &&
                <>
                    <div className={`payment-brick-title payment-brick-title-2`}>
                        <h1>{(!checkPix && !checkCredit && localValue !== 'SELECIONAR') ? <BsCircleFill size={10} /> : <BsCircle size={10} />} Pagar na entrega/Estabelecimento</h1>
                    </div>
                    <div className='payment-brick-payments payment-brick-payments-2'>
                        <select
                            value={localValue}
                            disabled={localDisabled}
                            onChange={localOnChange}
                        >
                            <option
                                key={0}
                                value={"SELECIONAR"}
                            >
                                SELECIONAR
                            </option>
                            {
                                paymentList.length > 0 && paymentList.map((item) => (
                                    <option
                                        key={item.id_forma_pagto}
                                        value={item.descricao}
                                        disabled={item.disponivel !== 'TODOS' ? item.disponivel === deliveryType ? false : true : false}
                                    >
                                        {item.descricao.toUpperCase()}
                                    </option>
                                ))
                            }
                        </select>

                        {/*(!checkPix && !checkDebit && !checkCredit) && <button onClick={() => { }}>
                            <span>Pagamento selecionado: {localValue}</span>
                        </button>*/}
                    </div>
                    {<div>
                        <p>Pagamento selecionado: {localValue}</p>
                    </div>}
                </>
            }
        </div>
    )
}

export default PaymentBrick;
