import React from "react";
import { FiAlertOctagon } from "react-icons/fi";

type AlertCartProps = {
	message: string;
	danger: boolean;
	onClick(): void;
	onClose(): void;
};

const AlertCart: React.FC<AlertCartProps> = ({
	message,
	danger,
	onClick,
	onClose,
}) => {
	return (
		<div className="Alert">
			<div className="box">
				<div className={`circle ${danger ? "danger" : "success"}`}>
					<FiAlertOctagon size={24} color="#fff" />
				</div>

				<p className="message">{message}</p>

				<div className="container-buttons">
					<button className={danger ? "danger" : "success"} onClick={onClick}>
						SIM
					</button>
					<button className={danger ? "danger" : "success"} onClick={onClose}>
						NÃO
					</button>
				</div>
			</div>
		</div>
	);
};

export default AlertCart;
