import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";

import Row from "../components/Row";
import Loading from "../components/Loading";

import AppContext, { AppContextType } from "../contexts/AppContext";
import { Order } from "../utils/types";

import "./styles.css";
import Alert from "../components/Alert";
import HeaderDefault from "../components/HeaderDefault";
import { IoLogoWhatsapp } from "react-icons/io";
import ListHeader from "../components/ListHeader";
import { formatedTime, formatPhoneWhatsapp, getDayMonth, capitalizeFirstLetter, formatValue } from "../utils/functions";
import SmartSuccessItem from "../components/SmartSuccessItem";
import SmartSuccessCombo from "../components/SmartSuccessCombo";
import PixArea from "../components/PixArea";
import { AiOutlineReload } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import DeliveryStatus from "../components/DeliveryStatus";

const Success: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header } = useParams();
	const {
		getOrderStatus,
		info,
		getInfo,
		setInfo,
		createGlobalError,
		globalError,
		clearGlobalErrors,
		getPayments,
	} = useContext<AppContextType>(AppContext);
	const [order, setOrder] = useState<Order>();
	const [showLoading, setShowLoading] = useState<boolean>(false);

	const [status, setStatus] = useState<string>("");

	const [id_venda_local, setIdVendaLocal] = useState<number>(0);

	const [stop, setStop] = useState<number | any>("");

	const [iniciaTimer, setIniciaTimer] = useState<number>(0);

	const [cronometro, setCronometro] = useState<number>(0);

	const [pixKey, setPixKey] = useState("");

	const [zapMessage, setZapMessage] = useState("");

	const handleUpdateStatus = async () => {
		try {
			setShowLoading(true); /* @ts-ignore */

			const resp = await getOrderStatus(header, order.id_pdv);
			setStatus(resp.status);
			setIdVendaLocal(Number(resp.id_venda_local));
			clearInterval(stop);

			setCronometro(0);
			let timer = stopRefresh();
			setStop(timer);

			setShowLoading(false);

			/* @ts-ignore */
			if (resp.missed) {
				createGlobalError(
					"Houve algum problema no recebimento do seu pedido. Favor entrar em contato com o estabelecimento."
				);
			}
		} catch (err) {
			setShowLoading(false);
		}
	};

	const handleUpdateStatusClick = async () => {
		try {
			setShowLoading(true); /* @ts-ignore */
			const resp = await getOrderStatus(header, order.id_pdv);
			setStatus(resp.status);
			setIdVendaLocal(Number(resp.id_venda_local));
			setShowLoading(false);

			/* @ts-ignore */
			if (resp.missed) {
				createGlobalError(
					"Houve algum problema no recebimento do seu pedido. Favor entrar em contato com o estabelecimento."
				);
			}
		} catch (err) {
			setShowLoading(false);
		}
	};

	const stopRefresh = () => {
		let contador: number = 0;
		let interval = setInterval(async () => {
			contador++;
			setCronometro(30 - contador);
			if (contador === 30) {
				clearInterval(interval);
				handleUpdateStatus();
			}
		}, 1000);
		return interval;
	};

	const handleNewOrder = () => {
		clearInterval(stop);
		history.push(`/${header}`);
	};

	const handleOpenWhatsApp = () => {
		const numberPhone = formatPhoneWhatsapp(info?.whatsapp);
		window.open(
			`https://api.whatsapp.com/send?phone=55${numberPhone}`,
			"_blank"
		);
	};

	// QUANDO CLICAR EM VOLTAR, VOLTA PARA TELA INICIAL
	useEffect(() => {
		const handlePopstate = () => {
			history.push(`/${header}`);
		};
		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	useEffect(() => {
		(async () => {
			try {
				setShowLoading(true);

				let localOrder: any = localStorage.getItem("@CHEF:order");

				if (!localOrder) {
					history.goBack();
					return;
				}
				localOrder = JSON.parse(localOrder);
				setOrder(localOrder);

				//CRIA TEXTO DO ZAP
				let zapItensConcatenados = "";
				localOrder.itens.forEach(element => {
					const customObsText = "\n" + element.custom_obs.toLowerCase();
					const itensText = `➡️` + element.quant + 'x ' + capitalizeFirstLetter(element.nome) + customObsText + "\n";
					zapItensConcatenados += itensText
				});
				localOrder.combos.forEach(combo => {
					const customObsText = "\n" + combo.custom_obs.toLowerCase();
					const combosText = `➡️` + combo.quant + 'x ' + capitalizeFirstLetter(combo.nome) + customObsText + "\n";
					zapItensConcatenados += combosText
					combo.itens.forEach(comboItens => {
						let itensText = `- ` + comboItens.quant + 'x ' + capitalizeFirstLetter(comboItens.nome) + "\n";
						comboItens.adicionais.forEach((add: any) => {
							if (add.isChecked) itensText += `-- ` + capitalizeFirstLetter(add.descricao_insumo) + "\n";
						});
						zapItensConcatenados += itensText
					});
				});
				let isPixOrNot: any = ``;
				//BUSCA A CHAVE PIX
				if (localOrder.payment.toUpperCase().includes("PIX")) {
					let paymentMethods = await getPayments(header);
					if (paymentMethods) {
						paymentMethods.forEach((item) => {
							if (item.descricao.toUpperCase() === localOrder.payment.toUpperCase()) {
								setPixKey(item.chave_pix);
								isPixOrNot = `*Envie seu comprovante de pagamento por aqui.*`;
							}
						})
					}
				}
				const itensZap = zapItensConcatenados;
				const statusOrder = await getOrderStatus(header, localOrder.id_pdv);
				if (statusOrder.id_venda_local) setIdVendaLocal(Number(statusOrder.id_venda_local))
				else setIdVendaLocal(Number(0))
				setZapMessage(encodeURI(
					`Pedido Nº *${id_venda_local.toString()}*\n\n` +
					`*Itens:*\n` +
					`${itensZap} \n` +
					`🛵 *Tipo:* ${capitalizeFirstLetter(localOrder.deliveryType)}\n` +
					`💳 *Pagamento:* ${capitalizeFirstLetter(localOrder.payment)}\n` +
					`Total: *${formatValue(localOrder.total)}*\n` +
					isPixOrNot
				));

				if (!info) {
					const resp1 = await getInfo(header);
					setInfo(resp1);
				}

				const resp = await getOrderStatus(header, localOrder.id_pdv);
				setStatus(resp ? resp.status : "ENVIADO");
				if (localOrder.type !== "Comanda") {
					setIniciaTimer(1);
				}
				setShowLoading(false);
			} catch (err) {
				setShowLoading(false);
			}
		})();

		return () => {
			clearInterval(stop);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id_venda_local]);

	useEffect(() => {
		let timer = stopRefresh();
		setStop(timer);

		return () => {
			clearInterval(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iniciaTimer]);

	const handleErrorPedido = () => {
		localStorage.removeItem("@CHEF:order");
		history.push(`/${header}`);
	}


	return (
		<>
			{showLoading && <Loading />}

			{status === "ERRO" && (
				<Alert
					message={"Ocorreu um erro ao realizar seu pedido! Por favor realize novamente."}
					danger={true}
					button="Ok"
					onClose={handleErrorPedido}
				/>
			)}

			{globalError && (
				<div className="default-container">
					<Alert
						message={globalError}
						danger={true}
						button="OK"
						onClose={clearGlobalErrors}
					/>
				</div>
			)}
			<div className="Cart">
				{showLoading && <Loading />}
				<HeaderDefault
					label="INFORMAÇÕES DO PEDIDO"
					onClick={() => history.push(`/${header}`)}
					id_pdv={id_venda_local}
				/>
				<div className="container">
					{!order?.type && (
						<>
							<ListHeader label1="STATUS DO PEDIDO" details={true} />
							<div className="container-statusPedido">
								<DeliveryStatus currentStep={status} delivery={order?.deliveryType === "ENTREGA" ? true : false} />
							</div>
							<button
								className="update-button"
								style={{ display: 'flex', backgroundColor: info?.cor_padrao }}
								onClick={handleUpdateStatusClick}
							>
								<AiOutlineReload style={{ alignSelf: 'center' }} size={15} color={info?.cor_padrao_texto ? info.cor_padrao_texto : 'white'} />
								<span>ATUALIZAR AGORA</span>
							</button>
							<div className="container-timer">
								{order?.type !== "Comanda" && (
									<span onClick={handleUpdateStatusClick}>
										{cronometro === 0
											? "ATUALIZANDO..."
											: `ATUALIZA EM ${cronometro}`}
									</span>
								)}
							</div>

							{(pixKey || info?.enviar_confirmacao_whatsapp) ?
								<>
									<ListHeader label1={pixKey ? "CHAVE PIX" : "ENVIAR PEDIDO PELO WHATSAPP"} details={true} />
									{id_venda_local ?
										<>
											{pixKey &&
												<PixArea chave_pix={pixKey} />
											}
											<button
												className="update-button"
												style={{ display: 'flex', backgroundColor: info?.cor_padrao }}
												onClick={() => window.open(
													`https://wa.me/55${formatPhoneWhatsapp(info?.whatsapp)}?text=${zapMessage}`,
													'_blank' // <- This is what makes it open in a new window.
												)}
											>
												<FaWhatsapp style={{ alignSelf: 'center' }} size={15} color='#25D366' />
												<span>CLIQUE AQUI</span>
											</button>
										</>
										:
										<p className="whatsappLink" > Aguardando confirmação do restaurante </p>
									}
								</>
								: null
							}
							<ListHeader label1="DADOS DO CLIENTE" details={true} />
						</>
					)}
					{!order?.type && !order?.number && (
						<>
							<div className="container-dadosClientes">
								<div className="container-nomeCliente">
									<span className="label">Para:</span>
									<span>{order?.user.name}</span>
								</div>
								<div className="container-nomeCliente">
									<span className="label">Celular:</span>
									<span>{order?.user.phone} </span>
								</div>
							</div>
						</>
					)}

					{!order?.type && !order?.number && (
						<ListHeader label1="DADOS DA ENTREGA" details={true} />
					)}

					<div className="container-dadosClientes">
						{!order?.type &&
							!order?.number &&
							order?.deliveryType === "ENTREGA" ? (
							<>
								<div className="container-nomeCliente">
									<span className="label">Tipo do Pedido:</span>
									<span>{order?.deliveryType}</span>
								</div>
								<div className="container-nomeCliente">
									<span className="label">Horario do Pedido:</span>
									<span>{formatedTime(order?.timeNow)}</span>
								</div>
								<div className="container-nomeCliente">
									<span className="label">Data do Pedido:</span>
									<span>{order?.dateNow}</span>
								</div>
								{order?.entrega_prevista && order?.entrega_prevista !== null && (
									<>
										<div className="container-nomeCliente">
											<span className="label">Entrega Prevista:</span>
											<span>
												{
													new Date(
														order?.entrega_prevista.toString()
													).getUTCHours()
														.toString()
														.padStart(2, "0") +
													":" +
													new Date(order?.entrega_prevista.toString())
														.getUTCMinutes()
														.toString()
														.padStart(2, "0")
												}
											</span>
										</div>
										<div className="container-nomeCliente">
											<span className="label">Data de Entrega:</span>
											<span>
												{getDayMonth("date", order?.entrega_prevista.toString())}
											</span>
										</div>
									</>
								)}
								<div className="container-enderecoSuccess">
									<span className="label">Endereço:</span>
									<span>
										{" "}
										{order?.user.address}, {order?.user.addressNumber} -{" "}
										{order?.user.complement} - {order?.user.neighborhood},{" "}
										{order?.user.city}
									</span>
								</div>
							</>
						) : !order?.type &&
							!order?.number &&
							order?.deliveryType === "RETIRADA" ? (
							<div className="delivery">
								<div className="container-nomeCliente">
									<span className="label">Tipo do Pedido:</span>
									<span>{order?.deliveryType}</span>
								</div>
								{order?.entrega_prevista && order?.entrega_prevista !== null && (
									<>
										<div className="container-nomeCliente">
											<span className="label">Retirada Prevista:</span>
											<span>
												{new Date(
													order?.entrega_prevista.toString()
												).getUTCHours()
													.toString()
													.padStart(2, "0") +
													":" +
													new Date(order?.entrega_prevista.toString())
														.getUTCMinutes()
														.toString()
														.padStart(2, "0")
												}
											</span>
										</div>
										<div className="container-nomeCliente">
											<span className="label">Data da Retirada:</span>
											<span>{getDayMonth("date", order?.entrega_prevista.toString())}</span>
										</div>
										<div className="container-nomeCliente">
											<span className="label">Horario do Pedido:</span>
											<span>{formatedTime(order?.timeNow)}</span>
										</div>
										<div className="container-nomeCliente">
											<span className="label">Data do Pedido:</span>
											<span>{order?.dateNow}</span>
										</div>
									</>
								)}
							</div>
						) : (
							<div></div>
						)}
					</div>
					<div>
						<ListHeader label1="RESUMO DO PEDIDO" details={true} />
						<div className="container-delivery-succes">
							{order?.type && order?.number && (
								<Row
									label={order?.type.toUpperCase()}
									data={order?.number.toString()}
								/>
							)}
							{order?.type &&
								order?.type === "Comanda" &&
								order?.number &&
								order?.mesa_vinculada &&
								Number(order?.mesa_vinculada) > 0 ? (
								<Row
									label="MESA"
									data={order?.mesa_vinculada.toString()}
									ativaGray={true}
								/>
							) : null}
							{!order?.type &&
								!order?.number &&
								order?.deliveryType === "ENTREGA" && (
									<Row
										label="TAXA DE ENTREGA"
										data={order?.taxa || 0}
										ativaGray={true}
									/>
								)}

							{!order?.type &&
								!order?.number &&
								order?.deliveryType === "ENTREGA" && (
									<Row
										label="PAGAMENTO"
										data={order?.payment}
										ativaGray={true}
									/>
								)}
							{!order?.type &&
								!order?.number &&
								order?.deliveryType === "ENTREGA" &&
								order?.payment === "DINHEIRO" &&
								order?.change > 0 && (
									<Row
										label="TROCO PARA"
										data={order?.change}
										ativaGray={true}
									/>
								)}
							<Row label="TOTAL" data={order?.total || 0} />
							{order?.desconto > 0 && <Row label="TOTAL COM DESCONTO" data={order.total - order.desconto || 0} />}
						</div>
						{order?.type &&
							(order?.type === "Mesa" || order?.type === "Comanda") &&
							order?.number ?
							(
								<div>
									<ListHeader label1="ITENS" details={true} />
									<div>
										{order?.itens ?

											order?.itens.map((el, index) => (
												<div className="paddingcart" key={index.toString()}>
													<SmartSuccessItem item={el} />
												</div>
											)) : null
										}
										{order?.combos?.length > 0 &&
											order?.combos.map((item, index) => (
												<div className="paddingcart" key={index.toString()}>
													<SmartSuccessCombo
														item={item}
													/>
												</div>
											))}
									</div>
								</div>

							) : null
						}
						<div className="container-whatsapp" onClick={handleOpenWhatsApp}>
							<IoLogoWhatsapp size={24} color="green" />
							<span>Clique aqui para chamar o estabelecimento</span>
						</div>
					</div>
				</div>
				<div className="floating-container">
					<button
						className="btn btn-primary"
						disabled={showLoading}
						onClick={() => handleNewOrder()}
					>
						NOVO PEDIDO
					</button>
				</div>
			</div>
			{/* <div className="Success">
				<div className="success-content">
					<div className="header">
						<h2>Status do Pedido</h2>
						<h2>{status}</h2>
					</div>

					<div className="content">
						{" "}
						<div className="container-preco">

						</div>
					</div>

					<div className="footer">
						<button
							className="btn btn-whatsapp"
							onClick={() =>
								window.open(
									`https://api.whatsapp.com/send?phone=55${info.whatsapp
										.replace("(", "")
										.replace(")", "")}`,
									"_blank"
								)
							}
						>
							<i className="fab fa-whatsapp fa-3x"></i>
						</button>

						{order?.type !== "Comanda" && (
							<button
								className="btn btn-secondary"
								onClick={handleUpdateStatusClick}
							>
								{cronometro === 0
									? "Atualizando..."
									: `Atualiza em ${cronometro}`}
							</button>
						)}

						<button
							className="btn btn-primary"
							onClick={() => handleNewOrder()}
						>
							Novo Pedido
						</button>
					</div>
				</div>
			</div> */}
		</>
	);
};

export default Success;
