import React, { useContext, useEffect, useState } from "react";
import ComboContext, { ComboContextType } from "../contexts/ComboContext";
import { ComboCategory } from "../utils/types";
import CartItem from "./CartItem";

interface ButtonChangeComboProps {
	item: ComboCategory;
	isPizza?: boolean;
	onClick(): void;
	hasClassName: boolean;
	index: number;
	editComboItem(id: number, combo_id: number, random: number): void;
}

const ButtonChangeCombo: React.FC<ButtonChangeComboProps> = ({
	item,
	isPizza,
	onClick,
	hasClassName,
	index,
	editComboItem,
}) => {
	const { itens, removeProductFromItens } =
		useContext<ComboContextType>(ComboContext);
	const [itemSelected, setItemSelected] = useState<any>(false);

	const handleRemoveItem = () => {
		removeProductFromItens(index, itemSelected[0].random);
		setItemSelected(false);
	};

	useEffect(() => {
		setItemSelected(false);
		const itensEtapa = itens.filter(
			(item2) => Number(item2.random) === Number(item.random)
		);
		if (itensEtapa.length > 0) setItemSelected(itensEtapa);
	}, [itens, item]);

	return (
		<div className="container-etapaCombo">
			{isPizza ? (
				<span>{item.name}</span>
			) : (
				<span>
					Etapa {index + 1} - {item.name}{" "}
				</span>
			)}

			{itemSelected ? (
				<CartItem
					item={itemSelected[0]}
					onRemove={handleRemoveItem}
					comboItem={true}
					onEdit={() => {
						editComboItem(
							itemSelected[0].id,
							itemSelected[0].combo_id,
							itemSelected[0].random
						);
					}}
				/>
			) : (
				<button
					className={hasClassName && item.hasFinishied ? "disabled" : null}
					onClick={onClick}
					id={item.name}
				>
					<span>[toque aqui para escolher]</span>
				</button>
			)}
		</div>
	);
};

export default ButtonChangeCombo;
