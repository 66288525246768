import React from "react";
import { MdDeliveryDining } from "react-icons/md";
type CartInfoDeliveryProps = {
	color: string;
};

const CartInfoDelivery: React.FC<CartInfoDeliveryProps> = ({
	children,
	color,
}) => {
	return (
		<div className="cart-address">
			<div className="cart-address-row">
				<MdDeliveryDining size={24} color={color} />
				<div className="cart-address-info delivery">{children}</div>
			</div>
		</div>
	);
};

export default CartInfoDelivery;
