import React, { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { BiPlus, BiMinus } from 'react-icons/bi';
import { Cart } from "../utils/types";
import { calculeTotal, formatValue } from "../utils/functions";
import RowCartItem from "./RowCartItem";

type CartItemProps = {
	item: Cart;
	onRemove(): void;
	comboItem?: boolean;
	onEdit(): void;
	onIncrease?(): void;
	onDecrease?(): void;
};

const CartItem: React.FC<CartItemProps> = ({
	item,
	onRemove,
	comboItem,
	onEdit,
	onIncrease,
	onDecrease,
}) => {
	const [shakeQuant, setShakeQuant] = useState(false);
	useEffect(() => {
		setShakeQuant(true);
		setTimeout(() => {
			setShakeQuant(false);
		}, 1000);
	}, [item.quant])

	return (
		<div className="CartItem">
			<div className={`content ${comboItem ? "center" : ""}`}>
				{comboItem && (
					<div className="image" onClick={onRemove}>
						<BsTrashFill size={24} color="#757575" />
					</div>
				)}
				{!comboItem &&
					<button
						className="change-amount"
						onClick={onDecrease}
					>
						<BiMinus size={20} color="#757575" />
					</button>
				}

				<div className={shakeQuant ? "content-amount shakeCart" : "content-amount"}>
					{!comboItem && <span>{item.quant.toString().replace('.', ',')}{item?.unidade?.toLocaleUpperCase() === "KG" ? ' Kg' : 'x'}</span>}
				</div>
				{!comboItem &&
					<button
						className="change-amount"
						onClick={onIncrease}
					>
						<BiPlus size={20} color="#757575" />
					</button>
				}
				<div className="infos">
					<span>{item.nome}</span>
					<span>{!comboItem ? formatValue(calculeTotal(item, item.quant)) : formatValue(item.valor * item.quant)}</span>
					<span>{item.descricao}</span>
					{item.custom_obs && (
						<RowCartItem label="OBS.:" label2={item.custom_obs} isItem={true} />
					)}
					{item?.observacoes &&
						item.observacoes?.length > 0 &&
						item.observacoes.map((item, index) => (
							<RowCartItem
								label="OBS.:"
								label2={item.descricao}
								key={index.toString()}
								isItem={true}
							/>
						))}
					{item.perguntas.map((question) => {
						if (question.incorporar > 0) {
							return question.answers
								.filter((answer) => answer.isChecked && !item.combo_id && !answer.id_produto) // se for combo nao mostra
								.map((answer, index) => (
									<RowCartItem
										label={`${question.descricao}.:`}
										label2={answer.descricao_resposta}
										key={index.toString()}
										isItem={true}
									/>
								));
						}
						return question.answers
							.filter((answer) => answer.isChecked && !item.combo_id) // se for combo nao mostra
							.map((answer, index) => (
								<RowCartItem
									label={`${question.descricao}.:`}
									label2={answer.descricao_resposta}
									key={index.toString()}
									isItem={true}
								/>
							));
					})}
					{item.opcionais?.length > 0 &&
						item.opcionais.map(
							(item, index) =>
								!item.isChecked && (
									<RowCartItem
										label="SEM.:"
										label2={item.descricao_insumo}
										key={index.toString()}
										isItem={true}
									/>
								)
						)}
					{item.adicionais?.length > 0 &&
						item.adicionais.map(
							(item, index) =>
								item.isChecked && (
									<RowCartItem
										label="COM.:"
										label2={item.descricao_insumo}
										key={index.toString()}
										isItem={true}
									/>
								)
						)}
				</div>
				<div className="info-value">
					<button
						className="remove"
						onClick={comboItem ? onEdit : onRemove}
						title={`Remover ${item.nome}`}
					>
						{comboItem ? (
							<FaPencilAlt size={25} color="#757575" />
						) : (
							<BsTrashFill size={25} color="#757575" />
						)}
					</button>
					{!comboItem &&
						<button
							className="remove"
							title={`Editar ${item.nome}`}
							onClick={onEdit}
						>
							<FaPencilAlt size={25} color="#757575" />
						</button>
					}
				</div>
			</div>
		</div>
	);
};

export default CartItem;
