import React, { useContext } from "react";
import { Ficha, Information } from "../utils/types";
import logo_voatro from "../../assets/images/logo_voatro.png";
import AppContext, { AppContextType } from "../contexts/AppContext";
import { formatValue } from "../utils/functions";

interface HeaderProps {
	info: Information;
	hasOrder: boolean;
	ficha: Ficha;
	onClickVerMais(): void;
	onClickSuccess(): void;
}

const Header: React.FC<HeaderProps> = ({
	info,
	hasOrder,
	onClickVerMais,
	onClickSuccess,
	ficha,
}) => {
	const { indexHorario } = useContext<AppContextType>(AppContext);
	return (
		<div className="header" id="header">
			<div className="image-section">
				{info.logo_url ? (
					<img src={info.logo_url} alt="logo_empresa" />
				) : (
					<div className="image">
						<img src={logo_voatro} alt="logo" />
					</div>
				)}
				{info.deliveryDate[indexHorario]?.aberto ? (
					<span className="badge green">Aberto</span>
				) : (
					<span className="badge red">Fechado</span>
				)}
			</div>
			<div className="location">
				<h2>{info.nome_estabelecimento ? info.nome_estabelecimento : info.nome}</h2>

				{info.valor_minimo_pedido > 0 ? (
					<h4 onClick={onClickVerMais}>
						Pedido mínimo: {formatValue(info.valor_minimo_pedido)} | Ver mais
					</h4>
				) : (
					<h4 onClick={onClickVerMais}>Ver mais</h4>
				)}
				{ficha && (
					<span className="ficha-label">{ficha.type} {ficha.number}</span>
				)}
				{ficha && hasOrder && (
					<div className="signout order-button">
						<button className="btn btn-white" onClick={onClickSuccess}>
							Meu pedido
						</button>
					</div>
				)}
				{hasOrder && !ficha && (
					<div className="signout order-button">
						<button className="btn btn-white" onClick={onClickSuccess}>
							Meu Pedido
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
