import React, { useState, createContext } from "react";
import server from "../services/newServer";

import {
	Cart,
	Observation,
	ComboCategory,
	Question,
	ComboProductGroup,
} from "../utils/types";
export interface ComboContextType {
	itens: Array<Cart>;
	comboProducts: Array<ComboProductGroup>;
	comboCategories: Array<ComboCategory>;
	comboCategoriesBorda: Array<ComboCategory>;
	comboContextError: any;

	getComboCategories(
		header: string,
		id: number,
		tipo: string
	): Promise<Array<ComboCategory>>;
	getComboProducts(
		header: string,
		id: number
	): Promise<Array<ComboProductGroup>>;
	removeProductFromItens(index: number, random: number | string): void;
	addProductToComboItens(
		product: any,
		comboId: string,
		quant: number,
		random?: string,
		compTotal?: number,
		obs?: string,
		observations?: Array<Observation>,
		questions?: Array<Question>,
		id?: number
	): void;
	editProductToComboItens(
		product: any,
		comboId: string,
		quant: number,
		random?: number,
		compTotal?: number,
		obs?: string,
		observations?: Array<Observation>,
		questions?: Array<Question>
	): void;
	addItemToEditCombo(product: any): void;
	handleAttComboScreen(): void;
	createComboContextError(err: any): void;
	clearComboContextError(): void;
	clearComboContext(): void;
	clearComboProducts(): void;
	clearComboCategories(): void;
	clearSessionComboProduct(): void;
	clearComboItens(): void;
	clearCategoriesBorda(): void;
	changeCategoriesFinished(product: any): void;
}

const ComboContext: React.Context<ComboContextType | any> = createContext({});

export const ComboProvider: React.FC = ({ children }) => {
	const [itens, setItens] = useState<Array<Cart>>([]);
	const [comboProducts, setComboProducts] = useState<Array<ComboProductGroup>>(
		[]
	);
	const [comboCategories, setComboCategories] = useState<Array<ComboCategory>>(
		[]
	);

	const [comboCategoriesBorda, setComboCategoriesBorda] = useState<
		Array<ComboCategory>
	>([]);

	const [comboContextError, setComboContextError] = useState<any>(null);

	async function getComboCategories(header: string, id: string, tipo: string) {
		try {
			const resp = await server.get(`/combos/${id}`, {
				headers: { id: header },
			});
			const list = [];
			const listBorda = [];

			let contador: number = 0;
			for (let index = 0; index < resp.data.length; index++) {
				let {
					id_combo,
					descricao,
					maximo_escolhas,
					minimo_escolhas,
					sabor_pizza,
					obrigatorio,
				} = resp.data[index];
				let escolhas = maximo_escolhas;
				let nome = descricao;
				let id = id_combo;
				let sabor = sabor_pizza;
				for (let j = 0; j < escolhas; j++) {
					contador += j + 1;
					if (
						sabor > 0 ||
						tipo === "personalizavel" ||
						tipo === "personalizado"
					) {
						list.push({
							id,
							name: `${nome} ${escolhas > 1 ? j + 1 : ""}`,
							hasFinishied: false,
							random: contador,
							required: j + 1 <= minimo_escolhas,
							maximo_escolhas: escolhas,
							sabor_pizza: sabor,
							obrigatorio: obrigatorio,
						});
					}
					if (sabor < 1) {
						listBorda.push({
							id,
							name: `${nome} ${escolhas > 1 ? j + 1 : ""}`,
							hasFinishied: false,
							random: contador,
							required: j + 1 <= minimo_escolhas,
							maximo_escolhas: escolhas,
							sabor_pizza: sabor,
							obrigatorio: obrigatorio,
						});
					}
				}
			}
			setComboCategoriesBorda(listBorda);
			setComboCategories(list);
			return list;
		} catch (err) {
			createComboContextError(err);
		}
	}

	async function getComboProducts(header: string, id: number) {
		try {
			const resp = await server.get(`/combos/${id}/choices`, {
				headers: { id: header },
			});
			setComboProducts(resp.data);
			return resp.data;
		} catch (err) {
			createComboContextError(err);
		}
	}

	async function editProductToComboItens(
		product: any,
		comboId: string,
		quant: number,
		random?: number,
		compTotal?: number,
		obs?: string,
		observations?: Array<Observation>,
		questions?: Array<Question>
	) {
		const newArray = itens.map((item: any): any => {
			if (item.id === product.id) {
				item.combo_id = parseInt(comboId);
				item.quant = quant;
				item.total = quant * item.valor;
				item.comp_total = compTotal;
				item.custom_obs = obs;
				item.observacoes = observations;
				item.random = random;
				item.questions = questions;
				item.opcionais = product.opcionais;
				item.adicionais = product.adicionais;
				item.perguntas = product.perguntas;
			}
			return item;
		});
		setItens(newArray);
	}

	async function addProductToComboItens(
		product: any,
		comboId: string,
		quant: number,
		random?: number,
		compTotal?: number,
		obs?: string,
		observations?: Array<Observation> | any,
		questions?: Array<Question>
	) {
		product.combo_id = parseInt(comboId);
		product.quant = quant;
		product.total = quant * product.valor;
		product.comp_total = compTotal ? compTotal : 0;
		product.custom_obs = obs ? obs : "";
		product.observacoes = observations ? observations : [];
		product.random = random;
		product.questions = questions ? questions : [];
		const localCategories = [...comboCategories];
		const localCategoriesBorda = [...comboCategoriesBorda];
		setComboCategoriesBorda(
			localCategoriesBorda.map((el) => {
				if (el.random === Number(random) && el.id === Number(comboId)) {
					el.hasFinishied = true;
					el.value = product.valor;
				}
				return el;
			})
		);
		setComboCategories(
			localCategories.map((el) => {
				if (el.random === Number(random) && el.id === Number(comboId)) {
					el.hasFinishied = true;
					el.value = product.valor;
				}
				return el;
			})
		);
		const local = [...itens];
		local.push(product);
		setItens(local);
	}

	//Muda hasFinishied para true
	function changeCategoriesFinished(product: any) {
		const localCategories = [...comboCategories];
		const localCategoriesBorda = [...comboCategoriesBorda];
		setComboCategoriesBorda(
			localCategoriesBorda.map((el) => {
				if (el.random === Number(product.random) && el.id === Number(product.combo_id)) {
					el.hasFinishied = true;
				}
				return el;
			})
		);
		setComboCategories(
			localCategories.map((el) => {
				if (el.random === Number(product.random) && el.id === Number(product.combo_id)) {
					el.hasFinishied = true;
				}
				return el;
			})
		);
	}
	//Adiciona itens de um combo paara o context itens
	function addItemToEditCombo(itens: any) {
		setItens(itens);
	}

	function removeProductFromItens(index: number, random: number | string) {
		const localItens = itens.filter((item) => {
			return Number(item.random) !== Number(random);
		});

		const localCategories = comboCategories.map((el) => {
			if (el.random === Number(random)) {
				el.hasFinishied = false;
				el.value = 0;
			}
			return el;
		});
		const localCategoriesBorda = comboCategoriesBorda.map((el) => {
			if (el.random === Number(random)) {
				el.hasFinishied = false;
				el.value = 0;
			}
			return el;
		});
		setComboCategoriesBorda(localCategoriesBorda);
		setComboCategories(localCategories);
		setItens(localItens);
	}

	const handleAttComboScreen = () => {
		const localCategories = sessionStorage.getItem("@CHEF:combocategories");
		const localCategoriesBorda = sessionStorage.getItem(
			"@CHEF:combocategoriesborda"
		);
		const localItens = sessionStorage.getItem("@CHEF:itens");
		if (localCategories) setComboCategories(JSON.parse(localCategories));
		if (localCategoriesBorda) {
			setComboCategoriesBorda(JSON.parse(localCategoriesBorda));
		}
		if (localItens) setItens(JSON.parse(localItens));
	};

	function createComboContextError(err: any) {
		try {
			if (typeof err === "string") {
				setComboContextError(err);
			} else {
				if (err.response.data) {
					setComboContextError(
						err.response.data.message || err.response.data.type
					);
				} else {
					setComboContextError(err.error || err.err);
				}
			}
		} catch (err) {
			setComboContextError(
				"Algum processo do site não foi finalizado ou não foi possível realiza-lo. Tente novamente!"
			);
		}
	}
	const clearSessionComboProduct = () => {
		sessionStorage.clear();
	};
	const clearCategoriesBorda = () => setComboCategoriesBorda([]);
	const clearComboContextError = () => setComboContextError(null);
	const clearComboCategories = () => setComboCategories([]);
	const clearComboItens = () => {
		let localCategoriesBorda = [...comboCategoriesBorda];
		localCategoriesBorda = localCategoriesBorda.map((el) => {
			el.hasFinishied = false;
			return el;
		})
		setComboCategoriesBorda(localCategoriesBorda);
		setItens([]);
	}
	const clearComboContext = () => {
		setItens([]);
		setComboProducts([]);
		setComboCategories([]);
		setComboContextError(null);
	};
	function clearComboProducts() {
		setComboProducts([]);
	}

	return (
		<ComboContext.Provider
			value={{
				itens,
				comboProducts,
				comboCategories,
				comboCategoriesBorda,
				comboContextError,

				getComboCategories,
				getComboProducts,
				addProductToComboItens,
				editProductToComboItens,
				removeProductFromItens,
				handleAttComboScreen,

				createComboContextError,
				clearComboContextError,
				clearComboContext,
				clearComboProducts,
				clearComboCategories,
				clearSessionComboProduct,
				clearComboItens,
				clearCategoriesBorda,
				addItemToEditCombo,
				changeCategoriesFinished,
			}}
		>
			{children}
		</ComboContext.Provider>
	);
};

export default ComboContext;
