/* eslint-disable array-callback-return */
import React from "react";
import { Composition, Observation } from "../utils/types";
import CompItem from "./CompItem";
import CompItemAnswer from "./CompItemAnswer";
import ListHeader from "./ListHeader";
import ObsItem from "./ObsItem";

interface ComposicaoDetailsProductProps {
	product: any;
	label: string;
	label2?: string;
	onToggle(value: number, removed: boolean, qtdade?: number): void;
}
const ComposicaoDetailsProduct: React.FC<ComposicaoDetailsProductProps> = ({
	product,
	label,
	label2,
	onToggle,
}) => {
	return (
		<div className="composition" id={`pergunta-${product?.id_pergunta}`}>
			<ListHeader label1={label} label2={label2} details={true} />
			{product?.id_pergunta ?
				product?.answers.map((item: any, index: number) => (
					<CompItemAnswer key={index.toString()} item={item} onToggle={onToggle} pergunta={product} maxEscolhas={product?.maximo_escolhas} />
				))
				:
				label !== "OBSERVAÇÕES"
					? product?.map((item: Composition, index: number) => (
						<CompItem key={index.toString()} item={item} onToggle={onToggle} />
					))
					: product?.map((item: Observation, index: number) => (
						<ObsItem key={index.toString()} item={item} />
					))

			}
		</div>
	);
};

export default ComposicaoDetailsProduct;
