import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import Alert from "../components/Alert";
import Loading from "../components/Loading";
import AppContext, { AppContextType } from "../contexts/AppContext";
import "./styles.css";
import HeaderDefault from "../components/HeaderDefault";
import RowHours from "../components/RowHours";
import ListHeader from "../components/ListHeader";
import { formatPhoneWhatsapp } from "../utils/functions";

// eslint-disable-next-line no-useless-escape

const Informacoes: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header } = useParams();
	const { globalError, clearGlobalErrors, info, indexHorario } =
		useContext<AppContextType>(AppContext);
	const { getHours, getInfo } = useContext<AppContextType>(AppContext);
	const [openingHours, setOpeningHours] = useState([]);

	const [showLoading, setShowLoading] = useState<boolean>(false);
	const handleGoBack = () => {
		clearGlobalErrors();
		history.push(`/${header}`);
	};

	const handleOpenWhatsApp = () => {
		const numberPhone = formatPhoneWhatsapp(info.whatsapp);
		window.open(
			`https://api.whatsapp.com/send?phone=55${numberPhone}`,
			"_blank"
		);
	};

	useEffect(() => {
		(async () => {
			setShowLoading(true);
			let horas = await getHours(header);
			horas = horas.sort((a, b) => {
				if (a.dia_da_semana > b.dia_da_semana) {
					return 1;
				}
				if (a.dia_da_semana < b.dia_da_semana) {
					return -1;
				}
				return 0;
			});
			const teste = [];
			// eslint-disable-next-line array-callback-return
			horas.map((item) => {
				if (item.openingHours.length === 0) {
					teste.push({
						nome_semana: item.nome_dia,
						horario_fim: "fechado",
						horario_inicio: "fechado",
					});
				} else {
					// eslint-disable-next-line array-callback-return
					item.openingHours.map((hora: any) => {
						teste.push({
							...hora,
							nome_semana: item.nome_dia,
						});
					});
				}
			});
			await getInfo(header);
			setOpeningHours(teste);
			setShowLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="Voltar"
					onClose={handleGoBack}
				/>
			</div>
		);
	}
	return (
		<div className="Cart">
			{showLoading && <Loading />}
			<HeaderDefault
				label="ALGUMAS INFORMAÇÕES DA LOJA"
				onClick={() => history.push(`/${header}`)}
			/>
			<div className="container">
				<ListHeader label1="HORÁRIOS DE FUNCIONAMENTO" details={true} />
				<div className="container-horarios">
					{openingHours.length > 0 &&
						openingHours.map((item, index) => (
							<RowHours
								key={index.toString()}
								label={
									item.nome_semana === openingHours[index - 1]?.nome_semana
										? ""
										: item.nome_semana
								}
								horario1={item.horario_inicio}
								horario2={item.horario_fim}
								entrega={item.entrega}
								retirada={item.retirada}
								focus={
									item.id_horario ===
										info.deliveryDate[indexHorario]?.id_horario
										? true
										: false
								}
							/>
						))}
				</div>
				<ListHeader label1="TELEFONE" details={true} />
				<span onClick={handleOpenWhatsApp} className="container-infoNumero">
					{info?.fone}
				</span>
				<ListHeader label1="ENDEREÇO" details={true} />
				<div className="container-endereco">
					<span className="texto-endereco">
						{`${info?.endereco ? info.endereco : ''}, ${info?.numero ? info.numero : ''} \n
          ${info?.bairro ? info.bairro : ''}
          `}
					</span>
				</div>
			</div>
			<div className="floating-container">
				<button
					className="btn btn-primary"
					disabled={showLoading}
					onClick={() => history.push(`/${header}`)}
				>
					FECHAR
				</button>
			</div>
		</div>
	);
};

export default Informacoes;
