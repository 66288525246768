import React, { useContext, useRef, useState } from 'react'
import { StandaloneSearchBox } from '@react-google-maps/api';
import ContainerLoadScript from './ContainerLoadScript';
import OrderContext, { OrderContextType } from '../contexts/OrderContext';
import Alert from './Alert';

interface InputGoogleMapsProps {
  positionAtual: any
  header: string
  googleKey: string
}

const InputGoogleMaps: React.FC<InputGoogleMapsProps> = ({ positionAtual, header, googleKey }) => {
  const ref = useRef<HTMLInputElement>()
  const {
    handleAdressConfirm,
    handleGetDelivery
  } = useContext<OrderContextType>(OrderContext);

  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>()
  // const [map, setMap] = useState<google.maps.Map>()
  const [focused, setFocused] = useState<boolean>(false);
  const [locatioNotFound, setLocatioNotFound] = useState<boolean>(false);

  const onLoad = (ref: google.maps.places.SearchBox) => {
    setSearchBox(ref)
  }

  const onPlacesChanged = async () => {
    try {
      const places = searchBox!.getPlaces();
      const place = places![0]
      const location = {
        coordinates: {
          latitude: place?.geometry?.location?.lat() || 0,
          longitude: place?.geometry?.location?.lng() || 0,
        }
      }
      const resp = await handleGetDelivery(header, location.coordinates)
      if (resp) {
        handleAdressConfirm(place?.address_components)
      } else {
        ref.current.value = ''
      }
    } catch (error) {
      console.log(error)
    }
  }

  const defaultBounds = {
    north: positionAtual.coordinates ? positionAtual.coordinates.lat + 0.1 : -23.533773 + 0.1,
    south: positionAtual.coordinates ? positionAtual.coordinates.lat - 0.1 : -23.533773 - 0.1,
    east: positionAtual.coordinates ? positionAtual.coordinates.lng + 0.1 : -46.625290 + 0.1,
    west: positionAtual.coordinates ? positionAtual.coordinates.lng - 0.1 : -46.625290 - 0.1,
  };
  if (locatioNotFound) {
    return (
      <div className="default-container">
        <Alert
          message={'Não entregamos nessa localização'}
          danger={true}
          button="Voltar"
          onClose={() => setLocatioNotFound(false)}
        />
      </div>
    );
  }

  return (
    <ContainerLoadScript
      googleKey={googleKey}
    >
      <div className='Input'>
        <label htmlFor="" className={focused ? 'labelFocused' : ''}>Endereço</label>
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged} bounds={defaultBounds}>
          <input
            ref={ref}
            id='address'
            autoComplete='off'
            onBlur={(event) => {
              setFocused(false);
            }}
            onFocus={() => setFocused(true)}
            // value={fullAddress}          
            className={`input-googlemaps ${focused ? 'focused' : ''}`}
          >
          </input>
        </StandaloneSearchBox>
      </div>
    </ContainerLoadScript>
  )
}

export default InputGoogleMaps