import React, { useContext, useEffect, useState } from "react";
import "./styles.css";

import { Composition } from "../utils/types";
import { animationSwing, formatValue, decryptText } from "../utils/functions";
import { FiPlus, FiMinus } from "react-icons/fi";
import AppContext, { AppContextType } from "../contexts/AppContext";

interface CompItemProps {
	item: Composition;
	onToggle(value: number, removed: boolean): void;
}

const CompItem: React.FC<CompItemProps> = ({ item, onToggle }) => {
	const {
		info,
		ficha
	} = useContext<AppContextType>(AppContext);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [fetched, setFetched] = useState(false)
	const [qtdade, setQtdade] = useState<number>(0);

	// EFFECT DE TRAZER INFORMACOES AO EDITAR
	useEffect(() => {
		if (!fetched) {
			const handleToggle2 = () => {
				try {
					setIsChecked(!isChecked);
					if (item.tipo === "OPCIONAL") {
						item.isChecked = isChecked;
					} else {
						item.isChecked = !isChecked;
					}
					onToggle(item.valor, isChecked);
				} catch (error) {
					console.log(error);
				}
			};
			const editando = decryptText(sessionStorage.getItem("@CHEF:editando"));
			const cartItens = decryptText(sessionStorage.getItem("@CHEF:cart"));
			const comboItens = decryptText(sessionStorage.getItem("@CHEF:itensCombo"));
			if (cartItens) {
				const cartItensObj = JSON.parse(cartItens);
				const editandoObj = JSON.parse(editando);

				if (item.tipo === "ADICIONAL") {
					cartItensObj[editandoObj?.item]?.adicionais.forEach(adicional => {
						if (adicional.id_produto_insumo === item.id_produto_insumo && adicional.isChecked) {
							if (adicional?.qtdade > 0) {
								item.isChecked = true;
								item.qtdade = qtdade + 1;
								setQtdade((prev) => prev + 1);
								onToggle(item.valor, false);
							} else
								handleToggle2();
						}
					});
				} else if (item.tipo === "OPCIONAL") {
					cartItensObj[editandoObj?.item]?.opcionais.forEach(opcional => {
						if (opcional.id_produto_insumo === item.id_produto_insumo && !opcional.isChecked) {
							handleToggle2();
						}
					});
				}

			}
			if (comboItens) {
				const comboItensObj = JSON.parse(comboItens);
				comboItensObj.forEach(iten => {
					if (item.tipo === "ADICIONAL") {
						iten.adicionais.forEach(adicional => {
							if (adicional.id_produto_insumo === item.id_produto_insumo && adicional.isChecked) {
								handleToggle2();
							}
						});
					} else if (item.tipo === "OPCIONAL") {
						iten.opcionais.forEach(opcional => {
							if (opcional.id_produto_insumo === item.id_produto_insumo && !opcional.isChecked) {
								handleToggle2();
							}
						});
					}
				});
			}
			setFetched(true);
		}
	}, [item, isChecked, fetched, onToggle])
	// FIM

	const handleToggle = () => {
		setIsChecked(!isChecked);
		if (item.tipo === "OPCIONAL") {
			item.isChecked = isChecked;
			item.valor = 0;
		} else {
			if (item.valor) {
				animationSwing("valueAnimation");
			}
			item.isChecked = !isChecked;
		}
		onToggle(item.valor, isChecked);
	};

	const handleToggle2 = (increase: boolean) => {
		if (increase) {
			if (qtdade === 0)
				item.isChecked = true;

			item.qtdade = qtdade + 1;
			setQtdade((prev) => prev + 1)
			onToggle(item.valor, false);
			if (item.valor) {
				animationSwing("valueAnimation");
			}
		} else {
			if (item.valor) {
				animationSwing("valueAnimation");
			}
			if (qtdade === 0) {
				item.isChecked = false
				setQtdade(0)
			} else if (qtdade === 1) {
				item.isChecked = false
				onToggle(item.valor, true);
				setQtdade((prev) => prev - 1)
			} else {
				item.qtdade = qtdade - 1;
				onToggle(-item.valor, false);
				setQtdade((prev) => prev - 1)
			}
		}
	}

	return (
		<div className="CompItem">
			<div className="label">
				{item.tipo === "OPCIONAL" && (
					<span>REMOVER {item.descricao_insumo}</span>
				)}
				{item.tipo === "ADICIONAL" && (
					<span>ADICIONAL {item.descricao_insumo}</span>
				)}
				{item.tipo !== "OPCIONAL" && item.tipo !== "ADICIONAL" && (
					<span>{item.descricao_insumo}</span>
				)}

				{item.tipo !== "OPCIONAL" && <span>+ {formatValue(qtdade === 0 ? item.valor : item.valor * qtdade)}</span>}
			</div>

			{item.tipo !== "ADICIONAL" || info?.adicionais_utiliza_check === 1 ?
				(!ficha || info?.apenas_visualizar_produtos_smart !== 1) && <input type="checkbox" checked={isChecked} onChange={handleToggle} />
				:
				(!ficha || info?.apenas_visualizar_produtos_smart !== 1) &&
				<div className="compIncreaser">
					<button className="increaseMinus" onClick={() => handleToggle2(false)}>
						<FiMinus style={{ alignSelf: 'center' }} size={15} color="#000" />
					</button>
					<span>{qtdade}</span>
					<button onClick={() => handleToggle2(true)}>
						<FiPlus style={{ alignSelf: 'center' }} size={15} color={info?.cor_padrao_texto ? info.cor_padrao_texto : "#fff"} />
					</button>
				</div>}
		</div>
	);
};

export default CompItem;
