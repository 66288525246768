import React, { useState } from "react";
import "./styles.css";

interface InputProps {
	label: string;
	type: string;
	value: string;
	disabled: boolean;
	onChangeText(text: string): void;
	onBlur(event: React.FocusEvent<HTMLInputElement>): void;
	placeholder?: string;
	id?: string;
}

const Input: React.FC<InputProps> = ({
	label,
	type,
	value,
	disabled,
	onChangeText,
	onBlur,
	placeholder,
	id,
	children,
}) => {
	const [focused, setFocused] = useState<boolean>(false);
	return (
		<div
			id={id}
			className={`Input ${disabled ? "disabled" : ""} ${focused || value?.length > 0 ? "focused" : ""
				}`}
		>
			<label>{label}</label>
			<div style={{ display: "flex", marginTop: 10 }}>
				{children}
				<input
					type={type}
					value={value}
					disabled={disabled}
					autoComplete="off"
					onChange={(event) => {
						if (event.target.value.length <= 200)
							onChangeText(event.target.value)
					}}
					onBlur={(event) => {
						setFocused(false);
						onBlur(event);
					}}
					onFocus={() => setFocused(true)}
				// placeholder={placeholder}
				/>
			</div>
			<span className={`Input-span-sugestao ${focused ? "focused" : ""}`}>
				{placeholder ? `ex: ${placeholder}` : ""}
			</span>
		</div>
	);
};

export default Input;
