import React, { useState } from "react";
import "./styles.css";
import { AiFillCopy } from "react-icons/ai";

interface PixArea {
    chave_pix: string;
}

const CompItem: React.FC<PixArea> = ({ chave_pix }) => {

    const [copied, setCopied] = useState(false);

    const copyText = () => {
        navigator.clipboard.writeText(String(chave_pix));
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <div>
            <div className="containerPix">
                <input type="text" placeholder={chave_pix} readOnly />
                <button
                    onClick={copyText}
                >
                    <AiFillCopy />
                </button>
            </div>
            {copied ?
                <p style={{ color: 'green' }} className="pixDescription">Copiado!</p>
                : <p className="pixDescription">Utilize esta chave pix para pagar e receber seu pedido.</p>
            }
        </div>
    );
};

export default CompItem;
