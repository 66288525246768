import React from "react";
import { FiArrowLeft } from "react-icons/fi";

import "./styles.css";

interface HeaderDefaultProps {
  label: string
  onClick(): void
  id_pdv?: number 
}

const HeaderDefault: React.FC<HeaderDefaultProps> = ({ label, onClick, id_pdv }) => {
  return (
    <div className="default-header">
      <button onClick={onClick}>
        <FiArrowLeft size={24} color="#58585a" />
      </button>

      <span>{label}</span>

      {id_pdv > 0 && <span>{id_pdv}</span>}
    </div>
  );
};

export default HeaderDefault;
