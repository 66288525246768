import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import {
    FiDollarSign,
    FiUser,
    FiPhone,
    FiMapPin,
    FiTag,
    FiHome,
} from "react-icons/fi";

import Row from "../components/Row";
import Alert from "../components/Alert";
import Input from "../components/Input";
import Loading from "../components/Loading";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";

import { Cart, Combo, Neighborhood, Order } from "../utils/types";
import {
    formatValue,
    phoneMask,
    checkOrder,
    createCartArray,
    createComboArray,
    justNumbers,
    decryptText,
    calculeTotal,
    getDayMonth,
    minToHour,
    generateHash,
} from "../utils/functions";

import "./styles.css";
import HeaderDefault from "../components/HeaderDefault";
import ListHeader from "../components/ListHeader";
import ModalPedidoEnviado from "../components/ModalPedidoEnviado";
import ScannerQrCode from "../components/ScannerQrCode";
import PaymentBrick from "../components/PaymentBrick";
import server from "../services/newServer";
import PaymentStatus from "../components/PaymentStatus";
import { FaMotorcycle } from "react-icons/fa";

// eslint-disable-next-line no-useless-escape
const phoneRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{4,6}$/;

const FinishCartNoGeo: React.FC<RouteComponentProps> = ({ history }) => {
    /* @ts-ignore */
    const { header } = useParams();

    const {
        info,
        indexHorario,
        cities,
        neighborhoods,
        client,
        ficha,
        globalError,
        getInfo,
        getCities,
        getNeighborhoods,
        getClientInfo,
        calcTimeDelivery,
        timeDelivery,
        timeNoDelivery,
        clearAppContextNotLocalStorage,
        clearSessionStorage,
        createGlobalError,
        getPayments,
        verifyDiscountCouponUnique,
        createTrackingLog,
    } = useContext<AppContextType>(AppContext);
    const {
        table,
        setTable,
        orderContextError,
        create,
        createOrderContextError,
        clearOrderContextError,
        deliveryTime,
        setDeliveryTime,
        coupon,
        getPrepTime,
        cardNumber,
        cardCode,
        cardExpiration,
        cardCpf,
        cardHolderName,
        paymentMethodId,
        addCoupon,
        clearCardInfo,
    } = useContext<OrderContextType>(OrderContext);

    /* Booleanos */
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showSucessAlert, setShowSuccessAlert] = useState<boolean>(false);

    /* Valores */
    const [subtotal, setSubtotal] = useState<number>(0);
    const [compTotal, setCompTotal] = useState<number>(0);

    //* Lista de pagamentos */
    const [paymentList, setPaymentList] = useState([]);

    /* Informações do cliente */
    const [name, setName] = useState<string>(client?.nome || ""); /* Nome */
    const [phone, setPhone] = useState<string>(
        client?.celular || ""
    ); /* Numero de telefone */
    const [address, setAddress] = useState<string>(
        client?.endereco || ""
    ); /* Endereço / Logradouro */
    const [addressNumber, setAddressNumber] = useState<string>(
        client?.numero?.toString() || ""
    ); /* Número */
    const [complement, setComplement] = useState<string>(
        client && client.complemento ? client.complemento : ""
    ); /* Complemento */

    /* Informações do pedido */
    const [city, setCity] = useState<string>(
        client?.id_municipio?.toString() || "none"
    ); /* Cidade escolhida */
    const idBairroValidar: String = client?.id_bairro?.toString() || "none";
    const bairro: Neighborhood = neighborhoods.find(
        (item) => item.id_bairro.toString() === idBairroValidar
    );
    const [neighborhood, setNeighborhood] = useState<string>(
        bairro?.id_bairro.toString() || "none"
    ); /* Bairro escolhido */
    const [deliveryType, setDeliveryType] =
        useState<string>(""); /* Tipo do pedido */
    const [change, setChange] = useState<string>(""); /* Troco */
    const [idEntrega, setIdEntrega] = useState<number>(
        bairro?.id_entrega || 0
    ); /* ID da entrega */
    const [taxa, setTaxa] = useState<number>(bairro?.valor || 0);
    // const [taxa, setTaxa] = useState<number>(0);
    const [payment, setPayment] =
        useState<string>("SELECIONAR"); /* Forma de pagamento */
    const [saveData, setSaveData] =
        useState<boolean>(false); /* Salvar os dados ou não */

    // const [focused, setFocused] = useState<boolean>(false);

    const [isRestaurantClose, setIsRestaurantClose] = useState<boolean>(false);
    const [openScannerQrCode, setOpenScannerQrCode] = useState<boolean>(false);
    const [showInformacoes, setShowInformacoes] = useState<boolean>(false);
    const [isComanda, setIsComanda] = useState<boolean>(false);
    const [isCheckBox, setIsCheckBox] = useState<boolean>(
        client?.numero?.toString() === "S/N" ? true : false
    );
    const [message, setMessage] = useState<string>("false");
    const [openModalPedidoEnvido, setOpenModalPedidoEnvido] =
        useState<boolean>(false);

    const [onlinePayID, setonlinePayID] = useState<any>('');

    const [sendClicked, setSendClicked] = useState(false);

    const [uniqueCouponWarning, setUniqueCouponWarning] = useState('');
    const [hash_venda, setHash_venda] = useState(null)

    const handleAction = async (pago?: boolean) => {
        try {
            if (pago) {
                const trackingParams = {
                    tipo: 'INF',
                    descricao: `${name} - realizou pagamento com a forma de pagamento: ${payment} | Valor: R$ ${subtotal + taxa}`,
                    id_processo: null,
                    id_tabela: null,
                    tabela: "pdv_cad_venda",
                    login_usuario: "voamenu",
                    cod_usuario: null,
                    nome_computador: "voamenu",
                    versao: null,
                    data: "",
                    sql: "",
                    tipo_log: "T"
                }
                createTrackingLog(header, trackingParams)
            }
            if (deliveryType === "SELECIONAR") {
                setMessage("Selecione entrega ou retirada!");
                setIsComanda(true);
                return;
            }
            if (!ficha && (payment === "" || payment === "SELECIONAR")) {
                setMessage("Selecione a forma de pagamento!");
                setIsComanda(true);
                return;
            }
            setShowLoading(true);
            const response = await getInfo(header);
            const haveBarcode = sessionStorage.getItem("@CHEF:barcode");
            const localFicha = {
                type: ficha ? ficha.type : null,
                number: ficha ? ficha.number : null,
                mesa_vinculada: table === "" ? 0 : Number(table)
            }
            if (haveBarcode) {
                const barcode = JSON.parse(haveBarcode);
                localFicha.type = barcode.type;
                localFicha.number = barcode.number;
                localFicha.mesa_vinculada = barcode.mesa_vinculada;
            }
            if (response.deliveryDate[indexHorario].aberto) {
                if (response.disponivel) {
                    if (!ficha || table !== "" || ficha.type === "Mesa") {
                        if (info.smart_identificar_cliente === 1) {
                            if (name === "" && phone === "") {
                                setMessage("Preencha todos os campos!");
                                setIsComanda(true);
                                return;
                            }
                        }
                        let localCart = [];
                        let localCombos = [];

                        let sessionCart = JSON.parse(decryptText(sessionStorage.getItem("@CHEF:cart")));
                        let sessionCombos = JSON.parse(
                            decryptText(sessionStorage.getItem("@CHEF:combos"))
                        );
                        if (sessionCart) {
                            localCart = await createCartArray([...sessionCart]);
                        } else {
                            sessionCart = [];
                        }
                        if (sessionCombos) {
                            localCombos = await createComboArray([...sessionCombos]);
                        } else {
                            sessionCombos = [];
                        }
                        const localTotal =
                            deliveryType === "ENTREGA" ? subtotal + taxa : subtotal;

                        let localNeighborhood = null;
                        let localCity = null;

                        if (!ficha) {
                            if (neighborhood && deliveryType === "ENTREGA") {
                                const founded = neighborhoods.find(
                                    (item) => item.id_bairro.toString() === neighborhood
                                );
                                localNeighborhood = founded ? founded.nome : null;
                            }

                            if (city && deliveryType === "ENTREGA") {
                                const founded = cities.find(
                                    (item) => item.id_municipio.toString() === city
                                );
                                localCity = founded ? founded.nome : null;
                            }
                        }
                        let date = new Date();
                        let id_mercado_pago = null;
                        let params: Order = {
                            obs: "",
                            payment,
                            subtotal,
                            comp_total: compTotal,
                            total: localTotal,
                            desconto: coupon ? coupon.value : 0,
                            id_cupom_desconto: coupon ? coupon.id : null,
                            quant: sessionCart.length + sessionCombos?.length,
                            type: localFicha.type,
                            number: localFicha.number,
                            taxa,
                            change: change === "" ? 0 : parseFloat(change),
                            deliveryType,
                            saveData: deliveryType === "ENTREGA" ? saveData : false,
                            user: {
                                name: name?.replace(/'/gi, ""),
                                phone,
                                //whatsapp,
                                address,
                                addressNumber,
                                id_entrega: idEntrega,
                                id_neighborhood: neighborhood,
                                id_city: city,
                                complement /* @ts-ignore */,
                                neighborhood: localNeighborhood /* @ts-ignore */,
                                city: localCity,
                            },
                            itens: localCart,
                            combos: localCombos,
                            timeNow: `${date.getHours()}:${date.getMinutes()}`,
                            dateNow: date.toLocaleDateString(),
                            header,
                            time: null,
                            mesa_vinculada: localFicha.mesa_vinculada,
                            hash_venda: null,
                            id_mercado_pago: null,
                        };
                        await checkOrder(params);

                        if ((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && !pago) {
                            id_mercado_pago = await handleOnlinePayment();
                            if (!id_mercado_pago) {
                                setShowLoading(false);
                                return
                            }
                        }

                        params.id_mercado_pago = id_mercado_pago;

                        let resp: any = false;

                        if (!((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && pago)) {
                            resp = await create(
                                header,
                                hash_venda,
                                params,
                                info.tempo_minimo_preparo
                            );
                        } else if ((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && pago) {
                            clearSessionStorage();
                            clearCardInfo();
                            if (!ficha)
                                history.push(`/${header}/success`);

                            setShowLoading(false);
                        }
                        if (resp) {
                            /* @ts-ignore */
                            params.id_pdv = resp.id;
                            params.entrega_prevista = resp.entrega_prevista;

                            if (ficha) {

                                //Verifica se já há uma ordem aberda para a comanda ou mesa e adiciona os itens se já houver
                                var localOrder: any = localStorage.getItem('@CHEF:order');

                                if (localOrder) {

                                    //ITENS
                                    localOrder = JSON.parse(localOrder);
                                    const localItens: Order = localOrder;
                                    localItens.itens.forEach(el => {
                                        params.itens.push(el);
                                    });

                                    const comboLocal: Order = localOrder;
                                    comboLocal.combos.forEach(combo => {
                                        params.combos.push(combo);
                                    });

                                    //Calcula o valor total a ser mostrado.
                                    var totalItens = 0;
                                    params.itens.forEach(item => {
                                        totalItens += item.valor * item.quant;
                                    });

                                    params.combos.forEach(combo => {
                                        totalItens += combo.valor * combo.quant;
                                    })

                                    params.total = totalItens;
                                }

                                // Adiciona a hora da criação
                                const finalParams: any = { ...params, createdAt: new Date().getTime() }

                                localStorage.setItem("@CHEF:order", JSON.stringify(finalParams));
                            } else {
                                // Adiciona a hora da criação
                                const finalParams: any = { ...params, createdAt: new Date().getTime() }
                                localStorage.setItem("@CHEF:order", JSON.stringify(finalParams));
                            }

                            if ((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && !pago) {
                                setShowLoading(false);
                                return
                            }

                            clearSessionStorage();
                            clearCardInfo();
                            localStorage.setItem("@CHEF:Cached", JSON.stringify({ cachedDate: new Date().getTime() }));
                            if (!ficha) {
                                history.push(`/${header}/success`);
                            } else {
                                setOpenModalPedidoEnvido(true);
                            }
                        } else if (!((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && pago)) {
                            createOrderContextError(
                                "Não foi possivel realizar o pedido! Tente novamente"
                            );
                        }
                        setShowLoading(false);
                    } else {
                        setShowLoading(false);
                        setMessage("Preencha todos os campos!");
                        setIsComanda(true);
                    }
                } else {
                    createGlobalError(
                        `No momento não estamos conseguindo atender novos pedidos. Tente novamente mais tarde.`
                    );
                }
            } else {
                setIsRestaurantClose(true);
            }
        } catch (err) {
            setShowLoading(false);
            createOrderContextError(err);
        }
    };

    const handleOnlinePayment = async () => {
        setSendClicked(true);
        const desconto = coupon?.value > 0 ? coupon.value : 0
        let paymentMethod = ''
        switch (payment) {
            case 'PIX ONLINE':
                paymentMethod = 'pix'
                break;
            case 'CREDITO ONLINE':
                paymentMethod = paymentMethodId
                break;
            case 'DEBITO ONLINE':
                paymentMethod = paymentMethodId
                break;
            default:
                break;
        }

        if (paymentMethod === '') {
            setMessage("Cartão invalido, verifique as informações do cartão.");
            setIsComanda(true);
            setSendClicked(false);
            return
        }

        if (paymentMethod !== 'pix' && (cardNumber === '' || cardCode === '' || cardExpiration === '' || cardHolderName === '' || cardCpf === '')) {
            setMessage("Preencha todos os campos do metodo de pagamento!");
            setIsComanda(true);
            setSendClicked(false);
            return
        }

        let respCard = null;

        if (paymentMethod !== 'pix') {
            const [cardMonth, cardYear] = cardExpiration.split('/');
            respCard = await server.post('/process_payment/cardinfo', {
                card_number: cardNumber,
                expiration_month: cardMonth,
                expiration_year: 20 + cardYear,
                security_code: cardCode,
                access_token: info.key_mercadopago,
                holder_name: cardHolderName,
                holder_cpf: cardCpf
            });
        }

        const params = {
            token: respCard?.data?.id ? respCard?.data?.id : '',
            accessToken: info.key_mercadopago,
            transaction_amount: (subtotal - desconto) + taxa,
            description: "Pagamento voamenu",
            payment_method_id: paymentMethod,
            installments: 1,
            payer: {
                email: info.email_mercadopago,
                identification: {
                    number: cardCpf,
                    type: "CPF"
                }
            }
        }
        const resp = await server.post('/process_payment', params);
        if (resp.data) {
            setonlinePayID(resp.data.body.id);
            return resp.data.body.id;
        } else {
            setSendClicked(false);
            setMessage("Erro ao tentar realizar pagamento!");
            setIsComanda(true);
            return false;
        }
    }

    const handleSuccess = () => {
        setShowSuccessAlert(false);
        history.push(`/${header}/success`);
    };

    const handlePhoneMask = (text: string) => {
        setTimeout(() => {
            const value = phoneMask(text);
            if (value !== text) text = value;
            setPhone(value);
        }, 1);
    };

    const handlePhoneInputBlurEvent = async () => {
        try {
            if (!phoneRegex.test(phone)) return;
            if (info.utiliza_cupom_desconto && info.permitir_unico_cupom && coupon) {
                const checkUniqueCoupon = await verifyDiscountCouponUnique(header, coupon.id, phone);
                if (checkUniqueCoupon) {
                    setUniqueCouponWarning("Desconto não aplicado, o cupom já foi utilizado por este usuário!")
                    addCoupon('');
                }
            }
            setShowLoading(true);
            const resp = await getClientInfo(header, phone);
            if (resp) {
                setName(resp.nome);

                if (deliveryType === "ENTREGA") {
                    setAddress(resp.endereco ? resp.endereco : "");
                    resp.numero?.toString() === "S/N"
                        ? setIsCheckBox(true)
                        : setIsCheckBox(false);
                    setAddressNumber(resp.numero ? resp.numero.toString() : "");
                    setComplement(resp.complemento ? resp.complemento : "");

                    if (resp.id_municipio) {
                        setCity(resp.id_municipio.toString());

                        const local = await getNeighborhoods(header, resp.id_municipio);
                        const founded = local.find(
                            (item) => item.id_bairro === resp.id_bairro
                        );

                        if (founded) {
                            setNeighborhood(resp.id_bairro.toString());
                            setDeliveryTime(founded.tempo_entrega);
                            if (subtotal >= info.valor_minimo_pedido) {
                                const taxa = getTaxaGratis(founded);
                                setTaxa(taxa);

                                //setTaxa(founded.valor);
                            }
                            setIdEntrega(founded.id_entrega);
                        } else {
                            setNeighborhood("none");
                            setTaxa(0);
                            setIdEntrega(0);
                        }
                    } else {
                        setCity("none");
                        setNeighborhood("none");
                        setIdEntrega(0);
                        setDeliveryTime(0);
                        setTaxa(0);
                    }
                } else {
                    setCity("none");
                    setNeighborhood("none");
                    setIdEntrega(0);
                    setDeliveryTime(0);
                    setTaxa(0);
                }
            } else {
                setCity("none");
                setNeighborhood("none");
                setIdEntrega(0);
                setDeliveryTime(0);
                setTaxa(0);
            }
            setShowInformacoes(true);
            setShowLoading(false);
        } catch (err) {
            setShowLoading(false);
            createGlobalError(err);
        }
    };

    const getTaxaGratis = (entrega: any) => {
        //VERIFICAR ENTREGA GRATIS
        const data = new Date().getDay();
        let taxa = entrega.valor;
        switch (data) {
            case 0:
                if (entrega.domingo > 0)
                    taxa = 0;
                break;
            case 1:
                if (entrega.segunda > 0)
                    taxa = 0;
                break;
            case 2:
                if (entrega.terca > 0)
                    taxa = 0;
                break;
            case 3:
                if (entrega.quarta > 0)
                    taxa = 0;
                break;
            case 4:
                if (entrega.quinta > 0)
                    taxa = 0;
                break;
            case 5:
                if (entrega.sexta > 0)
                    taxa = 0;
                break;
            case 6:
                if (entrega.sabado > 0)
                    taxa = 0;
                break;
            default:
                break;
        }

        return taxa;
    }

    const handleChangeInputBlurEvent = () => {
        const formated = parseFloat(change);
        setChange(formated ? formated?.toFixed(2)?.toString() : "");
    };

    const handleSetMesa = (text: string) => {
        let formatedText = justNumbers(text);
        if (isNaN(formatedText)) {
            setTable("");
        } else {
            setTable(formatedText);
        }

    };

    const handleCheckBoxNumber = () => {
        setIsCheckBox(!isCheckBox);
        if (!isCheckBox) setAddressNumber("S/N");
    };

    const handleSelectCity = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        try {
            setCity(event.target.value);

            if (event.target.value === "none") {
                setDeliveryTime(0);
                setTaxa(0);
                return;
            }

            await getNeighborhoods(header, event.target.value);

            setNeighborhood("none");
            setIdEntrega(0);
            setDeliveryTime(0);
            setTaxa(0);
        } catch (err) {
            createGlobalError(err);
        }
    };

    const handleSelectNeightborhood = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setNeighborhood(event.target.value);
        const item = neighborhoods.find(
            (item) => item.id_bairro.toString() === event.target.value
        );
        if (item) {
            setIdEntrega(item.id_entrega);
            setDeliveryTime(item.tempo_entrega);
            if (subtotal >= info.valor_minimo_pedido) {
                //VERIFICAR ENTREGA GRATIS
                const data = new Date().getDay();
                switch (data) {
                    case 0:
                        if (item.domingo > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    case 1:
                        if (item.segunda > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    case 2:
                        if (item.terca > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    case 3:
                        if (item.quarta > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    case 4:
                        if (item.quinta > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    case 5:
                        if (item.sexta > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    case 6:
                        if (item.sabado > 0)
                            setTaxa(0);
                        else
                            setTaxa(item.valor);
                        break;
                    default:
                        break;
                }
                //setTaxa(item.valor);
            }
        } else {
            setDeliveryTime(0);
            setTaxa(0);
        }
    };

    const handleCalculateTotal = async () => {
        /* Variaveis de valores locais */
        let tempSubTotal = 0;
        let tempCompTotal = 0;

        /* Percorre o array e soma nas variaveis de valores */
        const sessionCart: Cart[] = JSON.parse(
            decryptText(sessionStorage.getItem("@CHEF:cart"))
        );
        if (sessionCart?.length > 0) {
            for (const iteratorCart of sessionCart) {
                tempSubTotal += calculeTotal(iteratorCart, iteratorCart.quant);
                tempCompTotal += iteratorCart.comp_total;
            }
        }
        /* Percorre o array e soma nas variaveis de valores */
        const sessionCombos: Combo[] = JSON.parse(
            decryptText(sessionStorage.getItem("@CHEF:combos"))
        );
        if (sessionCombos?.length > 0) {
            for (const iteratorCombos of sessionCombos) {
                tempSubTotal += iteratorCombos.quant * iteratorCombos.valor;
            }
        }
        /* Seta os states de valores */
        setSubtotal(tempSubTotal);
        setCompTotal(tempCompTotal);
        return tempSubTotal;
    };

    const findCitiesAndNeighborhoods = async () => {
        try {
            setShowLoading(true);
            const resp = await getCities(header);
            if (client && client.id_municipio) {
                await getNeighborhoods(header, client.id_municipio);
                setCity(client.id_municipio.toString());
                setNeighborhood(client.id_bairro.toString());
                const founded = neighborhoods.find(
                    (item) => item.id_bairro.toString() === neighborhood
                );
                setDeliveryTime(founded?.tempo_entrega);
            } else {
                await getNeighborhoods(header, resp[0]?.id_municipio);
            }

            setShowLoading(false);
        } catch (err) {
            setShowLoading(false);
            createGlobalError(err);
        }
    };

    const handleGoBack = () => {
        history.push(`/${header}/Cart`);
    };

    const handleGoBackInformacoes = () => {
        setIsRestaurantClose(false);
        history.push(`/${header}/Informacoes`);
    };

    const handleFinishCard = async () => {
        setOpenModalPedidoEnvido(false);
        history.push(`/${header}/ficha/${ficha?.type}/${ficha?.number}`);
    };

    const handleChangeNumber = (text: string) => {
        let formatText = justNumbers(text);
        setAddressNumber(String(formatText || ""));
        setIsCheckBox(false);
    };

    useEffect(() => {
        (async () => {
            try {
                if (deliveryType === "RETIRADA") {
                    setDeliveryTime(0);
                    setTaxa(0);
                } else {
                    await handlePhoneInputBlurEvent();
                }
            } catch (err) {
                createGlobalError(err);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryType]);

    useEffect(() => {
        (async () => {
            try {
                setShowLoading(true);
                const tempSubTotal = await handleCalculateTotal();
                const localInfo = await getInfo(header);
                const payments = await getPayments(header);
                setHash_venda(generateHash());
                if (!ficha && info?.liberar_pedido_mediante_pagamento) {
                    setPayment("");
                }
                const paymentArray = []
                if (payment) {
                    payments.forEach((item) => {
                        paymentArray.push(item);
                    })

                    setPaymentList(paymentArray);
                }
                if (
                    localInfo.deliveryDate[indexHorario].entrega === 1 &&
                    !ficha &&
                    tempSubTotal >= localInfo.valor_minimo_pedido
                ) {
                    //setDeliveryType("ENTREGA");
                    setDeliveryType("SELECIONAR");
                    await findCitiesAndNeighborhoods();
                } else {
                    setDeliveryType("RETIRADA");
                }
                setShowLoading(false);
                // Caso o pedido esteja com valor 0
                if (tempSubTotal <= 0 && history.location.pathname.includes("Cart")) {
                    createGlobalError("Algum erro relacionado ao navegador ocorreu e as informações do pedido se perderam, tente novamente.");
                }
            } catch (err) {
                createGlobalError(err);
            }
        })();
        return () => {
            clearAppContextNotLocalStorage();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const prepTime: number = getPrepTime();
        calcTimeDelivery(prepTime, false, deliveryTime);
    }, [neighborhood, deliveryTime, calcTimeDelivery, getPrepTime])

    const showTimeDelivery = () => {
        return (
            neighborhood !== 'none' &&
            <div className="container-infoCart">
                <FaMotorcycle size={21} color="#757575" />
                <span className="info-cart">
                    Previsão de entrega:
                    <span style={{ fontWeight: "bold" }}>{getDayMonth("minutes", timeDelivery)}</span>
                    entre <span style={{ fontWeight: "bold" }}>{minToHour(timeNoDelivery)}</span> e <span style={{ fontWeight: "bold" }}>{minToHour(timeDelivery)}</span>
                </span>
            </div>
        )
    }

    const showDiscountValue = () => {
        return (
            coupon?.value ?
                (<Row label="Total com desconto" data={(subtotal - coupon.value) + taxa} />)
                : null
        )
    }

    const showPayments = () => {
        return (
            info.key_mercadopago ?
                <PaymentBrick amount={subtotal} localValue={payment} localDisabled={!phoneRegex.test(phone)} localOnChange={(event) => setPayment(event.target.value)} paymentList={paymentList} onlineOnChange={setPayment} deliveryType={deliveryType} />
                : <select
                    value={payment}
                    disabled={!phoneRegex.test(phone)}
                    onChange={(event) => setPayment(event.target.value)}
                >
                    <option
                        key={0}
                        value={"SELECIONAR"}
                    >
                        SELECIONAR
                    </option>
                    {
                        paymentList.length > 0 && paymentList.filter((e) => (e.descricao !== 'PIX ONLINE' && e.descricao !== 'CREDITO ONLINE')).map((item) => (
                            <option
                                key={item.id_forma_pagto}
                                value={item.descricao}
                                disabled={item.disponivel !== 'TODOS' ? item.disponivel === deliveryType ? false : true : false}
                            >
                                {item.descricao.toUpperCase()}
                            </option>
                        ))
                    }
                </select>
        )
    }

    if (globalError) {
        return (
            <div className="default-container">
                <Alert
                    message={globalError}
                    danger={true}
                    button="Voltar"
                    onClose={handleGoBack}
                />
            </div>
        );
    }
    if (showLoading || !info) {
        return (
            <div className="default-container">
                <Loading />
            </div>
        );
    }
    if (sendClicked && !onlinePayID) {
        return (
            <div className="default-container">
                <Loading />
            </div>
        )
    }
    return (
        <div className="Cart">
            {showLoading && <Loading />}
            {openScannerQrCode && (
                <ScannerQrCode
                    onAction={handleAction}
                    header={header}
                    numberComanda={ficha?.number}
                    onClose={() => setOpenScannerQrCode(false)}
                />
            )}
            {orderContextError && (
                <Alert
                    message={orderContextError}
                    danger={true}
                    button="Ok"
                    onClose={clearOrderContextError}
                />
            )}
            {showSucessAlert && (
                <Alert
                    message="Seu pedido foi realizado com sucesso! Você pode acompanhar o status na próxima página"
                    danger={false}
                    button="Ok"
                    onClose={handleSuccess}
                />
            )}
            {isComanda && (
                <Alert
                    message={message}
                    danger={true}
                    button="Ok"
                    onClose={() => setIsComanda(false)}
                />
            )}
            {isRestaurantClose && (
                <Alert
                    message="No momento estamos fechados!"
                    danger={true}
                    button="Ok"
                    onClose={handleGoBackInformacoes}
                />
            )}
            {openModalPedidoEnvido && (
                <ModalPedidoEnviado
                    color={info.cor_padrao}
                    onClose={handleFinishCard}
                />
            )}

            <HeaderDefault
                label={
                    ficha
                        ? `${ficha.type.toLocaleUpperCase()} ${ficha.number}`
                        : "FINALIZAR PEDIDO"
                }
                onClick={() => history.push(`/${header}/cart`)}
            />
            <div className="container">
                {onlinePayID && (
                    <PaymentStatus payment={onlinePayID} onClose={() => {
                        setonlinePayID('');
                        setSendClicked(false);
                    }}
                        onSuccess={() => {
                            setonlinePayID('');
                            handleAction(true);
                        }}
                    />
                )}
                <div className="col">
                    {!ficha && (
                        <div className="section">
                            <ListHeader label1="INFORMAÇÕES DO PEDIDO" details={true} />
                            <div className="section-input">
                                <Input
                                    label="Número de Telefone"
                                    type="text"
                                    disabled={false}
                                    value={phone}
                                    onChangeText={handlePhoneMask}
                                    onBlur={() => { }}
                                    placeholder="(xx) xxxxx-xxxx"
                                >
                                    <FiPhone size={22} className="icon-input" />
                                </Input>
                                {!showInformacoes && (
                                    <div className="container-buttonShowInformacoes">
                                        <button
                                            className="btn finishCart"
                                            onClick={handlePhoneInputBlurEvent}
                                        >
                                            CONTINUAR
                                        </button>
                                    </div>
                                )}

                                {showInformacoes && (
                                    <>
                                        <Input
                                            label="Seu Nome"
                                            type="text"
                                            disabled={!phoneRegex.test(phone)}
                                            value={name}
                                            onChangeText={(e) => {
                                                if (!/\d/.test(e))
                                                    setName(e)
                                            }}
                                            onBlur={() => null}
                                        >
                                            <FiUser size={22} className="icon-input" />
                                        </Input>
                                        <div
                                            className={`Input ${!phoneRegex.test(phone) ? "disabled" : null
                                                }`}
                                        >
                                            <label>Entrega ou Retirada?</label>
                                            <select
                                                value={deliveryType}
                                                disabled={!phoneRegex.test(phone)}
                                                onChange={(event) => {
                                                    setDeliveryType(event.target.value);
                                                }}
                                            >
                                                <option value="SELECIONAR">Selecionar</option>
                                                {info.deliveryDate[indexHorario].entrega === 1 &&
                                                    subtotal >= info.valor_minimo_pedido && (
                                                        <option value="ENTREGA">Entrega</option>
                                                    )}
                                                {info.deliveryDate[indexHorario].retirada === 1 && (
                                                    <option value="RETIRADA">Retirada</option>
                                                )}
                                            </select>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {!ficha &&
                        deliveryType === "ENTREGA" &&
                        subtotal >= info.valor_minimo_pedido &&
                        showInformacoes && (
                            <div className="section">
                                <ListHeader label1="INFORMAÇÕES DE ENTREGA" details={true} />
                                <div className="section-input">
                                    <Input
                                        label="Logradouro (Rua, endereço, etc...)"
                                        type="text"
                                        disabled={!phoneRegex.test(phone)}
                                        value={address.toUpperCase()}
                                        onChangeText={setAddress}
                                        onBlur={() => null}
                                    >
                                        <FiMapPin size={22} className="icon-input" />
                                    </Input>
                                    <div className="section-inputNumero">
                                        <Input
                                            label="Número"
                                            type="text"
                                            disabled={!phoneRegex.test(phone)}
                                            value={String(addressNumber)}
                                            onChangeText={(text) => handleChangeNumber(text)}
                                            onBlur={() => null}
                                        >
                                            <FiHome size={22} className="icon-input" />
                                        </Input>
                                        <div className="section_inputCheckbox">
                                            <input
                                                type="checkbox"
                                                onChange={handleCheckBoxNumber}
                                                checked={isCheckBox}
                                            />
                                            <span>S/N</span>
                                        </div>
                                    </div>
                                    <Input
                                        label="Complemento (Casa, referência, etc...)"
                                        type="text"
                                        disabled={!phoneRegex.test(phone)}
                                        value={complement.toUpperCase()}
                                        onChangeText={setComplement}
                                        onBlur={() => null}
                                    >
                                        <FiTag size={22} className="icon-input" />
                                    </Input>
                                    <div
                                        className={`Input ${!phoneRegex.test(phone) ? "disabled" : null
                                            }`}
                                    >
                                        <label>Cidade</label>
                                        <select
                                            value={city}
                                            disabled={!phoneRegex.test(phone)}
                                            onChange={handleSelectCity}
                                        >
                                            <option value="none" disabled>
                                                Selecione uma cidade
                                            </option>
                                            {cities?.map((item, index) => (
                                                <option
                                                    key={index.toString()}
                                                    value={item.id_municipio.toString()}
                                                >
                                                    {item.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {city !== "none" && (
                                        <div
                                            className={`Input ${!phoneRegex.test(phone) ? "disabled" : null
                                                }`}
                                        >
                                            <label>Bairro</label>
                                            <select
                                                value={neighborhood}
                                                disabled={!phoneRegex.test(phone)}
                                                onChange={handleSelectNeightborhood}
                                            >
                                                <option value="none" disabled>
                                                    Selecione um bairro
                                                </option>
                                                {neighborhoods.map((item, index) => (
                                                    <option
                                                        key={index.toString()}
                                                        value={item.id_bairro.toString()}
                                                    >
                                                        {item.nome} - {
                                                            getTaxaGratis(item) === 0 ?
                                                                `GRÁTIS`
                                                                : formatValue(getTaxaGratis(item))
                                                        }
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    {
                                        showTimeDelivery()
                                    }

                                    <div className="saveData">
                                        <input
                                            type="checkbox"
                                            id="saveData"
                                            checked={saveData}
                                            onChange={() => setSaveData(!saveData)}
                                        />
                                        <label htmlFor="saveData">
                                            Lembrar meus dados para próximas compras
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}

                    {!ficha &&
                        <div className="section-input">
                            {(deliveryType === "ENTREGA" || deliveryType === "RETIRADA") && showInformacoes && (
                                <div
                                    className={`Input ${!phoneRegex.test(phone) ? "disabled" : null
                                        }`}
                                >
                                    <label>Forma de pagamento:</label>
                                    {showPayments()}
                                </div>
                            )}
                            {(deliveryType === "ENTREGA" || deliveryType === "RETIRADA") &&
                                payment === "DINHEIRO" &&
                                showInformacoes && (
                                    <Input
                                        label="Troco para..."
                                        type="number"
                                        disabled={!phoneRegex.test(phone)}
                                        value={change}
                                        onChangeText={setChange}
                                        onBlur={handleChangeInputBlurEvent}
                                    >
                                        <FiDollarSign size={22} className="icon-input" />
                                    </Input>
                                )}
                        </div>
                    }

                    <div className="section">
                        {ficha && info.smart_identificar_cliente === 1 && (
                            <>
                                <ListHeader
                                    label1="Informe seu fone e nome para se identificar"
                                    details={true}
                                    finishCart={true}
                                />
                                <div className="section-valorFinishCart">
                                    <>
                                        <Input
                                            label="Número de Telefone"
                                            type="text"
                                            disabled={false}
                                            value={phone}
                                            onChangeText={handlePhoneMask}
                                            onBlur={handlePhoneInputBlurEvent}
                                            placeholder="(xx) xxxxx-xxxx"
                                        >
                                            <FiPhone size={24} className="icon-input" />
                                        </Input>
                                        <Input
                                            label="Seu Nome"
                                            type="text"
                                            disabled={!phoneRegex.test(phone)}
                                            value={name}
                                            onChangeText={setName}
                                            onBlur={() => null}
                                        >
                                            <FiUser size={24} className="icon-input" />
                                        </Input>
                                    </>
                                </div>
                            </>
                        )}
                        {ficha && ficha.type === "Comanda" && (
                            <>
                                <ListHeader
                                    label1="Informe a mesa que voce está localizado"
                                    details={true}
                                    finishCart={true}
                                />
                                <div style={{ paddingInline: 5 }}>
                                    <Input
                                        label="Mesa"
                                        type="number"
                                        disabled={
                                            info.smart_identificar_cliente
                                                ? !phoneRegex.test(phone)
                                                : false
                                        }
                                        value={table}
                                        onChangeText={(text) => handleSetMesa(text)}
                                        onBlur={() => null}
                                    >
                                        <FiUser size={24} className="icon-input" />
                                    </Input>
                                </div>
                            </>
                        )}
                        <div style={{ paddingInline: 5 }}>
                            {!ficha && deliveryType === "ENTREGA" && (
                                <>
                                    <Row label="Subtotal" data={subtotal} />
                                    <Row label="Taxa de Entrega" data={taxa} />
                                </>
                            )}
                            <Row label="Total" data={subtotal + taxa} />
                            {showDiscountValue()}
                            {uniqueCouponWarning && (
                                <div className="container-desconto"> <span style={{ color: '#b8232a' }}>{uniqueCouponWarning}</span> </div>
                            )}
                        </div>
                        <div style={{ paddingInline: 5, marginBottom: 80 }}></div>
                    </div>
                    {ficha && (
                        <div className="send-order-warning">
                            <span>Envie o pedido e aguarde.</span>
                        </div>
                    )}
                </div>
            </div>

            <div className={ficha ? "floating-container piscar" : "floating-container"}>
                <button
                    disabled={sendClicked || (!showInformacoes && ficha?.type !== "Mesa" && table === "") || ((!name || !phone) && info.smart_identificar_cliente > 0) || (ficha?.type === "Comanda" && info.smart_identificar_cliente === 0 && table === "")}
                    className={`btn btn-primary ${(!showInformacoes && ficha?.type !== "Mesa" && table === "") || ((!name || !phone) && info.smart_identificar_cliente > 0) || (ficha?.type === "Comanda" && info.smart_identificar_cliente === 0 && table === "")
                        ? "opacity"
                        : ""
                        }`}
                    onClick={
                        !ficha
                            ? () => handleAction()
                            : info.escanear_qr_para_confirmar > 0
                                ? () => setOpenScannerQrCode(true)
                                : () => handleAction()
                    }
                >
                    ENVIAR PEDIDO
                </button>
            </div>
        </div>
    );
};

export default FinishCartNoGeo;