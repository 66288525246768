import React from "react";
import { formatValue } from "../utils/functions";

type RowProps = { label: string; data: string | number; ativaGray?: boolean };

const Row: React.FC<RowProps> = ({ label, data, ativaGray }) => {
	if (label === "Entrega Prevista" || label === "Retirada Prevista") {
		data =
			new Date(data).getUTCHours() +
			":" +
			new Date(data).getUTCMinutes().toString().padStart(2, "0");
	}
	return (
		<div className={`Row`}>
			<span className={`${label === 'TOTAL COM A ENTREGA' ? 'span-total' : ''}${ativaGray ? 'span-gray' : ''}`}>{label}:</span>
			<span className={`${label === 'TOTAL COM A ENTREGA' ? 'span-total' : ''}${ativaGray ? 'span-gray' : ''}`}>{typeof data == "number" ? formatValue(data) : data}</span>
		</div>
	);
};

export default Row;
