import React from "react";

interface ListHeaderProps {
	label1: string
	label2?: string
	details: boolean
	finishCart?: boolean
	buttonAtualizar?: any
	buttonAtualizarFunction?: any
}

const ListHeader: React.FC<ListHeaderProps> = ({ label1, label2, details, finishCart, buttonAtualizar, buttonAtualizarFunction }) => {
	return (
		<div className={`list-header ${details ? 'details' : ''} `} style={{ paddingInline: `${finishCart ? 10 + 'px' : ''}` }}>
			<div className="list-header-row">
				<span style={{ fontSize: `${finishCart ? 0.8 + 'rem' : ''}` }}>{label1.toLocaleUpperCase()}</span>
			</div>
			<span>{label2}</span>

		</div>
	);
};

export default ListHeader;
