import { LoadScript } from "@react-google-maps/api";
import React from "react";
const libraries = ['places']

interface containerProps {
  googleKey: string;
}

const ContainerLoadScript: React.FC<containerProps> = ({ children, googleKey }) => {
  return (
    <LoadScript
      googleMapsApiKey={googleKey}
      //@ts-ignore
      libraries={libraries}
    >
      {children}
    </LoadScript>
  );
};

export default ContainerLoadScript;
