import React from "react";
import { FiAlertOctagon } from "react-icons/fi";

interface AlertProps {
	message: string;
	button: string;
	danger: boolean;
	onClose(): void;
}

const Alert: React.FC<AlertProps> = ({ message, button, danger, onClose }) => {
	return (
		<div className="Alert">
			<div className="box">
				<div className={`circle ${danger ? "danger" : "success"}`}>
					<FiAlertOctagon size={24} color="#fff" />
				</div>

				<p className="message">{message}</p>

				<button className={danger ? "danger" : "success"} onClick={onClose}>
					{button}
				</button>
			</div>
		</div>
	);
};

export default Alert;
