import React, { useEffect, useState } from "react";

import { Combo } from "../utils/types";
import { calculeTotal, formatValue } from "../utils/functions";

import "./styles.css";
import { BsTrashFill } from "react-icons/bs";
import RowCartItem from "./RowCartItem";
import { FaPencilAlt } from "react-icons/fa";
import { BiMinus, BiPlus } from "react-icons/bi";

type ComboItemProps = { item: Combo; onRemove(): void, onEdit(): void, onIncrease?(): void, onDecrease?(): void };

const ComboItem: React.FC<ComboItemProps> = ({ item, onRemove, onEdit, onIncrease, onDecrease }) => {
	const [shakeQuant, setShakeQuant] = useState(false);
	useEffect(() => {
		setShakeQuant(true);
		setTimeout(() => {
			setShakeQuant(false);
		}, 1000);
	}, [item.quant])

	return (
		<div className="ComboItem">
			<div className="content">
				<button
					className="change-amount"
					onClick={onDecrease}
				>
					<BiMinus size={20} color="#757575" />
				</button>
				<div className={shakeQuant ? "content-amount shakeCart" : "content-amount"}>
					<span>{item.quant}x</span>
				</div>
				<button
					className="change-amount"
					onClick={onIncrease}
				>
					<BiPlus size={20} color="#757575" />
				</button>
				<div className="infos">
					<span>{item.nome}</span>
					{item.custom_obs && (
						<RowCartItem label="OBS.:" label2={item.custom_obs} />
					)}

					{item.itens?.map((el, index) => {
						let components = [];
						components.push(
							<RowCartItem
								label="-"
								label2={el.nome}
								key={index.toString()}
								isItem={true}
								isNegrito={true}
							/>
						);

						if (el.custom_obs) {
							components.push(
								<RowCartItem label="OBS.:" label2={el.custom_obs} />
							);
						}

						if (el.observacoes?.length > 0) {
							el.observacoes?.forEach((obs, index) => {
								components.push(
									<RowCartItem
										label="OBS.:"
										label2={obs.descricao}
										key={index.toString()}
									/>
								);
							});
						}

						if (el.opcionais?.length > 0) {
							el.opcionais?.forEach((comp, index) => {
								if (!comp.isChecked) {
									components.push(
										<RowCartItem
											label="OBS.:"
											label2={comp.descricao_insumo}
											key={index.toString()}
										/>
									);
								}
							});
						}

						if (el.adicionais?.length > 0) {
							el.adicionais?.forEach((comp, index) => {
								if (comp.isChecked) {
									components.push(
										<RowCartItem
											label="COM:"
											label2={comp.descricao_insumo}
											key={comp.id_produto_insumo.toString()}
										/>
									);
								}
							});
						}
						return components;
					})}
				</div>

				<div className="info-value">
					<span>{formatValue(calculeTotal(item, item.quant))}</span>
					<div className="ComboItemIcons">
						<button
							className="remove"
							onClick={onRemove}
							title={`Remover ${item.nome}`}
						>
							<BsTrashFill size={25} color="#757575" />
						</button>
						<button
							className="remove"
							title={`Editar ${item.nome}`}
							onClick={onEdit}
						>
							<FaPencilAlt size={25} color="#757575" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ComboItem;
