import React from "react";

import Routes from "./app/routes";
import ScrollToTop from "./app/components/ScrollToTop";

const App: React.FC = () => {
	return (
		<>
			<ScrollToTop />
			<Routes />
		</>
	);
};

export default App;
