import React, { useState, createContext } from "react";
import server from "../services/newServer";

import { Category, ProductDetails } from "../utils/types";

export interface ProductContextType {
	product: ProductDetails;
	productCombo: ProductDetails;
	products: Array<Category>;
	productContextError: any;

	getProducts(header: string, hasFicha: boolean, mesa?: any, tipoFicha?: string): Promise<Array<Category>>;
	getProductById(
		header: string,
		id: string,
		comboId?: string,
		isComboPizza?: boolean
	): Promise<ProductDetails>;
	getProductByIdPergunta(
		header: string,
		id: string,
		comboId?: string,
		isComboPizza?: boolean
	): Promise<ProductDetails>;
	createProductContextError(err: any): void;

	clearProduct(): void;
	clearProductCombo(): void;
	clearProducts(): void;
	clearProductContextError(): void;
	clearProductContext(): void;
}

const ProductContext: React.Context<ProductContextType | any> = createContext(
	{}
);

export const ProductProvider: React.FC = ({ children }) => {
	const [product, setProduct] = useState<ProductDetails | any>(null);
	const [productCombo, setProductCombo] = useState<ProductDetails | any>(null);
	const [products, setProducts] = useState<Array<Category>>([]);

	const [productContextError, setProductContextError] = useState<any>(null);

	const getProducts = async (header: string, hasFicha: boolean, mesa?: any, tipoFicha?: string) => {
		try {
			const resp = await server.get(
				`/categories?${hasFicha ? "disponivel_local" : "disponivel_online"
				}=true`,
				{
					headers: { id: header },
				}
			);
			const dias = ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'];
			const dataAtual = new Date();
			const hour = dataAtual.getHours() < 10 ? 0 + dataAtual.getHours() : dataAtual.getHours();
			const minutes = dataAtual.getMinutes() < 10 ? 0 + dataAtual.getMinutes() : dataAtual.getMinutes();
			const seconds = dataAtual.getSeconds() < 10 ? 0 + 0 + dataAtual.getSeconds() : dataAtual.getSeconds();
			const horaAtual = `${hour}:${minutes}:${seconds}`;
			const diaAtual = dataAtual.getDay();
			//VERIFICA SE OS PRODUTOS ESTAO DISPONIVEIS NO HORARIO E DIA
			let categories = resp.data;
			let arrayCategories: any = [];
			categories.forEach((category, index) => {
				arrayCategories.push(category);
				let productsArray: any = []
				category?.products.forEach((product, index) => {
					if (product?.productSchedules?.length > 0) {
						const hasAvailability = product.productSchedules.filter((horario) => dias[diaAtual] === horario?.dia.toLocaleUpperCase())

						if (hasAvailability.length > 0) {
							const [atualHour, atualMinute] = horaAtual.split(':').map(Number);
							const [inicioHour, inicioMinute] = hasAvailability[0].hora_inicio.split(':').map(Number);
							const [fimHour, fimMinute] = hasAvailability[0].hora_fim.split(':').map(Number);
							if (
								(atualHour > inicioHour && atualHour < fimHour) || // Hora atual está entre o início e o fim
								(atualHour === inicioHour && atualMinute >= inicioMinute) || // Hora atual é a de início e os minutos são iguais ou maiores
								(atualHour === fimHour && atualMinute <= fimMinute) // Hora atual é a de fim e os minutos são iguais ou menores
							) {
								productsArray.push(product);
							}
						}
					} else {
						productsArray.push(product);
					}
				});
				arrayCategories[index].products = productsArray;
			});

			let respProducts = arrayCategories;

			if (mesa) {
				try {
					const fichas = await server.get(`/ficha/${mesa}/${tipoFicha}`, { headers: { id: header } });
					const dontShowCategories = await server.get(`/area-group/${fichas.data.id_ambiente}`, { headers: { id: header } });
					dontShowCategories.data.forEach((dontShow: any) => {
						respProducts = respProducts.filter((cat) => cat.id_grupo !== dontShow.id_grupo);
					});
				} catch (error) {
					console.log("Erro ao buscar ambientes");
				}
			}

			setProducts(respProducts);
			return respProducts;
		} catch (err) {
			createProductContextError(err);
		}
	};

	const getProductById = async (
		header: string,
		id: string,
		idCombo?: string,
		isComboPizza?: boolean
	) => {
		try {
			let resp = null;
			let url = "";
			if (idCombo) {
				url = `/combos/${idCombo}/choices/${id}`;
			} else {
				url = `/products/${id}`;
			}
			resp = await server.get(url, { headers: { id: header } });
			if (resp.data) {
				resp.data.perguntas.map((item) => {
					if (item.obrigatorio === 1) {
						item.obrigatorio = true;
					} else {
						item.obrigatorio = false;
					}
					item.answers.map((answers) => {
						answers.isChecked = false;
						//Seta se a resposta e visivel ou nao
						if (answers.id_produto) {
							getProductByIdPergunta(header, answers.id_produto).then((value) => {
								answers.visivel = value.visivel;
							});
						}
						return answers;
					});
					return item;
				});
			}
			if (isComboPizza) {
				setProductCombo(resp.data);
			} else {
				setProduct(resp.data);
			}
			return resp.data;
		} catch (err) {
			createProductContextError(err);
		}
	};
	const getProductByIdPergunta = async (
		header: string,
		id: string,
		idCombo?: string
	) => {
		try {
			let resp = null;
			let url = "";
			if (idCombo) {
				url = `/combos/${idCombo}/choices/${id}`;
			} else {
				url = `/products/${id}`;
			}
			resp = await server.get(url, { headers: { id: header } });

			return resp.data;
		} catch (err) {
			createProductContextError(err);
		}
	};

	const createProductContextError = (err: any) => {
		try {
			if (typeof err == "string") {
				setProductContextError(err);
			} else {
				if (err.response.data) {
					setProductContextError(
						err.response.data.message || err.response.data.type
					);
				} else {
					setProductContextError(err.error || err.err);
				}
			}
		} catch (err) {
			setProductContextError(
				"Algum processo do site não foi finalizado ou não foi possível realiza-lo. Tente novamente!"
			);
		}
	};

	const clearProduct = () => setProduct(null);
	const clearProductCombo = () => {
		setProductCombo(null);
	};
	const clearProducts = () => setProducts([]);

	const clearProductContextError = () => setProductContextError(null);
	const clearProductContext = () => {
		setProduct(null);
		setProducts([]);
		setProductContextError(null);
	};

	return (
		<ProductContext.Provider
			value={{
				product,
				productCombo,
				products,
				productContextError,

				getProducts,
				getProductById,
				getProductByIdPergunta,

				clearProduct,
				clearProducts,
				clearProductCombo,
				createProductContextError,
				clearProductContextError,
				clearProductContext,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

export default ProductContext;
