import React, { useState, useEffect, createContext } from "react";
import server from "../services/newServer";

import {
	Information,
	City,
	Neighborhood,
	Client,
	Ficha,
	highlights,
	DeliveryDate,
} from "../utils/types";
export interface AppContextType {
	info: Information;
	highlights: Array<highlights>;
	cities: Array<City>;
	horas: Array<any>;
	payments: Array<any>;
	neighborhoods: Array<Neighborhood>;
	client: Client;
	ficha: Ficha;
	globalError: any;
	indexHorario: number;
	location: any;
	timeNoDelivery: number;
	timePreparation: number;
	timeDelivery: number;

	setInfo(info: Information): void;

	getInfo(header: string): Promise<Information>;
	getHighlights(header: string, dia_semana: number): Promise<highlights>;
	getPlaces(): Promise<Array<{ id: string; name: string }>>;
	getCities(header: string): Promise<Array<City>>;
	getHours(header: string): Promise<Array<any>>;
	getPayments(header: string): Promise<Array<any>>;
	getNeighborhoods(
		header: string,
		id: number | string
	): Promise<Array<Neighborhood>>;
	createNeighborhood(header: string, descricao: string, id_municipio: number, id_entrega: number): Promise<any>;
	getClientInfo(header: string, phone: string): Promise<Client>;
	getOrderStatus(
		header: string,
		id: number | string
	): Promise<{ status: string; id_venda_local: string | null }>;
	handleChangeLocation(location: any): void;
	createFicha(type: string, number: number, header: string): void;
	calcTimeDelivery(timePrep: number, cart?: boolean, entrega?: any): void;
	clearFicha(): void;

	createGlobalError(err: any): void;
	clearInfo(): void;
	clearClient(): void;
	clearAppContext(): void;
	clearAppContextNotLocalStorage(): void;
	clearSessionStorage(): void;
	clearGlobalErrors(): void;
	verifyDiscountCoupon(header: string, coupon: string): any;
	verifyFicha(header: string, numero: number, tipo: string);
	verifyDiscountCouponUnique(header: string, coupon: number, celular: string): any;
	createTrackingLog(header: string, params: any): void;
}

const AppContext: React.Context<AppContextType | any> = createContext({});

export const AppProvider: React.FC = ({ children }) => {
	const [info, setInfo] = useState<Information | any>(null);
	const [highlights, setHighlights] = useState<Array<any>>([]);
	const [globalError, setGlobalError] = useState<any>(null);

	const [client, setClient] = useState<Client | any>(null);
	const [cities, setCitites] = useState<Array<City>>([]);
	const [neighborhoods, setNeighborhoods] = useState<Array<Neighborhood>>([]);
	const [ficha, setFicha] = useState<Ficha>(null);

	const [location, setLocation] = useState<any>();

	const [indexHorario, setIndexHorario] = useState<number>(0);

	const [horas, setHoras] = useState<Array<any>>([]);
	const [payments, setPayments] = useState<Array<any>>([])
	const [timeNoDelivery, setTimeNoDelivery] = useState(0);
	const [timePreparation, setTimePreparation] = useState(0);
	const [timeDelivery, setTimeDelivery] = useState(0);

	useEffect(() => {
		const storaged = localStorage.getItem("@CHEF:ficha");
		if (storaged) setFicha(JSON.parse(storaged));
	}, []);

	const getInfo = async (header: string) => {
		try {
			const resp = await server.get<Information>("/info", {
				headers: { id: header },
			});
			let root = document.documentElement;
			if (resp.data.cor_padrao) {
				root.style.setProperty("--color-primary", `${resp.data.cor_padrao}`);
			}
			if (resp.data.cor_padrao_texto) {
				root.style.setProperty("--color-default-text", `${resp.data.cor_padrao_texto}`);
			}
			if (resp.data.deliveryDate.length > 1) {
				verificaHorarioAberto(resp.data.deliveryDate);
			}
			setInfo(resp.data);
			return resp.data;
		} catch (err) {
			if (err.response.status === 404) {
				createGlobalError("página não encontrada");
			} else {
				createGlobalError(err);
			}
		}
	};

	const handleChangeLocation = (location: any) => {
		setLocation(location);
	};

	const verificaHorarioAberto = (deliverys: DeliveryDate[]) => {
		for (let index = 0; index < deliverys.length; index++) {
			if (deliverys[index].aberto) {
				setIndexHorario(index);
			}
		}
	};

	const calcTimeDelivery = (prepTime: number, cart?: boolean, entrega?: any) => {
		const timeTotalNoDelivery = prepTime + info?.tempo_minimo_preparo;
		const tempoEntrega = cart ? 0 : (entrega ? entrega : Number(info?.tempo_maximo_entrega));
		const timeTotalDelivery: number =
			prepTime +
			info?.tempo_minimo_preparo +
			tempoEntrega;
		if (prepTime > 0) setTimePreparation(prepTime);
		else setTimePreparation(info?.tempo_minimo_preparo);
		setTimeNoDelivery(timeTotalNoDelivery);
		setTimeDelivery(timeTotalDelivery);
	};

	const getPayments = async (header: string) => {
		try {
			const resp = await server.get("/payment-method", {
				headers: { id: header },
			});
			setPayments(resp.data);
			return resp.data;
		} catch (err) {
			if (err.response.status === 404) {
				createGlobalError("página não encontrada");
			} else {
				createGlobalError(err);
			}
		}
	}

	const getHours = async (header: string) => {
		try {
			const resp = await server.get("/opening-hours/teste", {
				headers: { id: header },
			});
			setHoras(resp.data);
			return resp.data;
		} catch (err) {
			if (err.response.status === 404) {
				createGlobalError("página não encontrada");
			} else {
				createGlobalError(err);
			}
		}
	};

	const verifyFicha = async (header: string, numero: number, tipo: string) => {
		try {
			const resp = await server.get(`/ficha/${numero}/${tipo}`, {
				headers: { id: header },
			});
			if (resp?.data?.numero) return true;
		} catch (err) {
			if (err.response.status === 404) {
				createGlobalError("página não encontrada");
			} else {
				createGlobalError(err);
			}
		}
	};

	const getHighlights = async (header: string, dia_semana: number) => {
		try {
			setHighlights([]);
			const resp = await server.get<Array<highlights>>("/highlights", {
				headers: { id: header },
			});
			const newDestaques = resp.data.filter((item) => {
				if (item.domingo === 1) {
					if (dia_semana === 0) {
						return item;
					}
				}
				if (item.segunda === 1) {
					if (dia_semana === 1) {
						return item;
					}
				}
				if (item.terca === 1) {
					if (dia_semana === 2) {
						return item;
					}
				}
				if (item.quarta === 1) {
					if (dia_semana === 3) {
						return item;
					}
				}
				if (item.quinta === 1) {
					if (dia_semana === 4) {
						return item;
					}
				}
				if (item.sexta === 1) {
					if (dia_semana === 5) {
						return item;
					}
				}
				if (item.sabado === 1) {
					if (dia_semana === 6) {
						return item;
					}
				}
				return null;
			});
			setHighlights(newDestaques);
		} catch (err) {
			if (err.response.status === 404) {
				createGlobalError("página não encontrada");
			} else {
				createGlobalError(err);
			}
		}
	};

	const getCities = async (header: string) => {
		try {
			const resp = await server.get("/cities/served", {
				headers: { id: header },
			});
			setCitites(resp.data);
			return resp.data;
		} catch (err) {
			createGlobalError(err);
		}
	};

	const getNeighborhoods = async (header: string, id: number) => {
		try {
			const resp = await server.get(`/cities/${id}/neighborhoods`, {
				headers: { id: header },
			});
			setNeighborhoods(resp.data);
			return resp.data;
		} catch (err) {
			createGlobalError(err);
		}
	};

	const createNeighborhood = async (header: string, descricao: string, id_municipio: number, id_entrega: number) => {
		try {
			const resp = await server.post(`/neighborhoods`, {
				descricao: descricao,
				id_municipio: id_municipio,
				id_entrega: id_entrega,
				ativo: 1
			}, {
				headers: { id: header },
			})
			return resp;
		} catch (error) {
			createGlobalError(error);
		}
	}

	const getClientInfo = async (header: string, phone: string) => {
		try {
			const resp = await server.get(`/customers/${phone}`, {
				headers: { id: header },
			});
			if (resp.data) {
				setClient(resp.data);
			}
			return resp.data;
		} catch (err) {
			createGlobalError(err);
		}
	};

	const verifyDiscountCoupon = async (header: string, coupon: string) => {
		try {
			const resp = await server.get(`/discount-coupon`, {
				headers: { id: header },
			});
			if (resp.data) {
				const foundCoupon = resp.data.filter((item: any) => item?.codigo_desconto.toUpperCase() === coupon.toUpperCase());
				if (foundCoupon?.length > 0) {
					const cDate = foundCoupon[0].dt_validade?.toString().split("T");
					const couponDate = new Date(cDate[0]);
					const actualDate = new Date();

					const couponDateMonth = couponDate.getMonth();
					const couponDateYear = couponDate.getFullYear();
					const couponDateDay = couponDate.getUTCDate();

					const actualDateMonth = actualDate.getMonth();
					const actualDateYear = actualDate.getFullYear();
					const actualDateDay = actualDate.getDate();

					if (actualDateYear < couponDateYear) {
						return foundCoupon[0];
					} else if (actualDateYear === couponDateYear) {
						if (actualDateMonth < couponDateMonth) {
							return foundCoupon[0];
						} else if (actualDateMonth === couponDateMonth) {
							if (actualDateDay <= couponDateDay)
								return foundCoupon[0];
							else
								return false;
						}
					}

				} else {
					return false;
				}
			} else {
				return false;
			}

		} catch (err) {
			createGlobalError(err);
		}
	};

	const createTrackingLog = async (header: string, params: any) => {
		try {
			console.log(params);
			const resp = await server.post(`/tracking`,
				params,
				{
					headers: { id: header },
				})
			return resp;
		} catch (error) {
			console.log(error)
		}
	}

	const getOrderStatus = async (header: string, id: number) => {
		try {
			const resp = await server.get(`/orders/${id}/status`, {
				headers: { id: header },
			});
			return resp.data;
		} catch (err) {
			createGlobalError(err);
		}
	};

	const createFicha = (type: string, number: number, header: string) => {
		type = type.substring(0, 1).toUpperCase().concat(type.substring(1));
		setFicha({ type, number });
		localStorage.setItem(
			"@CHEF:ficha",
			JSON.stringify({ type, number, header })
		);
	};

	const clearFicha = () => {
		setFicha(null);
		localStorage.removeItem("@CHEF:ficha");
	};

	const createGlobalError = (err: any) => {
		try {
			if (typeof err == "string") {
				setGlobalError(err);
			} else {
				if (err.response.data) {
					setGlobalError(err.response.data.message || err.response.data.type);
				} else {
					setGlobalError(err.error || err.err);
				}
			}
		} catch (err) {
			setGlobalError(
				"Algum processo do site não foi finalizado ou não foi possível realiza-lo. Tente novamente!"
			);
		}
	};

	const verifyDiscountCouponUnique = async (header: string, coupon: number, celular: string) => {
		try {
			const resp = await server.post(`/discount-coupon/unique`, {
				celular: celular,
				id_cupom: coupon
			}, {
				headers: { id: header },
			});
			if (resp?.data.length > 0) {
				if (resp.data[0]?.utilizado > 0) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} catch (err) {
			createGlobalError(err);
		}
	};

	const clearInfo = () => setInfo(null);
	const clearClient = () => setClient(null);
	const clearGlobalErrors = () => setGlobalError(null);
	const clearAppContextNotLocalStorage = () => {
		setGlobalError(null);
		setClient(null);
		setCitites([]);
		setNeighborhoods([]);
	};
	const clearAppContext = () => {
		setGlobalError(null);
		setInfo(null);
		setClient(null);
		setFicha(null);
		setCitites([]);
		setNeighborhoods([]);
		localStorage.clear();
	};

	const clearSessionStorage = () => sessionStorage.clear();

	return (
		<AppContext.Provider
			value={{
				info,
				highlights,
				cities,
				neighborhoods,
				client,
				horas,
				payments,
				ficha,
				location,
				indexHorario,
				timeNoDelivery,
				timePreparation,
				timeDelivery,
				globalError,

				setInfo,
				getInfo,
				getHighlights,
				// getPlaces,
				getHours,
				getPayments,
				getCities,
				getNeighborhoods,
				createNeighborhood,
				getClientInfo,
				getOrderStatus,
				handleChangeLocation,
				calcTimeDelivery,
				verifyDiscountCoupon,
				verifyFicha,
				verifyDiscountCouponUnique,
				createTrackingLog,

				createFicha,
				clearFicha,
				clearAppContextNotLocalStorage,
				clearSessionStorage,

				createGlobalError,
				clearInfo,
				clearClient,
				clearAppContext,
				clearGlobalErrors,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
