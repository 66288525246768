import React from 'react'

const DeliveryStatus: React.FC<any> = ({ currentStep, delivery }: any) => {

    let steps = ['Aguardando confirmação', 'Recebido', 'Confirmado', 'Em produção'];

    if (delivery) {
        steps.push('Saiu para entrega');
        steps.push('Entregue');
    } else
        steps.push('Pronto para retirada');

    const isInArray = steps.filter((step, index) => step?.toLocaleUpperCase() === currentStep);

    const indicatorArray = steps;

    if (steps[0].toLocaleUpperCase() !== currentStep)
        steps = steps.slice(1);
    else
        steps.pop();

    return (
        <div className="progress-tracker">
            {isInArray.length > 0 ? steps.map((step, index) => (
                <div key={index} className={`step ${(currentStep === step?.toLocaleUpperCase()) ? 'active' : ''}`}>
                    <div className="step-indicator">{indicatorArray.indexOf(step) + 1}</div>
                    <div className="step-label">{step}</div>
                </div>
            )) :
                <span>{currentStep}</span>
            }
        </div>
    );
}

export default DeliveryStatus;
