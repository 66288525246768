import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { FiDollarSign } from "react-icons/fi";
import { FaMotorcycle } from "react-icons/fa";

import Alert from "../components/Alert";
import AlertCart from "../components/AlertCart";
import Loading from "../components/Loading";
import CartItem from "../components/CartItem";
import ComboItem from "../components/ComboItem";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";

import { Neighborhood } from "../utils/types";
import { calculeTotal, formatValue, getDayMonth, minToHour } from "../utils/functions";

import "./styles.css";
import HeaderDefault from "../components/HeaderDefault";
import FloatingContainer from "../components/FloatingContainer";
import Input from "../components/Input";

const Cart: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header } = useParams();

	const {
		info,
		neighborhoods,
		client,
		ficha,
		indexHorario,
		timeDelivery,
		timeNoDelivery,
		timePreparation,
		getInfo,
		globalError,
		calcTimeDelivery,
		createGlobalError,
		clearGlobalErrors,
		verifyDiscountCoupon,
	} = useContext<AppContextType>(AppContext);
	const {
		cart,
		combos,
		orderContextError,
		removeProductFromCart,
		removeProductFromCombo,
		createOrderContextError,
		clearOrderContextError,
		getPrepTime,
		handleAttCartScreen,
		trackPixelEvent,
		changeQuantity,
		addCoupon,
	} = useContext<OrderContextType>(OrderContext);
	/* Booleanos */
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const [, setShowSuccessAlert] = useState<boolean>(false);

	const [showPedidoMinimoAlert, setShowPedidoMinimoAlert] =
		useState<boolean>(false);

	/* Valores */
	const [subtotal, setSubtotal] = useState<number>(0);

	const idBairroValidar: String =
		client && client.id_bairro ? client.id_bairro.toString() : "none";
	const bairro: Neighborhood = neighborhoods.find(
		(item) => item.id_bairro.toString() === idBairroValidar
	);
	/* ID da entrega */
	const [taxa] = useState<number>(bairro ? bairro.valor : 0);

	/*Confirmar remover item*/
	const [showConfirmRemove, setShowConfirmRemove] = useState(false);
	/*Index e tipo pra remover*/
	const [indexToRemove, setIndexToRemove] = useState<any>({ tipo: null, index: null });

	const [cupom, setCupom] = useState<string>('');
	const [cupomError, setCupomError] = useState<string>('');
	const [actualCupom, setActualCupom] = useState<any>('');

	const [showHaveOrderAlert, setShowHaveOrderAlert] = useState<boolean>(false);

	const handleCheckAction = async () => {
		let localOrder: any = localStorage.getItem("@CHEF:order");
		if (localOrder) {
			localOrder = JSON.parse(localOrder);
			const timeNow = new Date()
			if (localOrder.subtotal === subtotal && ((timeNow.getTime() - localOrder.createdAt) < 1800000))
				setShowHaveOrderAlert(true);
			else
				handleAction();
		} else {
			handleAction();
		}
	}

	const handleAction = async () => {
		try {
			if (info?.deliveryDate[0]?.entrega === 0 && info?.deliveryDate[0]?.retirada === 0 && !ficha) {
				createOrderContextError("Não estamos entregando e nem aceitando retirada no momento.");
				return;
			}
			if (info.valor_minimo_pedido !== 0) {
				if (subtotal + taxa < info.valor_minimo_pedido && !ficha) {
					setShowPedidoMinimoAlert(true);
				} else {
					trackPixelEvent("InitiateCheckout");
					if (cupom)
						addCoupon(actualCupom);
					// info.deliveryDate[indexHorario].entrega = 1;
					if (info.usa_geolocalizacao > 0 && !ficha) {
						history.push(`/${header}/FinishCart`);
					} else {
						history.push(`/${header}/FinishCartNoGeo`);
					}
				}
			} else {
				trackPixelEvent("InitiateCheckout");
				if (cupom)
					addCoupon(actualCupom);
				// info.deliveryDate[indexHorario].entrega = 1;
				if (info.usa_geolocalizacao > 0 && !ficha) {
					history.push(`/${header}/FinishCart`);
				} else {
					history.push(`/${header}/FinishCartNoGeo`);
				}
			}
		} catch (err) {
			setShowLoading(false);
			createOrderContextError(err);
		}
	};

	const handlePedidoMinimo = () => {
		trackPixelEvent("InitiateCheckout");
		setShowSuccessAlert(false);
		info.deliveryDate[indexHorario].entrega = 0;
		if (info.usa_geolocalizacao > 0) {
			history.push(`/${header}/FinishCart`);
		} else {
			history.push(`/${header}/FinishCartNoGeo`);
		}
	};

	const handleCalculateTotal = () => {
		/* Variaveis de valores locais */
		let tempSubTotal = 0;

		/* Percorre o array e soma nas variaveis de valores */
		if (cart?.length > 0) {
			cart.forEach((item) => {
				tempSubTotal += calculeTotal(item, item.quant);
			});
		}

		/* Percorre o array e soma nas variaveis de valores */
		if (combos?.length > 0) {
			combos.forEach((item) => {
				tempSubTotal += item.total;
			});
		}

		/* Seta os states de valores */
		setSubtotal(tempSubTotal);
		// setCompTotal(tempCompTotal);
	};

	const handleGoBack = () => {
		clearGlobalErrors();
		history.push(`/${header}`);
	};

	useEffect(() => {
		window.addEventListener("load", (event) => {
			handleAttCartScreen();
		});
		if (cart?.length === 0 && combos?.length === 0) {
			if (!ficha) {
				history.push(`/${header}`);
			} else {
				history.push(`/${header}/ficha/${ficha.type}/${ficha.number}`);
			}
		} else {
			handleCalculateTotal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cart, combos]);

	useEffect(() => {
		try {
			addCoupon('');
			(async () => {
				if (!info) await getInfo(header);
			})();
			const prepTime: number = getPrepTime();
			calcTimeDelivery(prepTime, true);
		} catch (err) {
			createGlobalError(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCoupon = async () => {
		setCupomError('');
		if (actualCupom) {
			setActualCupom('');
			setCupom('');
			return;
		}
		const resp = await verifyDiscountCoupon(header, cupom);
		if (resp) {
			if (actualCupom?.code !== cupom) {
				const desconto = (subtotal / 100) * resp.perc_desconto;
				setActualCupom({
					id: resp.id_cupom_desconto,
					code: cupom,
					value: desconto
				});
			}
		} else {
			setCupomError('Cupom invalido.')
		}
	}
	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="Voltar"
					onClose={handleGoBack}
				/>
			</div>
		);
	}

	if (!info) {
		return (
			<div className="default-container">
				<Loading />
			</div>
		);
	}
	return (
		<div className="Cart">
			{showLoading && <Loading />}
			{orderContextError && (
				<Alert
					message={orderContextError}
					danger={true}
					button="Ok"
					onClose={clearOrderContextError}
				/>
			)}

			{showPedidoMinimoAlert && (
				<AlertCart
					message={`Pedido abaixo de ${formatValue(
						info.valor_minimo_pedido
					)}, apenas retirada, deseja continuar ?`}
					danger={true}
					onClick={() => {
						handlePedidoMinimo();
					}}
					onClose={() => history.push(`/${header}`)}
				/>
			)}

			{showHaveOrderAlert && (
				<AlertCart
					message={"Um pedido com o mesmo valor foi enviado recentemente, deseja enviar outro pedido?"}
					danger={true}
					onClick={handleAction}
					onClose={() => setShowHaveOrderAlert(false)}
				/>
			)}

			{showConfirmRemove && (
				<AlertCart
					message={`Remover produto do carrinho?`}
					danger={true}
					onClick={() => {
						if (indexToRemove.tipo === "COMBO") {
							removeProductFromCombo(indexToRemove.index)
						} else {
							removeProductFromCart(indexToRemove.index);
						}
						setIndexToRemove({ tipo: null, index: null });
						setShowConfirmRemove(false);
					}}
					onClose={() => setShowConfirmRemove(false)}
				/>
			)
			}
			<HeaderDefault
				label="MEU PEDIDO"
				onClick={() => history.push(`/${header}`)}
			/>

			<div className="container">
				<div className="col cart">
					{cart?.length > 0 &&
						cart.map((item, index) => (
							<div className="paddingcart" key={index.toString()}>
								<CartItem
									key={index.toString()}
									item={item}
									onRemove={() => {
										setShowConfirmRemove(true);
										setIndexToRemove({
											tipo: "PRODUTO",
											index: index
										});
										//removeProductFromCart(index);
									}}
									onIncrease={() => {
										if (item?.unidade === "KG") {
											changeQuantity(index, item.quant + 0.100)
										} else {
											changeQuantity(index, item.quant + 1)
										}
									}}
									onDecrease={() => {
										const minQuant = item?.unidade === "KG" ? 0.101 : 1
										if (item.quant > minQuant) {
											if (item?.unidade === "KG") {
												changeQuantity(index, item.quant - 0.100)
											} else {
												changeQuantity(index, item.quant - 1)
											}
										}
									}}
									onEdit={() => {
										history.push(
											`/${header}/details/${item.id}/${index}`
										)
									}}
								/>
							</div>
						))}
					{combos?.length > 0 &&
						combos.map((item, index) => (
							<div className="paddingcart" key={index.toString()}>
								<ComboItem
									key={index.toString()}
									item={item}
									onRemove={() => {
										setShowConfirmRemove(true);
										setIndexToRemove({
											tipo: "COMBO",
											index: index
										});
										//removeProductFromCombo(index)
									}}
									onIncrease={() => {
										changeQuantity(index, item.quant + 1, "PERSONALIZADO")
									}}
									onDecrease={() => {
										if (item.quant > 1) {
											changeQuantity(index, item.quant - 1, "PERSONALIZADO")
										}
									}}
									onEdit={() => {
										history.push(
											`/${header}/combo/${item.tipo}/${item.id}/${index}`
										)
									}}
								/>
							</div>
						))}
					<div className="container-maisItems">
						<span onClick={() => history.push(`/${header}`)}>
							ADICIONAR MAIS ITENS
						</span>
					</div>
					<div className="container-subtotal">
						<span>SUBTOTAL: {formatValue(subtotal)}</span>
						{actualCupom.value && <span>VALOR COM DESCONTO: {formatValue(subtotal - actualCupom.value)}</span>}
					</div>

					{info?.utiliza_cupom_desconto > 0 &&
						<div className="container-desconto">
							<Input
								label="Cupom de desconto:"
								type="text"
								disabled={false}
								value={cupom}
								onChangeText={setCupom}
								onBlur={() => { }}
								placeholder="CUPOM10"
							/>
							<button onClick={handleCoupon}>{actualCupom ? 'REMOVER CUPOM' : 'APLICAR CUPOM'}</button>
							{cupomError && <span style={{ color: '#b8232a' }}>{cupomError}</span>}
						</div>
					}
				</div>
				<div className="containerInfo">
					{!ficha && timeDelivery > 0 && (
						<div className="container-infoCart">
							<FaMotorcycle size={21} color="#757575" />
							<span className="info-cart">
								Previsão de entrega:
								<span style={{ fontWeight: "bold" }}>{getDayMonth("minutes", timeDelivery)}</span>
								entre <span style={{ fontWeight: "bold" }}>{minToHour(timeNoDelivery)}</span> e <span style={{ fontWeight: "bold" }}>{minToHour(timeDelivery)}</span>
							</span>
						</div>
					)}
					{ficha && (
						<>
							<div className="container-infoCart">
								<span className="info-cart" style={{ fontWeight: "bold" }}>
									Não esqueça de finalizar seu pedido!
								</span>
							</div>
							<div className="container-infoCart">
								<span className="info-cart">
									Tempo de preparo: <span style={{ fontWeight: "bold" }}>{timePreparation}</span> minutos
								</span>
							</div>
						</>
					)}
					{!ficha && info.valor_minimo_pedido !== 0 && (
						<div className="container-infoCart">
							<FiDollarSign size={18} color="#757575" />
							<span className="info-cart">
								Pedido minimo entrega: {formatValue(info.valor_minimo_pedido)}
							</span>
						</div>
					)}
				</div>
			</div>
			<FloatingContainer
				label="FINALIZAR"
				onClick={handleCheckAction}
				total={actualCupom ? subtotal - actualCupom?.value : subtotal}
			/>
		</div>
	);
};

export default Cart;
