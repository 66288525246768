import React, { useContext, useEffect, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import AppContext, { AppContextType } from "../contexts/AppContext";

interface IncreaseDecreaseButtonsProps {
	value: number;
	unidade?: string;
	setShowAmountModal?(): void;
	onIncrease(): void;
	onDecrease(): void;
	changeQuantity?(value: any): Function | any
}

const IncreaseDecreaseButtons: React.FC<IncreaseDecreaseButtonsProps> = ({
	value,
	unidade,
	setShowAmountModal,
	onIncrease,
	onDecrease,
	changeQuantity
}) => {
	const {
		info
	} = useContext<AppContextType>(AppContext);
	const [quantity, setQuantity] = useState<any>(value);
	useEffect(() => {
		setQuantity(value);
	}, [value])


	return (
		<div className={unidade?.toLocaleUpperCase() === "KG" ? "IncreaseDecreaseButtons kg-box" : "IncreaseDecreaseButtons"}>
			<button onClick={onDecrease}>
				<FiMinus size={24} color={info?.cor_padrao_texto ? info.cor_padrao_texto : "#FFF"} />
			</button>

			{unidade?.toLocaleUpperCase() === "KG" ?
				<>
					<button className="button-kg" onClick={setShowAmountModal}><span>{value.toString().replace('.', ',')} Kg</span></button>
				</>
				:
				<input
					className="input-quantity"
					type="number"
					value={quantity}
					autoComplete="off"
					onChange={(event) => {
						if (parseInt(event.target.value) <= 0) {
							setQuantity(1);
							if (changeQuantity)
								changeQuantity(1);
						} else if (parseInt(event.target.value) > 999) {
							setQuantity(999);
							if (changeQuantity)
								changeQuantity(999);
						} else {
							setQuantity(event.target.value);
							if (changeQuantity)
								changeQuantity(event.target.value);
						}
					}}
					placeholder={quantity.toString()}
				/>

			}

			<button onClick={onIncrease}>
				<FiPlus size={24} color={info?.cor_padrao_texto ? info.cor_padrao_texto : "#FFF"} />
			</button>
		</div>
	);
};

export default IncreaseDecreaseButtons;
