import React from "react";
import { AiFillLike } from "react-icons/ai";

interface ModalPedidoEnviadoProps {
  color: string
  onClose(): void
}

const ModalPedidoEnviado: React.FC<ModalPedidoEnviadoProps> = ({ color , onClose}) => {
	return (
		<div className="Alert">
			<div className="box">
				<div className={`circle pedido`}>
					<AiFillLike size={30} color={`${color}`} />
				</div>
				<p className="message pedido">PEDIDO ENVIADO</p>
				<p className="message pedido">Agora é só aguardar enquanto preparamos tudo para você!</p>
				<button className="success pedido" onClick={onClose}>
          FECHAR
				</button>
			</div>
		</div>
	);
};

export default ModalPedidoEnviado;
