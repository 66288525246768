import React from 'react';
import { formatValue } from '../utils/functions';
import { Combo } from '../utils/types';
import RowCartItem from './RowCartItem';

type ISmartSuccessCombo = {
    item: Combo;
};

const SmartSuccessCombo: React.FC<ISmartSuccessCombo> = ({
    item
}) => {
    return (
        <div className="SmartSucessItem">
            <div className="CartItem">
                <div className="content">
                    <div className="content-amount">
                        <span>
                            {item?.quant}x
                        </span>
                    </div>

                    <div className="infos">
                        <span>{item?.nome}</span>
                        <span>{formatValue(item.valor * item.quant)}</span>
                        {item.custom_obs && (
                            <RowCartItem label="-" label2={item.custom_obs} />
                        )}
                        {item.itens?.map((el, index) => {
                            let components = [];
                            components.push(
                                <RowCartItem
                                    label="-"
                                    label2={el.nome}
                                    key={index.toString()}
                                    isItem={true}
                                    isNegrito={true}
                                />
                            );

                            if (el.custom_obs) {
                                components.push(
                                    <RowCartItem key={index.toString()} label="-" label2={el.custom_obs} />
                                );
                            }

                            if (el.observacoes?.length > 0) {
                                el.observacoes?.forEach((obs, index) => {
                                    components.push(
                                        <RowCartItem
                                            label="OBS.:"
                                            label2={obs.descricao}
                                            key={index.toString()}
                                        />
                                    );
                                });
                            }

                            if (el.opcionais?.length > 0) {
                                el.opcionais?.forEach((comp, index) => {
                                    if (!comp.isChecked) {
                                        components.push(
                                            <RowCartItem
                                                label="OBS.:"
                                                label2={comp.descricao_insumo}
                                                key={index.toString()}
                                            />
                                        );
                                    }
                                });
                            }

                            if (el.adicionais?.length > 0) {
                                el.adicionais?.forEach((comp, index) => {
                                    if (comp.isChecked) {
                                        components.push(
                                            <RowCartItem
                                                label="COM:"
                                                label2={comp.descricao_insumo}
                                                key={index.toString()}
                                            />
                                        );
                                    }
                                });
                            }
                            return components;
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmartSuccessCombo;