import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import Alert from "../components/Alert";
import Loading from "../components/Loading";
// import ProductItem from "../components/ProductItem";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import ProductContext, { ProductContextType } from "../contexts/ProductContext";

import {
	Ficha,
	Information,
	Order,
	ProductItem as ProductItemType,
} from "../utils/types";

import voatro from "../../assets/images/logoVoatroVazado.png";
import server from "../services/newServer";

import "./styles.css";
import Header from "../components/Header";
import Menu from "../components/Menu";
import FloatingContainer from "../components/FloatingContainer";
import ComboContext, { ComboContextType } from "../contexts/ComboContext";
import ProductItem from "../components/ProductItem";
import SearchBar from "../components/SearchBar";
import { calculeTotal } from "../utils/functions";
const Home: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header, notrender, type, number } = useParams();

	const {
		info,
		highlights,
		ficha,
		indexHorario,
		globalError,
		getInfo,
		getHighlights,
		createFicha,
		createGlobalError,
		clearGlobalErrors,
		clearFicha,
		verifyFicha
	} = useContext<AppContextType>(AppContext);
	const { cart, combos, initFacebookPixel } =
		useContext<OrderContextType>(OrderContext);
	const { products, productCombo, productContextError, getProducts } =
		useContext<ProductContextType>(ProductContext);
	const { comboCategories, clearComboContext, clearSessionComboProduct } =
		useContext<ComboContextType>(ComboContext);
	const [hasOrder, setHasOrder] = useState<boolean>(false);
	const [muralMessage, setMuralMessage] = useState<any>();
	const [filteredProducts, setFilteredProducts] = useState<any>([]);

	//const [removeBallon, setRemoveBallon] = useState(false);

	const [goTopButton, setGoTopButton] = useState(false);

	const [activeLink, setActiveLink] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const handleNextPage = (item: ProductItemType) => {
		if (info.disponivel) {
			if (!item.tipo_produto_cheff) {
				history.push(`/${header}/details/${item.id_produto}`);
			} else {
				history.push(
					`/${header}/combo/${item.tipo_produto_cheff.toLowerCase()}/${item.id_produto
					}`
				);
			}
		} else {
			createGlobalError(
				`No momento não estamos conseguindo atender novos pedidos. Tente novamente mais tarde.`
			);
		}
	};

	const handleClosed = () => {
		clearFicha();
		clearGlobalErrors();

		history.push(`/${header}/informacoes`);
	}

	const handleRefresh = () => {
		clearFicha();
		clearGlobalErrors();

		if (ficha) history.push(`/${header}`);
		window.location.reload();
	};

	const handleCartClick = () => {
		history.push(`/${header}/cart`);
		// trackPixelEvent("InitiateCheckout");
	};

	const handleActiveLink = (id: number) => {
		setActiveLink(id);
	};

	const handleCalculateTotal = () => {
		/* Variaveis de valores locais */
		let tempSubTotal = 0;
		// let tempCompTotal = 0;

		/* Percorre o array e soma nas variaveis de valores */
		if (cart.length > 0) {
			cart.forEach((item) => {
				tempSubTotal += calculeTotal(item, item.quant);
				// tempCompTotal += item.comp_total;
			});
		}

		/* Percorre o array e soma nas variaveis de valores */
		if (combos.length > 0) {
			combos.forEach((item) => {
				tempSubTotal += item.total;
			});
		}
		/* Seta os states de valores */
		setTotal(tempSubTotal);
	};

	const clickDestaques = (id: number) => {
		products.map((category) => {
			category.products.map((product) => {
				if (product.id_produto === id) {
					handleNextPage(product);
					return product;
				}
				return null;
			});
			return category;
		});
	};

	const handleFinishCombo = () => {
		const tipo =
			comboCategories[0].sabor_pizza > 0 ? "pizza" : "personalizavel";
		history.push(`/${header}/combo/${tipo}/${productCombo.id}`);
	};

	const handleClearCombo = () => {
		clearComboContext();
		clearSessionComboProduct();
	};

	const checkPermissionProductVoamenu = (
		localInfo: Information,
		storagedFicha: string
	) => {
		if (localInfo.voamenu_smart === 0) {
			if (type || number) {
				history.push(`/${header}`);
				window.location.reload();
				return;
			}

			if (storagedFicha) {
				clearFicha();
				history.push(`/${header}`);
				window.location.reload();
				return;
			}
		}

		if (localInfo.voamenu_delivery === 0) {
			if ((!type && !number) || storagedFicha) {
				if (!storagedFicha) {
					createGlobalError("Voamenu delivery não está disponivel!");
					//window.location.reload();
					return;
				}
			}
		}
	};

	const handleCheckRestaurantFicha = (storagedFicha: string) => {
		if (storagedFicha) {
			const formatedFicha: Ficha = JSON.parse(storagedFicha);
			if (formatedFicha?.header !== header) {
				clearFicha();
				setHasOrder(false);
			}
		}
	};

	const setTitleandBackgroundImg = (localInfo: Information) => {
		const headerEl = document.getElementById("header");
		document.title = `${localInfo.nome_estabelecimento ? localInfo.nome_estabelecimento : localInfo.nome} - Voamenu`;
		if (localInfo.background_imagem_url) {
			/* @ts-ignore */
			headerEl.style.backgroundImage = `
							url(${localInfo.background_imagem_url})
							`;
		}
		// MUDA O FAVICON PARA O DO RESTAURANTE
		if (localInfo) {
			const link = document.querySelector("link[rel~='icon']");
			if (link !== null) {
				if (localInfo.logo_url) {
					//@ts-ignore
					link.href = `${localInfo.logo_url}`;
				}
			}
		}
	};
	useEffect(() => {
		(async () => {
			//SCROLLAR PARA O ITEM 
			const getScroll = localStorage.getItem("@CHEF:scroll");
			if (getScroll) {
				console.log(getScroll);
				let scrolllou = false
				const timeoutScroll = setInterval(() => {
					const element = document.getElementById(getScroll);
					if (element) {
						element.scrollIntoView({ behavior: "smooth", block: "center" });
						localStorage.removeItem("@CHEF:scroll");
					}
					if (scrolllou) clearInterval(timeoutScroll);
					scrolllou = true
				}, 100);
			}
			try {
				// Verificar se passou 12 horas do ultimo acesso para limpar cache
				const getCache = localStorage.getItem("@CHEF:Cached");
				if (getCache) {
					const getCacheObj = JSON.parse(getCache);
					const newTime = new Date().getTime();
					const timeHasExpired = newTime - 43200000 ///43200000; // 12 horas
					if (getCacheObj.cachedDate < timeHasExpired) {
						localStorage.clear();
						setHasOrder(false);
					}
				} else {
					localStorage.clear();
					localStorage.setItem("@CHEF:Cached", JSON.stringify({ cachedDate: new Date().getTime() }));
				}
				//FIM
				if (products.length === 0) {

					//Se for mesa seta o numero para filtrar os ambientes
					let mesa: any = false;
					if (ficha?.type.toLowerCase() === "mesa") mesa = ficha.number;
					if (type === "mesa") mesa = number;

					await getProducts(header, !!ficha || (!!type && !!number), mesa, (type ? type.toLocaleUpperCase() : ficha?.type?.toLocaleUpperCase()));
				}
				server.defaults.headers = { id: header };
				if (notrender) { // SETA O BACKGROUND E NOME DENOVO
					let localInfo;
					localInfo = !info ? await getInfo(header) : { ...info };
					setTitleandBackgroundImg(localInfo);
				}
				if (!notrender) {
					window.addEventListener("load", (event) => {
						handleClearCombo();
					});

					//Se for mesa seta o numero para filtrar os ambientes
					let mesa: any = false;
					if (ficha?.type.toLowerCase() === "mesa") mesa = ficha.number;
					if (type === "mesa") mesa = number;

					getProducts(header, !!ficha || (!!type && !!number), mesa, (type ? type.toLocaleUpperCase() : ficha?.type?.toLocaleUpperCase()));

					let localInfo;
					localInfo = !info ? await getInfo(header) : { ...info };

					setTitleandBackgroundImg(localInfo);
					initFacebookPixel(header, localInfo.codigo_pixel);

					await getHighlights(
						header,
						localInfo.deliveryDate[indexHorario]?.dia_da_semana
					);

					if (!ficha && type && number) {
						createFicha(type, number, header);
					}

					const storagedFicha = localStorage.getItem("@CHEF:ficha");
					checkPermissionProductVoamenu(localInfo, storagedFicha);

					handleCheckRestaurantFicha(storagedFicha);

					const storagedOrder = localStorage.getItem("@CHEF:order");
					if (storagedOrder) {
						const formatedOrder: Order = JSON.parse(storagedOrder);
						if (formatedOrder.header === header) {
							setHasOrder(true);
						} else {
							localStorage.removeItem("@CHEF:order");
							clearFicha();
							setHasOrder(false);
						}

						// Verificar se passou 12 horas e resetar a order
						//if (formatedOrder) {
						/*const hasCreatedTime: any = JSON.parse(storagedOrder);
						if (hasCreatedTime.createdAt) {
							var newTime = new Date().getTime();
							const timeHasExpired = newTime - 900000 ///43200000; // 12 horas
							if (hasCreatedTime.createdAt < timeHasExpired) {
								//localStorage.removeItem("@CHEF:order");
								//localStorage.removeItem("@CHEF:ficha");
								localStorage.clear();
								setHasOrder(false);
							}
						}*/
						//}
					}
				}
				if (cart.length > 0 || combos.length > 0) {
					handleCalculateTotal();
				}
			} catch (err) {
				createGlobalError(err);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cart, combos]);

	useEffect(() => {
		const handleShowScrollButton = () => {
			window.pageYOffset > 400 ? setGoTopButton(true) : setGoTopButton(false);
		};

		window.addEventListener('scroll', handleShowScrollButton);

		return () => {
			window.removeEventListener('scroll', handleShowScrollButton)
		}

	}, [])

	// QUANDO CLICAR EM VOLTAR, VOLTA PARA TELA INICIAL
	useEffect(() => {
		const handlePopstate = () => {
			history.push(`/${header}`);
		};
		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	useEffect(() => {
		setMuralMessage(info?.mural_avisos?.split(/\r?\n|\r|\n/g));
	}, [info])

	useEffect(() => {
		(async () => {
			if (number) {
				const fichaExiste = await verifyFicha(header, number, type?.toLocaleUpperCase());
				if (!fichaExiste) {
					createGlobalError(`A mesa ou comanda de número: ${number} não existe.`);
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	/*
	//Limpa o editando quando vai para home
	useEffect(() => {
		sessionStorage.removeItem("@CHEF:editando");
	}, [])*/

	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="OK"
					onClose={globalError.includes("fechados") ? handleClosed : handleRefresh}
				/>
			</div>
		);
	}

	if (productContextError) {
		return (
			<div className="default-container">
				<Alert
					message={productContextError}
					danger={true}
					button="OK"
					onClose={handleRefresh}
				/>
			</div>
		);
	}
	if (!info) {
		return (
			<div className="default-container">
				<Loading />
			</div>
		);
	}
	const settings = {
		dots: true,
		centerMode: true,
		centerPadding: `${highlights.length >= 2 ? "20px" : "350px"}`,
		slidesToShow: highlights.length >= 2 ? 2 : 1,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: "40px",
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: "20px",
					slidesToShow: 1,
				},
			},
		],
	};

	const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	const handleSearch = (value: any) => {
		if (value !== '') {
			let encontrados: any = [];
			products.forEach(item => {
				const encontrou = item.products.filter((el: any) => el?.descricao?.toLocaleUpperCase().includes(value.toLocaleUpperCase()) || el?.descricao_completa?.toLocaleUpperCase().includes(value.toLocaleUpperCase()))

				if (encontrou?.length > 0)
					encontrados.push(...encontrou);
			});
			setFilteredProducts(encontrados);
		} else {
			setFilteredProducts([]);
		}
	}

	return (
		<div className="Home">
			<Header
				info={info}
				hasOrder={hasOrder}
				ficha={ficha}
				onClickVerMais={() => history.push(`/${header}/Informacoes`)}
				onClickSuccess={() => history.push(`/${header}/success`)}
			/>
			<Menu
				products={products}
				activeLink={activeLink}
				onClickActive={handleActiveLink}
			/>
			<SearchBar onSearch={handleSearch} />
			{info.mural_avisos && (
				<div className="list-header mural-avisos-text">
					{muralMessage?.map((message, index) => (
						<span key={index} >{message}</span>
					))}
				</div>
			)
			}
			{goTopButton && (
				<div className={`scrollToTop`}>
					<button
						className={'scrollToTopButton'}
						onClick={handleScrollToTop}
					>
						<BsFillArrowUpCircleFill size={40} color='black' opacity='0.7' />
					</button>
				</div>
			)}
			<div style={{ marginBottom: 10 }} className="container-destaque-desktop">
				{highlights?.length > 0 && (
					<Slider {...settings}>
						{highlights.map((item, index) => (
							<div
								key={index.toString()}
								onClick={() => clickDestaques(item?.id_produto)}
								className="container-destaque"
							>
								<img src={item.url_imagem} alt="" />
								<div className="texto-destaque">
									<span>{item?.titulo}</span>
									<span>{item?.sub_titulo}</span>
								</div>
							</div>
						))}
					</Slider>
				)}
			</div>
			<div className="content">
				{filteredProducts?.length <= 0 ?
					products
						.filter((item) => item.products.length > 0)
						.map((item, index) => (
							<div key={index.toString()}>
								<div className="list-header" id={item.id_grupo.toString()}>
									<b>
										<span>{item.alias || item.descricao}</span>
									</b>
									<div className="category-divider"></div>
								</div>
								<div className="content-products">
									{item.products.map((product, index) => (
										<ProductItem
											key={index.toString()}
											item={product}
											onClick={() => handleNextPage(product)}
											header={header}
										/>
									))}
								</div>
							</div>
						))
					:
					<div>
						<div className="content-products">
							{filteredProducts.map((product, index) => (
								<ProductItem
									key={index.toString()}
									item={product}
									onClick={() => handleNextPage(product)}
									header={header}
								/>
							))}
						</div>
					</div>
				}
				{/*(cart.length > 0 || combos.length > 0) && !removeBallon && ficha ?
					<button className={!removeBallon ? "send-order-label" : "send-order-label-dimiss"} onClick={() => setRemoveBallon(true)}>
						<span style={{ color: 'black' }}>Não esqueça de finalizar seu pedido!</span>
						<AiOutlineClose style={{ marginLeft: '25px', verticalAlign: 'middle' }} />
					</button>
					: null
				*/}
			</div>
			<footer>
				<img src={voatro} alt="voatro" />

				<div className="instagram">
					<span>@voatrosistemas</span>
				</div>
			</footer>
			{cart.length > 0 || combos.length > 0 ? (
				<FloatingContainer
					sizeCart={cart.length}
					sizeCombo={combos.length}
					label="VER CARRINHO"
					onClick={handleCartClick}
					sizeButton={true}
					total={total}
					isHome={true}
				/>
			) : null}
			{comboCategories.length > 0 ? (
				<FloatingContainer
					sizeCart={0}
					sizeCombo={0}
					label="TERMINAR COMBO"
					onClick={handleFinishCombo}
					total={total}
					incompletCombo={true}
				>
					<button
						className="btn btn-googlemaps back"
						onClick={handleClearCombo}
					>
						<AiOutlineArrowLeft size={24} color="#FFF" />
					</button>
				</FloatingContainer>
			) : null}
		</div>
	);
};

export default Home;
