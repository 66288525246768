import React from "react";

interface OptionNumeroFatiasProps {
	index: number;
	numberFatias: number;
	onSelectOptionNumberFatia(index: number): void;
}

const OptionNumeroFatias: React.FC<OptionNumeroFatiasProps> = ({
	index,
	numberFatias,
	onSelectOptionNumberFatia,
}) => {
	const handleClickOption = () => {
		onSelectOptionNumberFatia(index);
	};
	return (
		<li onClick={handleClickOption}>
			<div
				className={`span-checkbox ${numberFatias === index ? "active" : null}`}
			></div>
			<span>
				{index + 1} {index === 0 ? "SABOR" : "SABORES"}
			</span>
		</li>
	);
};

export default OptionNumeroFatias;
