import React from "react";

type RowCartItemProps = {
	label: string;
	label2: string;
	isItem?: boolean;
	isNegrito?: boolean;
};

const RowCartItem: React.FC<RowCartItemProps> = ({
	label,
	label2,
	isItem,
	isNegrito,
}) => {
	return (
		<div className={`SubItem margin ${isItem ? "item" : ""}`}>
			{isNegrito ? (
				<span>
					<b>
						{label} {label2}
					</b>
				</span>
			) : (
				<span>
					<b>{label}</b> {label2}
				</span>
			)}
		</div>
	);
};

export default RowCartItem;
