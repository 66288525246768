import React from "react";
import "./styles.css";

import gif from "../../assets/images/Spinner-1s-200px.gif";

const Loading: React.FC = () => {
	return (
		<div className="Loading">
			<div className="box">
				<img src={gif} alt="loading" />
			</div>
		</div>
	);
};

export default Loading;
