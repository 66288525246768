import React, { useContext, useState } from "react";
import { FiHome, FiTag } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RiMapPin2Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import Input from "./Input";
import InputGoogleMaps from "./InputGoogleMaps";
import Geocode from "react-geocode";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import useGeoLocation from "../hooks/useGeoLocation";
import Alert from "./Alert";
interface ModalAddressProps {
	header: string;
	color: string;
	withAdress: boolean;
	googleKey: string;
	onClose(): void;
}

const ModalAddress: React.FC<ModalAddressProps> = ({
	header,
	color,
	withAdress,
	googleKey,
	onClose,
}) => {
	const loc = useGeoLocation();
	const {
		address,
		addressNumber,
		neighborhood,
		city,
		handleGetDelivery,
		handleAdressConfirm,
		handleChangeAddressNumber,
		handleChangeAddressComplement,
		handleChangeNeighborhood,
	} = useContext<OrderContextType>(OrderContext);
	const [addressNumberModal, setAddressNumberModal] = useState<string>(
		addressNumber !== "" ? addressNumber : ""
	);
	const [isCheckBox, setIsCheckBox] = useState<boolean>(
		addressNumber?.toString() === "S/N" ? true : false
	);
	const [addressComplement, setAddressComplement] = useState<string>("");
	const [bairro, setBairro] = useState<string>("");
	const [notPermission, setNotPermission] = useState<boolean>(false);

	const handleSaveAddress = async () => {
		try {
			if (address === "") {
				mandatoryQuestionAnimation("address");
				return;
			}
			if (addressNumberModal === "" && !isCheckBox && addressNumber === "") {
				mandatoryQuestionAnimation("adressnumber");
				return;
			}
			if (addressComplement === "") {
				mandatoryQuestionAnimation("addresscomplement");
				return;
			}
			if (address !== "" && neighborhood === "none" && bairro === "") {
				mandatoryQuestionAnimation("addressbairro");
				return;
			}
			handleChangeAddressNumber(!isCheckBox ? addressNumberModal : "S/N");
			handleChangeAddressComplement(addressComplement);
			if (neighborhood === "none") handleChangeNeighborhood(bairro);
			onClose();
		} catch (error) {
			createOrderContextError(error);
		}
	};
	const mandatoryQuestionAnimation = (id: string) => {
		const el = document.getElementById(id);
		el?.animate(
			[
				// keyframes
				{ transform: "translate3d(-1px, 0, 0)" },
				{ transform: "translate3d(2px, 0, 0)" },
				{ transform: "translate3d(-4px, 0, 0)" },
				{ transform: "translate3d(4px, 0, 0)" },
				{ transform: "translate3d(-4px, 0, 0)" },
				{ transform: "translate3d(4px, 0, 0)" },
				{ transform: "translate3d(-4px, 0, 0)" },
				{ transform: "translate3d(4px, 0, 0)" },
				{ transform: "translate3d(-4px, 0, 0)" },
				{ transform: "translate3d(2px, 0, 0)" },
				{ transform: "translate3d(-1px, 0, 0)" },
			],
			{
				// timing options
				duration: 1000,
				iterations: 1,
			}
		);
	};
	const handleConfirmCurrentLocation = async () => {
		if (loc.coordinates) {
			const location = {
				coordinates: {
					latitude: loc.coordinates.lat,
					longitude: loc.coordinates.lng,
				}
			}
			const resp = await handleGetDelivery(header, location.coordinates);
			if (resp) {
				await getGeocode();
			}
		} else {
			setNotPermission(true);
		}
	};

	const handleCheckBox = () => {
		setIsCheckBox(!isCheckBox);
		if (!isCheckBox) setAddressNumberModal("");
	};

	const handleAdressNumberModal = (e: string) => {
		setAddressNumberModal(e);
		setIsCheckBox(false);
	};

	const getGeocode = async () => {
		try {
			Geocode.setApiKey(googleKey);
			await Geocode.fromLatLng(
				String(loc.coordinates.lat),
				String(loc.coordinates.lng)
			).then(
				(response) => {
					const address = response.results[0];
					handleAdressConfirm(address.address_components);
				},
				(error) => {
					console.error(error);
				}
			);
		} catch (err) {
			console.log(err);
		}
	};

	if (notPermission) {
		return (
			<div className="default-container">
				<Alert
					message="Você precisa aceitar a permissão de localização"
					danger={true}
					button="Voltar"
					onClose={() => setNotPermission(false)}
				/>
			</div>
		);
	}
	return (
		<div className="Alert address">
			<div className="container-modal-addAddress">
				<div className="container-modal-addAddress-header">
					<span>ADICIONAR ENDEREÇO</span>
					<button onClick={onClose}>
						<IoMdClose size={24} color="#58585a" />
					</button>
				</div>
				<div className="container-modal-addAddress-padding">
					{withAdress ? (
						<>
							<span className="span-address">
								{address}, {addressNumberModal}
							</span>
							<span className="span-address">
								{neighborhood}, {city}
							</span>
						</>
					) : (
						<InputGoogleMaps positionAtual={loc} header={header} googleKey={googleKey} />
					)}
					<div className="container-modal-addAddressNumber">
						<Input
							label="Número"
							type="number"
							disabled={false}
							value={addressNumberModal}
							onChangeText={(e) => handleAdressNumberModal(e)}
							onBlur={() => { }}
							id="adressnumber"
						>
							<FiHome size={24} className="icon-input" />
						</Input>
						<div className="section_inputCheckbox">
							<input
								type="checkbox"
								onChange={handleCheckBox}
								checked={isCheckBox}
							/>
							<label htmlFor="">S/N</label>
						</div>
						<Input
							label="Complemento"
							type="text"
							disabled={false}
							value={addressComplement}
							onChangeText={(e) => setAddressComplement(e)}
							onBlur={() => { }}
							placeholder="bloco 3, ap 201, casa, etc"
							id="addresscomplement"
						>
							<FiTag size={24} className="icon-input" />
						</Input>
					</div>
					{address !== "" && neighborhood === "none" && (
						<div className="container-modal-addAddressNumber bairro">
							<Input
								label="Bairro"
								type="text"
								disabled={false}
								value={bairro}
								onChangeText={(e) => setBairro(e)}
								onBlur={() => { }}
								placeholder=""
								id="addressbairro"
							>
								<HiOutlineLocationMarker size={24} className="icon-input" />
							</Input>
						</div>
					)}

					<button className="btn addAddress" onClick={handleSaveAddress}>
						SALVAR ENDEREÇO
					</button>
					<div className="cart-address">
						<div className="cart-address-row">
							<RiMapPin2Fill size={24} color="#58585a" />
							<div
								className="cart-address-info"
								onClick={handleConfirmCurrentLocation}
							>
								<span>Usar localização atual</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalAddress;
function createOrderContextError(err: any) {
	throw new Error("Function not implemented.");
}
