import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { GiClick } from "react-icons/gi";

import Alert from "../components/Alert";
import Loading from "../components/Loading";
import IncreaseDecreaseButtons from "../components/IncreaseDecreaseButtons";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import ComboContext, { ComboContextType } from "../contexts/ComboContext";
import ProductContext, { ProductContextType } from "../contexts/ProductContext";

import "./styles.css";
import HeaderDefault from "../components/HeaderDefault";
import { animationSwing, formatValue, incryptText, decryptText } from "../utils/functions";

import formaPizza from "../../assets/images/cPrancheta_1.png";
import pizza from "../../assets/images/newpizza.png";
import { ComboCategory } from "../utils/types";
import { RiCloseCircleFill } from "react-icons/ri";
import ListHeader from "../components/ListHeader";
import ButtonChangeCombo from "../components/ButtonChangeCombo";
import OptionNumeroFatias from "../components/OptionNumeroFatias";

const Combo: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header, tipo, id, editar } = useParams();

	const { info, ficha, indexHorario, globalError, createGlobalError } =
		useContext<AppContextType>(AppContext);
	const { addProductToCombo } = useContext<OrderContextType>(OrderContext);
	const {
		itens,
		comboCategories,
		comboCategoriesBorda,
		comboContextError,
		getComboCategories,
		removeProductFromItens,
		clearComboContext,
		//clearSessionComboProduct,
		clearComboItens,
		clearCategoriesBorda,
		addItemToEditCombo,
		changeCategoriesFinished,
	} = useContext<ComboContextType>(ComboContext);
	const { getProductById, clearProduct, productCombo, clearProductCombo } =
		useContext<ProductContextType>(ProductContext);

	const [quant, setQuant] = useState<number>(1);
	const [subtotal, setSubtotal] = useState<number>(0);
	const [compTotal, setCompTotal] = useState<number>(0);
	const [isRestaurantClose, setIsRestaurantClose] = useState<boolean>(false);
	const [obs, setObs] = useState<string>("");
	const [showLoading, setShowLoading] =
		useState<boolean>(false); /* @ts-ignore */
	const [openOptionNumberFatias, setOpenOptionNumberFatias] =
		useState<boolean>(false);

	const [numberFatias, setNumberFatias] = useState<number>(-1);
	const [valorPizza, setValorPizza] = useState<number>(0);

	const handleAction = async () => {
		try {
			if (!info?.deliveryDate[indexHorario]?.aberto) {
				setIsRestaurantClose(true);
				return;
			}
			const resp = verificaCombo();
			if (!resp) return;
			const localProduct = await getProductById(header, id);
			await addProductToCombo(
				localProduct,
				itens,
				quant,
				subtotal + valorPizza,
				compTotal,
				obs,
				tipo,
			);
			clearProduct();
			clearProductCombo();
			clearComboContext();
			clearCategoriesBorda();
			sessionStorage.removeItem("CHEF:numberFatia");
			history.push(`/${header}`);
		} catch (err) {
			createGlobalError(err);
		}
	};

	const verificaCombo = () => {
		//Se estiver editando seta os itens obrigatorios hasFinishied como true
		const isEditando = sessionStorage.getItem("@CHEF:editando");
		if (isEditando) {
			itens.forEach(element => {
				changeCategoriesFinished(element);
			});
		}
		let idCombo;
		let finishiedTrue;
		if (tipo === "pizza") {
			finishiedTrue = comboCategories.filter((el) => el.hasFinishied === true);
			if (finishiedTrue.length === 0) {
				animationSwing("pizza");
				return false;
			}
		}
		let finishied = comboCategories.filter(
			(el) => el.hasFinishied === false && el.obrigatorio > 0
		);
		let finishied2 = comboCategoriesBorda.filter(
			(el) => el.hasFinishied === false && el.obrigatorio > 0
		);
		if (finishied.length > 0 || finishied2.length > 0) {
			if (tipo === "pizza") {
				if (finishied.length > 0 && finishiedTrue.length !== numberFatias + 1) {
					idCombo = "pizza";
					animationSwing(idCombo);
					return false;
				} else {
					if (finishied2.length === 0) return true;
					idCombo = finishied2[0]?.name;
					animationSwing(idCombo);
					return false;
				}
			} else {
				idCombo = finishied[0]?.name;
				animationSwing(idCombo);
				return false;
			}
		} else {
			return true;
		}
	};

	const handleGoBack = () => {
		//clearSessionComboProduct();
		clearProductCombo();
		clearComboContext();
		clearCategoriesBorda();
		clearProduct();
		history.push(`/${header}`);
	};

	function handleCalculateTotal() {
		let total = 0;
		let compTotal = 0;
		let finishied = comboCategories.filter((el) => el.hasFinishied);
		if (finishied.length > 0) {
			finishied.forEach((el) => {
				let localItens = finishied.filter((item) => item.id === el.id);
				let localTotal = 0;

				if (localItens.length > 0) {
					localItens.forEach(
						(item) => {
							if (productCombo?.calcular_pizza_por_tamanho > 0 && tipo === "pizza")
								localTotal += item.value
							else
								localTotal += item.value / localItens.length
						}
					);
					total += localTotal / localItens.length;
				}
			});
		}
		if (tipo === "pizza") {
			let finishiedBorda = comboCategoriesBorda.filter((el) => el.hasFinishied);
			if (finishiedBorda.length > 0) {
				finishiedBorda.forEach((el) => {
					let localItens = finishiedBorda.filter((item) => item.id === el.id);
					let localTotal = 0;

					if (localItens.length > 0) {
						localItens.forEach(
							(item) => {
								if (productCombo?.calcular_pizza_por_tamanho > 0 && tipo === "pizza")
									localTotal += item.value
								else
									localTotal += item.value
							}
						);
						total += localTotal / localItens.length;
					}
				});
			}
		}

		if (itens.length > 0) {
			itens.forEach((item) => {
				if (item.adicionais.length > 0) {
					item.adicionais.forEach((comp) => {
						if (comp.isChecked) compTotal += comp.valor * comp.qtdade;
					});
				}
			});
		}
		console.log(compTotal);
		console.log(total);
		setCompTotal(compTotal);
		setSubtotal(total);
	}

	const divisaoPizza = (escolhas: number, index: number, item: any) => {
		if (item.sabor_pizza > 0) {
			if (escolhas === 1) {
				return "inteira";
			}
			if (escolhas === 2) {
				return `metade pt${index + 1}`;
			}
			if (escolhas === 3) {
				if (escolhas === index + 1) {
					return `metade pt${index + 1}meio`;
				}
				return `metade pt${index + 1}`;
			}
			if (escolhas === 4) {
				return `umQuarto pt${index + 1}`;
			}
		}
	};

	const selecionaPizza = (item: ComboCategory) => {
		if (!item.hasFinishied) {
			history.push(
				`/${header}/combo-products/${tipo}/${item.id}/${id}/${item.random}/${item.obrigatorio}`
			);
		}
	};

	const handleEditItem = (
		id_item: number,
		combo_id: number,
		random: number
	) => {
		history.push(
			`/${header}/details/${id_item}/${combo_id}/${tipo}/${id}/${random}/${1}`
		);
	};

	const handleGoBackInformacoes = () => {
		setIsRestaurantClose(false);
		clearComboContext();
		history.push(`/${header}/Informacoes`);
	};

	const handleSelectOptionNumberFatias = (index: number) => {
		setNumberFatias(index);
		sessionStorage.setItem("CHEF:numberFatia", index.toString());
		setOpenOptionNumberFatias(false);
		clearComboItens();
		comboCategories.map((combo) => (combo.hasFinishied = false));
	};

	const handleSetQuantity = (value: any) => {
		if (value > 0)
			setQuant(parseInt(value));
	}

	useEffect(() => {
		return () => {
			if (history.action === "POP") {
				handleGoBack();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (productCombo?.calcular_pizza_por_tamanho > 0 && tipo === "pizza") {
			setValorPizza(Number(productCombo.valor));
		}
	}, [productCombo, tipo])


	useEffect(() => {
		//Se estiver editando cria o session estorage
		const checkIsEditando = () => {
			let editando = null;
			if (editar && tipo !== "pizza") {
				editando = {
					editando: true,
					item: editar
				}
				sessionStorage.setItem("@CHEF:editando", incryptText(JSON.stringify(editando)));
				//Busca os itens do combo se estiver editando o combo
				const comboItens = sessionStorage.getItem("@CHEF:combos");
				if (comboItens && tipo !== "pizza") {
					const localComboItens = JSON.parse(decryptText(comboItens));
					let arrayItens = [];
					let localSubTotal = 0;
					let localCompTotal = 0;
					localComboItens[editar]?.itens.forEach(item => {

						item.adicionais.forEach(element => {
							localCompTotal += element.isChecked ? element.valor : 0;
						});
						localSubTotal += item.valor;
						arrayItens.push(item);
					});

					setCompTotal(localCompTotal)
					setSubtotal(localSubTotal);
					if (tipo !== "pizza") addItemToEditCombo(arrayItens);
				}
			} else if (editar && tipo === "pizza") {
				editando = {
					editando: true,
					item: editar
				}
				sessionStorage.setItem("@CHEF:editandoPizza", incryptText(JSON.stringify(editando)));
			} else {
				sessionStorage.removeItem("@CHEF:editando");
				sessionStorage.removeItem("@CHEF:editandoPizza");
			}
		};
		//FIM

		(async () => {
			try {
				// window.addEventListener("load", handleAttComboScreen);
				const isEditando = sessionStorage.getItem("@CHEF:editando");
				if (isEditando) {
					//Adiciona o valor total ao editar combo
					let localSubTotal = 0;
					let localCompTotal = 0;
					itens.forEach(item => {
						item.adicionais.forEach(element => {
							localCompTotal += element.isChecked ? element.valor : 0;
						});
						localSubTotal += item.valor;
					});
					setCompTotal(localCompTotal)
					setSubtotal(localSubTotal);
					//FIM
				} else { handleCalculateTotal(); }
				await getProductById(header, id, null, true);
				if (comboCategories.length === 0) {
					const resp = await getComboCategories(header, id, tipo);
					setNumberFatias(
						resp[0].maximo_escolhas > 0 ? resp[0].maximo_escolhas - 1 : 0
					);
					sessionStorage.setItem(
						"CHEF:numberFatia",
						String(resp[0].maximo_escolhas - 1)
					);
					sessionStorage.setItem("@CHEF:saboresId", JSON.stringify(resp));
					checkIsEditando();
					return;
				}
				const localNumberFatia = sessionStorage.getItem("CHEF:numberFatia");
				if (localNumberFatia) setNumberFatias(Number(localNumberFatia));
			} catch (err) {
				setShowLoading(false);
				createGlobalError(err);
			}
		})();
		// return () => window.removeEventListener("load", handleAttComboScreen);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itens]);

	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="Ok"
					onClose={handleGoBack}
				/>
			</div>
		);
	}

	if (!comboCategories || showLoading) {
		return (
			<div className="default-container">
				<Loading />
			</div>
		);
	}
	return (
		<div className="Combo">
			{comboContextError && (
				<Alert
					message={comboContextError}
					danger={true}
					button="Ok"
					onClose={clearComboContext}
				/>
			)}

			{isRestaurantClose && (
				<Alert
					message={"No momento estamos fechados!"}
					danger={true}
					button="Ok"
					onClose={handleGoBackInformacoes}
				/>
			)}

			<HeaderDefault label="MONTAGEM DO COMBO" onClick={handleGoBack} />
			{numberFatias === -1 ?
				<div className="default-container">
					<Loading />
				</div> :
				null
			}
			<div className="container">
				<div className="col">
					<ListHeader
						label1={productCombo ? productCombo.nome : "Combo"}
						label2={
							tipo === "pizza"
								? "Toque na imagem abaixo para escolher seu sabor!"
								: ""
						}
						details={true}
					/>
					{tipo === "pizza" ? (
						<div className="categories pizza">
							{numberFatias === -1 ? "" :
								<button
									className="numero-fatias"
									onClick={() =>
										setOpenOptionNumberFatias(!openOptionNumberFatias)
									}
								>
									<span>
										{numberFatias + 1 === 1 ?
											numberFatias + 1 + " SABOR" :
											numberFatias + 1 + " SABORES"
										}
									</span>
								</button>
							}
							{openOptionNumberFatias && (
								<ul className={`container-option-numberfatias`}>
									{comboCategories.map((combo, index) => (
										<OptionNumeroFatias
											key={index.toString()}
											index={index}
											numberFatias={numberFatias}
											onSelectOptionNumberFatia={handleSelectOptionNumberFatias}
										/>
									))}
								</ul>
							)}
							<div
								className="container-forma"
								style={{ backgroundImage: `url(${formaPizza})` }}
								id="pizza"
							>
								<div className="area-pizza">
									{comboCategories.map((item, index) => (
										<div key={index.toString()}>
											<div
												style={{
													backgroundImage: `
												url(${item.hasFinishied
															? pizza
															: index + 1 === 3 && numberFatias + 1 === 3
																? formaPizza
																: ""
														})`,
													top: `
												${numberFatias + 1 !== 4
															? index + 1 === 3 &&
																item.hasFinishied &&
																numberFatias !== 0
																? "-9.8%"
																: index + 1 < 3 || numberFatias === 0
																	? ""
																	: "-13.8%"
															: ""
														}`,
													border: `${item.hasFinishied &&
														index + 1 === 3 &&
														numberFatias + 1 === 3
														? "none"
														: ""
														}`,
												}}
												className={`fatias-pizza ${divisaoPizza(
													numberFatias + 1,
													index,
													item
												)} ${item.hasFinishied ? "borderNone" : ""}
												`}
												onClick={() => selecionaPizza(item)}
											></div>
											{item.sabor_pizza > 0 && (
												<div
													onClick={() =>
														removeProductFromItens(index, item.random)
													}
													className={`${`removeFatia${numberFatias === 0 ? 1 : index + 1
														}${numberFatias + 1 === 4 ? "quarto" : ""} ${item.hasFinishied ? "" : "RemoveFatiahidden"
														}`}`}
												>
													<RiCloseCircleFill size={30} color="red" />
												</div>
											)}
										</div>
									))}
									<span>
										<GiClick size={30} color="#FFF" className="icon" />
									</span>
								</div>
							</div>
							{comboCategoriesBorda.map((item, index) => (
								<ButtonChangeCombo
									key={index.toString()}
									onClick={() =>
										history.push(
											`/${header}/combo-products/${tipo}/${item.id}/${id}/${item.random}/${item.obrigatorio}`
										)
									}
									item={item}
									hasClassName={true}
									index={index}
									editComboItem={handleEditItem}
									isPizza={true}
								/>
							))}
						</div>
					) : (
						<div className="categories">
							{comboCategories.map((item, index) => (
								<ButtonChangeCombo
									key={index.toString()}
									onClick={() =>
										history.push(
											`/${header}/combo-products/${tipo}/${item.id}/${id}/${item.random}/${item.obrigatorio}`
										)
									}
									item={item}
									hasClassName={true}
									index={index}
									editComboItem={handleEditItem}
								/>
							))}
						</div>
					)}
					{itens.length > 0 && tipo === "pizza" && (
						<>
							<div className="container-itens-pizza">
								{comboCategories
									.filter((item) => item.hasFinishied)
									.map((item, index) => (
										<ButtonChangeCombo
											key={index.toString()}
											onClick={() =>
												history.push(
													`/${header}/combo-products/${tipo}/${item.id}/${id}/${item.random}`
												)
											}
											item={item}
											hasClassName={true}
											index={index}
											isPizza={true}
											editComboItem={handleEditItem}
										/>
									))}
							</div>
						</>
					)}
				</div>

				<div className="col">
					{(!ficha || info?.apenas_visualizar_produtos_smart !== 1) &&
						<>
							<ListHeader label1="ALGUM COMENTÁRIO?" details={true} />
							{/* <div className="section"> */}
							<div className="textarea">
								<textarea
									id="description"
									placeholder="Coloque a sua observação aqui..."
									value={obs}
									onChange={(event) => {
										if (event.target.value.length <= 200)
											setObs(event.target.value)
									}}
								></textarea>
							</div>
						</>
					}
					{/* </div> */}
				</div>
			</div>
			{(!ficha || info?.apenas_visualizar_produtos_smart !== 1) &&
				<div className="floating-container">
					<IncreaseDecreaseButtons
						value={quant}
						onIncrease={() => setQuant(quant + 1)}
						onDecrease={() => setQuant(quant === 0 ? 0 : quant - 1)}
						changeQuantity={handleSetQuantity}
					/>
					<button
						className="btn btn-primary"
						disabled={
							comboCategories.filter((item) => item.required).length >
							itens.length || quant === 0
						}
						onClick={handleAction}
					>
						<span>ADICIONAR</span>
						<span>{formatValue((subtotal + compTotal) * quant)}</span>
					</button>
				</div>
			}
			<div></div>
		</div>
	);
};

export default Combo;
