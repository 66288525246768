import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../screens/Home";
import Cart from "../screens/Cart";
import FinishCart from "../screens/FinishCart";
import Combo from "../screens/Combo";
import Success from "../screens/Success";
import NotFound from "../screens/NotFound";
import ComboProducts from "../screens/ComboProducts";
import ProductDetails from "../screens/ProductDetails";
import Informacoes from "../screens/Informacoes";
import FinishCartNoGeo from "../screens/FinishCartNoGeo";

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route path="/:header" component={Home} exact />
			<Route path="/:header/ficha/:type/:number" component={Home} />
			<Route path="/:header/+itens/:notrender" component={Home} />
			<Route path="/:header/informacoes" component={Informacoes} />
			<Route
				path="/:header/details/:id/:combo/:tipo/:defaultId/:random/:edit"
				component={ProductDetails}
			/>
			<Route
				path="/:header/details/:id/:combo/:tipo/:defaultId/:random"
				component={ProductDetails}
			/>
			<Route path="/:header/details/:id/:editar" component={ProductDetails} />
			<Route path="/:header/details/:id" component={ProductDetails} />
			<Route path="/:header/cart" component={Cart} />
			<Route path="/:header/FinishCart" component={FinishCart} />
			<Route path="/:header/FinishCartNoGeo" component={FinishCartNoGeo} />
			<Route path="/:header/combo/:tipo/:id/:editar" component={Combo} />
			<Route path="/:header/combo/:tipo/:id" component={Combo} />
			<Route
				path="/:header/combo-products/:tipo/:id/:defaultId/:random/:required"
				component={ComboProducts}
			/>
			<Route
				path="/:header/combo-products/:tipo/:id/:defaultId/:random"
				component={ComboProducts}
			/>
			<Route path="/:header/success" component={Success} />

			<Route component={NotFound} />
		</Switch>
	);
};

export default Routes;
