import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AppProvider } from "./app/contexts/AppContext";
import { OrderProvider } from "./app/contexts/OrderContext";
import { ComboProvider } from "./app/contexts/ComboContext";
import { ProductProvider } from "./app/contexts/ProductContext";

import "./index.css";

ReactDOM.render(
	<React.StrictMode>
		<AppProvider>
			<ProductProvider>
				<OrderProvider>
					<ComboProvider>
						<Router>
							<App />
						</Router>
					</ComboProvider>
				</OrderProvider>
			</ProductProvider>
		</AppProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
