import React, { useContext, useEffect, useState } from 'react';
import { PiWarning, PiCircleFill, PiCopy } from "react-icons/pi";
import { ImSpinner6 } from "react-icons/im";
import { formatValue, getHourAndMinutes } from '../utils/functions';
import server from "../services/newServer";
import AppContext, { AppContextType } from '../contexts/AppContext';

interface PaymentProps {
    payment: any;
    onClose: Function | any;
    onSuccess: Function | any;
}

const statusPagamento = {
    "pending": "Pendente",
    "in_process": "Pendente",
    "approved": "Aprovado",
    "rejected": "Rejeitado",
}

const PaymentStatus: React.FC<PaymentProps> = ({
    payment,
    onClose,
    onSuccess,
}) => {
    const {
        info
    } = useContext<AppContextType>(AppContext);
    const [paymentInfo, setPaymentInfo] = useState<any>();
    const [fetching, setFetching] = useState(false);
    const [closed, setClosed] = useState(false);

    const getPaymentInfo = async () => {
        if (!fetching && !closed) {

            setFetching(true);
            const resp = await server.get(`/process_payment/${payment}/${info?.key_mercadopago}`);
            setPaymentInfo(resp.data);

            console.log(resp.data);

            if (resp.data.status === "approved") {
                onSuccess();
            }

            if (resp.data.status === "rejected") {

            }

        }
    }

    useEffect(() => {
        getPaymentInfo();
        const intervalId = setInterval(() => {
            getPaymentInfo();
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const copiarParaClipboard = () => {
        navigator.clipboard.writeText(String(paymentInfo?.chave_pix));
    }

    return (
        <div className='payment-status-container'>
            <div className='payment-status-modal'>
                <div className='payment-status-title' >
                    <div style={{ backgroundColor: paymentInfo?.status === "rejected" ? 'red' : 'orange', height: 30, borderTopRightRadius: '8px', borderTopLeftRadius: '8px' }}>
                        {paymentInfo?.status !== "rejected" ?
                            <PiCircleFill size={60} color={'orange'} stroke='white' strokeWidth={15}>
                                <PiWarning size={50} color='white' />
                            </PiCircleFill>
                            : <PiCircleFill size={60} color={'#7E7E7E'} fill='red' stroke='white' strokeWidth={15} />
                        }
                    </div>
                    <h1>{statusPagamento[paymentInfo?.status ? paymentInfo.status : 'pending']}</h1>
                    <p>Expira as: {paymentInfo ? getHourAndMinutes(paymentInfo?.expiration) : '00:00'}</p>
                </div>
                <div className="payment-status-modal-info">
                    <div>
                        <p>{formatValue(paymentInfo?.amount ? paymentInfo.amount : 0)}</p>
                        {paymentInfo?.chave_pix ?
                            <span>Pagar com pix</span>
                            : <span>Esperando aprovação.</span>
                        }

                    </div>
                    {paymentInfo?.status === "rejected" &&
                        <div>
                            <p>Erro:</p>
                            <span>Pagamento rejeitado, verifique os dados do cartão e tente novamente</span>
                        </div>}
                    {paymentInfo?.chave_pix &&
                        <div>
                            <p className='warning-payment-pix-header'>Atenção:</p>
                            <span>
                                <b className='warning-payment-pix'>Após a finalização do pagamento no app do banco, retorne a esta pagina para finalizar o pedido.</b>
                            </span>
                            <span className='payment-status-pix'>
                                <input value={paymentInfo ? paymentInfo?.chave_pix : 'carregando...'} onChange={() => { }} />
                                <button onClick={copiarParaClipboard}>
                                    <PiCopy size={25} color='white' />
                                    Copiar código
                                </button>
                            </span>
                        </div>
                    }

                    <span className='payment-status-footer'>
                        {(paymentInfo?.chave_pix || paymentInfo?.status === "rejected") &&
                            <button className='payment-status-cancel' onClick={() => {
                                setClosed(true);
                                onClose();
                            }}>Cancelar</button>}
                        <span className='waiting-payment'>Aguardando <ImSpinner6 className='icn-spinner' size={20} /></span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PaymentStatus;
