import React, { useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import ProductContext, { ProductContextType } from "../contexts/ProductContext";

import "./styles.css";

const NotFound: React.FC<RouteComponentProps> = ({ history }) => {
	const { clearAppContext } = useContext<AppContextType>(AppContext);
	const { clearOrderContext } = useContext<OrderContextType>(OrderContext);
	const { clearProductContext } =
		useContext<ProductContextType>(ProductContext);

	const init = async () => {
		clearAppContext();
		clearOrderContext();
		clearProductContext();

		/*setTimeout(() => {
			if (window.location.href.toString().indexOf(":3006") > 0) {
				history.push(`/seurestaurante`);
			} else {
				window.location.href = "https://www.voatro.com.br/";
			}
		}, 2500);*/
	};

	useEffect(() => {
		try {
			init();
		} catch (err) {
			init();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className="indexHeader">
				<h1>VOAMENU</h1>
				<p>Um sistema inovador que oferece uma experiência completa e integrada para o seu restaurante. Compatível com os sistemas operacionais iOS e Android, garantimos que você possa alcançar um público amplo de usuários de smartphones.</p>

				<a href="#sobre">
					Saiba mais!
				</a>
			</div>
			<div className="headerWave">
				<img alt="separador" src="https://i.imgur.com/kvHM37e.png" />
			</div>
			<div className="indexContent">
				<h1 id="sobre">SOBRE</h1>
				<div>
					<span>Nossa plataforma é projetada para estabelecer um relacionamento sólido com os clientes, proporcionando a eles a melhor experiência possível ao interagir com o seu restaurante. Por meio de um link exclusivo com o nome do seu negócio, seus clientes poderão acessar facilmente o nosso sistema e desfrutar de todos os recursos e benefícios oferecidos.</span>
				</div>
				<h1>DELIVERY</h1>
				<div>
					<img alt="foto voamenu delivery" src="https://www.voatro.com.br/wp-content/uploads/2023/01/delivery-1-1.png" />
					<span>Cardápio digital integrado com frente de caixa Voatro. Receba os pedidos automaticamente, livre de taxas e comissões. Tenha seu cardápio online e turbine suas vendas.</span>
				</div>

				<h1>SMART</h1>
				<div>
					<img alt="foto voamenu smart" src="https://www.voatro.com.br/wp-content/uploads/2023/01/voamenusmart-1-1.png" />
					<span>Na mesa, ou na comanda. Seu cliente através de QR Code, acessa seu cardápio digital e faz o pedido. Sem investimento em equipamentos, redução de equipe e cardápio atualizado em tempo real.</span>
				</div>
				<h1>SAIBA MAIS</h1>
				<div>
					<span>Não perca a oportunidade de melhorar o desempenho do seu negócio. Visite nosso site <a href="https://www.voatro.com.br/">voatro.com.br</a>, e descubra como podemos transformar a maneira como você interage com os seus clientes e gerencia o seu restaurante.</span>
				</div>
			</div>

			<div className="indexFooter">
				<p>(48) 3052-3075</p>
				<p>contato@voatro.com.br</p>
				<a href="https://www.voatro.com.br/">https://www.voatro.com.br/</a>
				<p>Bruning Sistemas e Comercio De Informatica Ltda</p>
			</div>
		</div>
	);
};

export default NotFound;
