import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import { RiMapPin2Fill } from "react-icons/ri";

type CartAddressProps = {
  color: string
  onPress(): void
  isRetirada: boolean
};

const CartAddress: React.FC<CartAddressProps> = ({children,color, onPress, isRetirada}) => {
	return (
		<div className="cart-address">
			<div className="cart-address-row">
				<RiMapPin2Fill size={24}  color={color}/>
				<div className="cart-address-info">
					{children}
				</div>
				</div>
        {!isRetirada && (
        <div onClick={onPress}><FaPencilAlt size={25} color="#757575"/></div>
        )}
		</div>
	);
};

export default CartAddress;
