import React, { useContext, useEffect, useState } from "react";
import "./styles.css";

import { Observation } from "../utils/types";
import { decryptText } from "../utils/functions";
import AppContext, { AppContextType } from "../contexts/AppContext";

type ObservationProps = { item: Observation };

const ObsItem: React.FC<ObservationProps> = ({ item }) => {
	const { info, ficha } = useContext<AppContextType>(AppContext);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [fetched, setFetched] = useState(false)

	// EFFECT DE TRAZER INFORMACOES AO EDITAR
	useEffect(() => {
		if (!fetched) {
			const handleToggle2 = () => {
				setIsChecked(!isChecked);
				item.isChecked = !isChecked;
			};
			const editando = decryptText(sessionStorage.getItem("@CHEF:editando"));
			const cartItens = decryptText(sessionStorage.getItem("@CHEF:cart"));
			const comboItens = decryptText(sessionStorage.getItem("@CHEF:itensCombo"));
			if (cartItens) {
				const cartItensObj = JSON.parse(cartItens);
				const editandoObj = JSON.parse(editando);
				cartItensObj[editandoObj?.item]?.observacoes.forEach(observacao => {
					if (observacao.id_obs === item.id_obs && observacao.isChecked) {
						handleToggle2();
					}
				});
			}
			if (comboItens) {
				const comboItensObj = JSON.parse(comboItens);
				comboItensObj.forEach(iten => {
					iten.observacoes.forEach(observacao => {
						if (observacao.id_obs === item.id_obs && observacao.isChecked) {
							handleToggle2();
						}
					});
				});
			}
			setFetched(true);
		}
	}, [item, isChecked, fetched])
	// FIM

	const handleToggle = () => {
		setIsChecked(!isChecked);
		item.isChecked = !isChecked;
	};

	return (
		<div className="ObsItem">
			<div className="label">
				<span>{item.descricao}</span>
			</div>
			{(!ficha || info?.apenas_visualizar_produtos_smart !== 1) && <input type="checkbox" checked={isChecked} onChange={handleToggle} />}
		</div>
	);
};

export default ObsItem;
