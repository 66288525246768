import React, { useContext, useEffect, useState } from "react";
import "./styles.css";

import { Answer } from "../utils/types";
import { animationSwing, decryptText, formatValue } from "../utils/functions";
import AppContext, { AppContextType } from "../contexts/AppContext";


interface CompItemProps {
    item: Answer;
    maxEscolhas: number | null;
    pergunta: any | null;
    onToggle(value: number, removed: boolean, qtdade?: number): void;
}

const CompItemAnswer: React.FC<CompItemProps> = ({ item, onToggle, maxEscolhas, pergunta }) => {
    const { info, ficha } = useContext<AppContextType>(AppContext);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [loaded, setLoaded] = useState(true);
    const [visivel, setVisivel] = useState<any>(null);
    const [fetched, setFetched] = useState(false)

    // EFFECT DE TRAZER INFORMACOES AO EDITAR
    useEffect(() => {
        if (!fetched) {
            const handleToggle2 = () => {
                try {
                    if (item.visivel > 0 || !item.visivel) {
                        setIsChecked(!isChecked);
                        item.isChecked = !isChecked;
                        onToggle(item.preco_produto, isChecked, item.qtdade);
                    }
                } catch (error) {
                    console.log(error)
                }
            };
            const editando = decryptText(sessionStorage.getItem("@CHEF:editando"));
            const cartItens = decryptText(sessionStorage.getItem("@CHEF:cart"));
            const comboItens = decryptText(sessionStorage.getItem("@CHEF:itensCombo"));
            if (cartItens) {
                const cartItensObj = JSON.parse(cartItens);
                const editandoObj = JSON.parse(editando);

                cartItensObj[editandoObj?.item]?.perguntas.forEach(pergunta => {
                    pergunta.answers.forEach(resposta => {
                        if (resposta.id_pergunta_item === item.id_pergunta_item && resposta.isChecked) {
                            handleToggle2();
                        }
                    });
                });

            }
            if (comboItens) {
                const comboItensObj = JSON.parse(comboItens);
                comboItensObj.forEach(iten => {
                    iten.perguntas.forEach(pergunta => {
                        pergunta.answers.forEach(resposta => {
                            if (resposta.id_pergunta_item === item.id_pergunta_item && resposta.isChecked) {
                                handleToggle2();
                            }
                        });
                    });
                });
            }
            setFetched(true);
        }
    }, [item, isChecked, fetched, onToggle])
    // FIM

    useEffect(() => {
        setTimeout(() => {
            if (visivel === null) {
                setLoaded(!loaded);
                if (item.visivel !== undefined) setVisivel(item.visivel)
            }
        }, 200);

    }, [loaded, item.visivel, visivel])


    const handleToggle = () => {
        const quantRespostasSelecionada = pergunta?.answers?.filter((item) => item?.isChecked);
        if (
            maxEscolhas && maxEscolhas < quantRespostasSelecionada?.length + 1 && !isChecked
        ) {
            return;
        }
        if (item.visivel > 0 || !item.visivel) {
            setIsChecked(!isChecked);
            if (item.preco_produto) {
                animationSwing("valueAnimation");
            }
            item.isChecked = !isChecked;
            onToggle(item.preco_produto, isChecked, item.qtdade);
        }
    };


    return (
        <div>
            {item.visivel <= 0 ?
                <div className="unavailable-box">
                    <div className="CompItem">
                        <div className="label">
                            <span>{item.descricao_resposta}</span>
                        </div>
                        <span className="unavailable-tag">Indisponível</span>
                    </div>
                    <div className="ObsItem">
                        <div className="label">
                            {item?.obs_resposta && (
                                <span style={{ fontSize: "11px" }}>({item.obs_resposta})</span>
                            )}
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className="ObsItem">
                        <div className="label">
                            <span>{item.descricao_resposta}</span>
                            {item.preco_produto > 0 &&
                                <span>+ {formatValue(item.preco_produto)}</span>
                            }

                        </div>


                        {(!ficha || info?.apenas_visualizar_produtos_smart !== 1) && <input type="checkbox" checked={isChecked} onChange={handleToggle} />}
                    </div>
                    <div className="ObsItem">
                        <div className="label">
                            {item?.obs_resposta && (
                                <span style={{ fontSize: "11px" }}>({item.obs_resposta})</span>
                            )}
                        </div>
                    </div>
                </>
            }

        </div>
    );
};

export default CompItemAnswer;
