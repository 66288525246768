import React from "react";
import { AiOutlineSearch } from 'react-icons/ai';

interface SearchBarProps {
    onSearch: Function | any;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {

    return (
        <div className="searchBar">
            <AiOutlineSearch size={25} color="#757575" />
            <input
                type={'text'}
                autoComplete="off"
                onChange={(event) => onSearch(event.target.value)}
                onBlur={(event) => { }}
                placeholder={"Buscar..."}
            />
        </div>
    );
};

export default SearchBar;
