import React, { memo, useContext } from "react";
import { FiCameraOff } from "react-icons/fi";

import { ProductItem as ProductItemType, ComboProduct } from "../utils/types";
import { decryptText, formatValue } from "../utils/functions";
import TagsProduct from "./TagsProduct";
import { HiPlusSm } from "react-icons/hi";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import AppContext, { AppContextType } from "../contexts/AppContext";

interface ProductItemProps {
	item: ProductItemType | ComboProduct | any;
	onClick(): void;
	header?: any
}
const ProductItem: React.FC<ProductItemProps> = ({ item, onClick, header }) => {
	const { addProductToCart, changeQuantity } = useContext<OrderContextType>(OrderContext);
	const { info, ficha, indexHorario, createGlobalError } = useContext<AppContextType>(AppContext);

	//Adicionar rapido no carrinho
	const fastAdd = async () => {
		if (info?.deliveryDate[indexHorario]?.aberto) {
			if (
				item.tipo_produto_cheff?.toUpperCase() === "PERSONALIZAVEL" ||
				item.tipo_produto_cheff?.toUpperCase() === "PIZZA" ||
				item.tipo_produto_cheff?.toUpperCase() === "PERSONALIZADO" ||
				item.descricao.toUpperCase().includes("COMBO") ||
				item?.tem_pergunta_obrigatoria > 0 ||
				item?.unidade?.toString() === "KG"
			) {
				onClick();
			} else {
				const cart = decryptText(sessionStorage.getItem("@CHEF:cart"));
				let produto = { ...item, id: item.id_produto, adicionais: [], opcionais: [], perguntas: [], nome: item.descricao, descricao: '' }

				produto.valor = item.preco_voamenu ? item.preco_voamenu : item.preco_avista;
				produto.total = item.preco_voamenu ? item.preco_voamenu : item.preco_avista;
				//console.log(produto)
				if (cart) {
					const cartObj = JSON.parse(cart);
					if (cartObj.length > 0) {
						let alreadyHave: number = 0;
						let itemObj: any = null;
						cartObj.forEach((el, index) => {
							if (el?.id_produto === item.id_produto) {
								alreadyHave++
								itemObj = { index: index, quant: el.quant };
							}
						});

						if (alreadyHave > 0) {
							changeQuantity(itemObj?.index, itemObj?.quant + 1);
						} else {
							addProductToCart(
								produto,
								1,
								0,
								'',
								[],
								[],
							);
						}
					} else {
						addProductToCart(
							produto,
							1,
							0,
							'',
							[],
							[],
						);
					}
				} else {
					addProductToCart(
						produto,
						1,
						0,
						'',
						[],
						[],
					);
				}

			}
		} else {
			createGlobalError(
				`No momento estamos fechados. Tente novamente mais tarde.`
			);
		}
	}

	return (
		<div className="ProductItem" id={`item-${item.id_produto}`}>
			{item.url_imagem ? (
				<div className="container-image" onClick={onClick}>
					<img
						src={item.url_imagem}
						className="image"
						alt={item.descricao}
					/>
				</div>
			) : (
				<div className="container-imageIcone" onClick={onClick}>
					<div className="imageIcone">
						<FiCameraOff size={30} color="#757575" />
					</div>
				</div>
			)}

			<div className="infos" onClick={onClick}>
				<div className="value">
					<span className="nome-produto">
						{item.descricao_completa ? item.descricao_completa : item.descricao}
					</span>
					{item.tipo_produto_cheff !== "PERSONALIZADO" && (
						<div className="value-group">
							{item.preco_exibicao_anterior > 0 && (item.preco_avista > 0 ? item.preco_exibicao_anterior > item.preco_voamenu : item.preco_exibicao_anterior > item.preco_avista) && (
								<span className="riscado">
									{formatValue(item.preco_exibicao_anterior)}
								</span>
							)}

							{item.preco_voamenu > 0 ?
								<div>
									<span>{formatValue(item.preco_voamenu)}</span>
									{item.unidade && <span>/{item.unidade}</span>}
								</div>
								: item.preco_avista > 0 && (
									<div>
										<span>{formatValue(item.preco_avista)}</span>
										{item.unidade && <span>/{item.unidade}</span>}
									</div>
								)
							}
						</div>
					)}
				</div>
				<span>
					{item.obs
						? item.obs.length <= 80
							? item.obs
							: item.obs.substr(0, 100) + "..."
						: null}
					<span className="fastAddDivider"></span>
				</span>
				{item.maior_18 > 0 && <TagsProduct label="Maior de 18 anos" />}
				{item.sem_glutem > 0 && <TagsProduct label="Sem glúten" />}
				{item.glutem > 0 && <TagsProduct label="Contém glúten" />}
				{item.sem_lactose > 0 && <TagsProduct label="Sem lactose" />}
				{item.lactose > 0 && <TagsProduct label="Contém lactose" />}
				{item.vegetariano > 0 && <TagsProduct label="Vegetariano" />}
				{item.vegano > 0 && <TagsProduct label="Vegano" />}
			</div>
			{!item?.id_combo && !(ficha && info.apenas_visualizar_produtos_smart > 0) && !info.ocultar_adicao_rapida && <HiPlusSm className="fastAdd" size={25} color={info.cor_padrao} onClick={fastAdd} />}
		</div>
	);
};

export default memo(ProductItem);
