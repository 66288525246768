import React from 'react';
import { formatValue } from '../utils/functions';
import { Cart } from '../utils/types';
import RowCartItem from './RowCartItem';

type ISmartSuccessItem = {
  item: Cart;
};

const SmartSuccessItem: React.FC<ISmartSuccessItem> = ({
  item
}) => {
  return (
    <div className="SmartSucessItem">
      <div className="CartItem">
        <div className="content">
          <div className="content-amount">
            <span>
              {item?.quant}x
            </span>
          </div>

          <div className="infos">
            <span>{item?.nome}</span>
            <span>{formatValue(item.valor * item.quant)}</span>
            {item.perguntas.map((question) => {
              return question.answers
                .filter((answer) => answer.isChecked)
                .map((answer, index) => (
                  <RowCartItem
                    label={`${question.descricao}.:`}
                    label2={answer.descricao_resposta}
                    key={index.toString()}
                    isItem={true}
                  />
                ));
            })}
            {item.opcionais?.length > 0 &&
              item.opcionais.map(
                (item, index) =>
                  !item.isChecked && (
                    <RowCartItem
                      label="SEM.:"
                      label2={item.descricao_insumo}
                      key={index.toString()}
                      isItem={true}
                    />
                  )
              )}
            {item.adicionais?.length > 0 &&
              item.adicionais.map(
                (item, index) =>
                  item.isChecked && (
                    <RowCartItem
                      label="COM.:"
                      label2={item.descricao_insumo}
                      key={index.toString()}
                      isItem={true}
                    />
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartSuccessItem;