import React, { useEffect, useContext, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";

import Alert from "../components/Alert";
import Loading from "../components/Loading";
import ProductItem from "../components/ProductItem";

import AppContext, { AppContextType } from "../contexts/AppContext";
import ComboContext, { ComboContextType } from "../contexts/ComboContext";

import "./styles.css";
import ProductContext, { ProductContextType } from "../contexts/ProductContext";
import HeaderDefault from "../components/HeaderDefault";

import { useFetchSwr } from "../hooks/useFetchSwr";
import { ComboProduct, ComboProductGroup } from "../utils/types";
import Input from "../components/Input";
import { AiOutlineSearch } from "react-icons/ai";
import { incryptText } from "../utils/functions";

const ComboProducts: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header, tipo, id, defaultId, random, required, editar } = useParams();
	const { data } = useFetchSwr<ComboProductGroup[]>(
		`/combos/${id}/choices`,
		header
	);

	const { globalError } = useContext<AppContextType>(AppContext);
	const {
		comboContextError,
		addProductToComboItens,
		clearComboContextError,
		clearComboProducts,
	} = useContext<ComboContextType>(ComboContext);

	const { getProductById } = useContext<ProductContextType>(ProductContext);
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const [comboFilter, setComboFilter] = useState<any>([]);
	const [filter, setFilter] = useState<ComboProduct[]>([]);
	const [filterVlue, setFilterValue] = useState<string>("");

	const handleGoBack = () => {
		history.push(`/${header}/combo/${tipo}/${defaultId}`);
		clearComboProducts();
	};

	const handleFilterComboProducts = (e) => {
		setFilterValue(e);
		let prev = comboFilter.filter((combo) =>
			combo.descricao.includes(e.toLocaleUpperCase())
		);
		setFilter(prev);
	};

	const handleProductClick = async (idProduto: string, idCombo: string) => {
		setShowLoading(true);
		if (required === 0 || tipo === "pizza") {
			const productLocal = await getProductById(header, idProduto, idCombo);
			addProductToComboItens(productLocal, idCombo, 1, random);
			history.push(`/${header}/combo/${tipo}/${defaultId}`);
		} else {
			history.push(
				`/${header}/details/${idProduto}/${idCombo}/${tipo}/${defaultId}/${random}`
			);
		}
		setShowLoading(false);
		clearComboContextError();
		clearComboProducts();
	};

	useEffect(() => {
		//Se estiver editando cria o session estorage
		const checkIsEditando = () => {
			let editando = null;
			if (editar) {
				editando = {
					editando: true,
					item: editar
				}
				sessionStorage.setItem("@CHEF:editando", incryptText(JSON.stringify(editando)));
			} else {
				if (!tipo) {
					sessionStorage.removeItem("@CHEF:editando");
				}
			}
		};
		//FIM
		let ArrayTest = [];
		if (data) {
			for (const itemCombo of data) {
				for (const filterCombo of itemCombo.options) {
					ArrayTest = [{ ...filterCombo }, ...ArrayTest];
				}
			}
			setComboFilter(ArrayTest);
			checkIsEditando();
		}
		return () => {
			if (history.action === "POP") {
				handleGoBack();
			}
			clearComboContextError();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="Voltar"
					onClose={handleGoBack}
				/>
			</div>
		);
	}

	if (!data) {
		return (
			<div className="default-container">
				<Loading />
			</div>
		);
	}

	return (
		<div className="ComboProducts">
			{comboContextError && (
				<Alert
					message={comboContextError}
					danger={true}
					button="Ok"
					onClose={clearComboContextError}
				/>
			)}
			{showLoading && <Loading />}
			<HeaderDefault label="SELECIONAR" onClick={handleGoBack} />
			<div className="content">
				<div className="container-input-filter">
					<Input
						label="Pesquisar sabor"
						type="text"
						disabled={false}
						value={filterVlue}
						onChangeText={handleFilterComboProducts}
						onBlur={() => { }}
						placeholder=""
					>
						<AiOutlineSearch size={22} className="icon-input" />
					</Input>
					{/* <input type="text" onChange={handleFilterComboProducts}>
					</input> */}
				</div>
				{filterVlue?.length === 0 ? (
					data
						.filter((item) => item.options?.length > 0)
						.map((item, index) => (
							<div key={index.toString()}>
								<div className="list-header" id={item.id_grupo.toString()}>
									<b>
										<span>{item.descricao_grupo}</span>
									</b>
								</div>
								<div className="content-products">
									{item.options.map((combo, index) => (
										<ProductItem
											key={index.toString()}
											item={combo}
											onClick={() =>
												handleProductClick(
													combo.id_produto.toString(),
													combo.id_combo.toString()
												)
											}
										/>
									))}
								</div>
							</div>
						))
				) : (
					<div className="content-products">
						{filter?.map((combo, index) => (
							<ProductItem
								key={index.toString()}
								item={combo}
								onClick={() =>
									handleProductClick(
										combo.id_produto.toString(),
										combo.id_combo.toString()
									)
								}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default ComboProducts;
