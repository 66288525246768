import React, { useContext, useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { decryptText, formatValue } from "../utils/functions";
import AppContext, { AppContextType } from "../contexts/AppContext";

interface FloatingContainerProps {
	sizeCart?: number
	sizeCombo?: number
	total?: number
	label: string
	sizeButton?: boolean
	disable?: boolean
	animation?: string
	increaseButtonOn?: boolean
	incompletCombo?: boolean
	onClick(): void
	isHome?: boolean
}
const FloatingContainer: React.FC<FloatingContainerProps> = ({
	sizeCart,
	sizeCombo,
	total,
	label,
	onClick,
	sizeButton,
	disable,
	animation,
	incompletCombo,
	increaseButtonOn,
	children,
	isHome
}) => {
	const {
		info
	} = useContext<AppContextType>(AppContext);
	const [shakeCart, setSakeCart] = useState(false)
	const [totalItens, setTotalItens] = useState<any>(0);
	//Animation
	useEffect(() => {
		setSakeCart(true);
		setTimeout(() => {
			setSakeCart(false);
		}, 1000);
	}, [sizeCart, sizeCombo, total])

	//Atualizar quantidade no carrinho
	useEffect(() => {
		const cart = JSON.parse(decryptText(sessionStorage.getItem("@CHEF:cart")));
		let quantProdutos = 0
		if (cart) {
			cart.forEach(produto => {
				if (produto?.unidade !== "KG")
					quantProdutos += Number(produto.quant);
				else {
					quantProdutos += 1;
				}
			});
		}
		const combo = JSON.parse(decryptText(sessionStorage.getItem("@CHEF:combos")));
		if (combo) {
			combo.forEach(produto => {
				quantProdutos += Number(produto.quant)
			});
		}
		setTotalItens(quantProdutos);
	}, [sizeCart, sizeCombo, total])


	return (
		<div>
			<div className="floating-container">
				{children}
				<button className={`btn btn-primary ${sizeButton ? 'justify' : ''}`} onClick={onClick} disabled={disable ? disable : false}>
					{sizeCart + sizeCombo > 0 &&
						(
							<div className={shakeCart ? "container-iconeCarrinho shakeCart" : "container-iconeCarrinho"}>
								<div><span>{totalItens}</span></div>
								<AiOutlineShoppingCart size={30} color={info?.cor_padrao_texto ? info.cor_padrao_texto : "#fff"} />
							</div>
						)}
					<span>{label}</span>
					{!incompletCombo && <span id={animation} className={shakeCart ? "container-iconeCarrinho shakeCart" : "container-iconeCarrinho"}>{formatValue(total)}</span>}

				</button>
			</div>
		</div>
	);
};

export default FloatingContainer;
