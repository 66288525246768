import React, { useCallback, useContext, useEffect, useState } from "react";
import { formatValue } from "../utils/functions";
import { Answer, Question } from "../utils/types";
import ListHeader from "./ListHeader";
import { decryptText } from "../utils/functions";
import AppContext, { AppContextType } from "../contexts/AppContext";

type QuestionProps = { item: Question; index: number };

const QuestionItem: React.FC<QuestionProps> = ({ item, index }) => {
	const { info, ficha } = useContext<AppContextType>(AppContext);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [loaded, setLoaded] = useState(true);
	const [visivel, setVisivel] = useState<any>(null);
	const [fetched, setFetched] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			if (visivel === null) {
				setLoaded(!loaded);
				item.answers.forEach(element => {
					if (element.visivel !== undefined) setVisivel(element.visivel)
				});
			}
		}, 200);

	}, [loaded, visivel, item.answers])

	const handleAnswerToogle = useCallback((
		answers: Array<Answer>,
		index: number,
		obrigatorio: number,
		maximo_escolhas: number,
		minimo_escolhas: number
	) => {
		if (answers[index].visivel > 0 || !answers[index].visivel) {
			if (maximo_escolhas < 2 && minimo_escolhas < 2) {
				if (obrigatorio || !answers[index].isChecked) {
					if (!obrigatorio) {
						answers[index].isChecked = !answers[index].isChecked;
						setIsChecked(!isChecked);
						return;
					}
					answers.forEach((answer) => {
						answer.isChecked = false; // Percorre o array de respostas e desmarca todos
					});
					answers[index].isChecked = true; // Marca o selecionado como true
					setIsChecked(!isChecked);
				} else {
					answers[index].isChecked = !answers[index].isChecked;
					setIsChecked(!isChecked);
				}
			} else {
				const selected = answers.filter((answer) => {
					return answer.isChecked;
				});
				if (!answers[index].isChecked) {
					if (selected.length === 0) {
						answers[index].isChecked = true;
						setIsChecked(!isChecked);
					}
					if (
						selected.length + 1 <= maximo_escolhas &&
						!answers[index].isChecked
					) {
						answers[index].isChecked = true;
						setIsChecked(!isChecked);
					}
				} else {
					if (!obrigatorio) {
						answers[index].isChecked = false;
						setIsChecked(!isChecked);
					}
					if (selected.length >= minimo_escolhas) {
						answers[index].isChecked = false;
						setIsChecked(!isChecked);
					}
				}
			}
		}
	}, [isChecked]);

	// EFFECT DE TRAZER INFORMACOES AO EDITAR
	useEffect(() => {
		if (!fetched) {
			const editando = decryptText(sessionStorage.getItem("@CHEF:editando"));
			const cartItens = decryptText(sessionStorage.getItem("@CHEF:cart"));
			const comboItens = decryptText(sessionStorage.getItem("@CHEF:itensCombo"));
			if (cartItens) {
				const cartItensObj = JSON.parse(cartItens);
				const editandoObj = JSON.parse(editando);
				console.log(editandoObj);
				//cartItensObj.forEach((iten) => {
				cartItensObj[editandoObj?.item]?.perguntas.forEach(pergunta => {
					pergunta.answers.forEach(resposta => {
						item.answers.forEach((answers, index) => {
							if (resposta.id_pergunta_item === answers.id_pergunta_item && resposta.isChecked) {
								(() => {
									handleAnswerToogle(
										item.answers,
										index,
										item.obrigatorio,
										item.maximo_escolhas ? item.maximo_escolhas : 0,
										item.minimo_escolhas ? item.minimo_escolhas : 0
									)
								})();


							}
						});

					});
				});
				//});
			}
			if (comboItens) {
				const comboItensObj = JSON.parse(comboItens);
				comboItensObj.forEach(iten => {
					iten.perguntas.forEach(pergunta => {
						pergunta.answers.forEach(resposta => {
							item.answers.forEach((answers, index) => {
								if (resposta.id_pergunta_item === answers.id_pergunta_item && resposta.isChecked) {
									(() => {
										handleAnswerToogle(
											item.answers,
											index,
											item.obrigatorio,
											item.maximo_escolhas ? item.maximo_escolhas : 0,
											item.minimo_escolhas ? item.minimo_escolhas : 0
										)
									})();
								}
							});

						});
					});
				});
			}
			setFetched(true);
		}
	}, [item, isChecked, fetched, handleAnswerToogle])
	// FIM
	return (
		<div className="QuestionItem" id={`pergunta-${item.id_pergunta}`}>
			<div className="composition">
				<ListHeader
					label1={item.descricao}
					label2={
						item.maximo_escolhas > 1
							? `Escolha de ${item.minimo_escolhas} à ${item.maximo_escolhas} itens`
							: item.minimo_escolhas > 1 || item.obrigatorio
								? `Escolha ${item.minimo_escolhas > 1 ? item.minimo_escolhas : 1
								} item`
								: ""
					}
					details={true}
				/>
				{item.answers.map((resposta, index) => (
					resposta.visivel <= 0 ?
						<div className="unavailable-box" key={index.toString()}>
							<div className="ObsItem">
								<div className="label">
									<span>{resposta.descricao_resposta}</span>
								</div>
								<span className="unavailable-tag">Indisponível</span>
							</div>

							<div className="ObsItem">
								<div className="label">
									{resposta?.obs_resposta && (
										<span style={{ fontSize: "11px" }}>({resposta.obs_resposta})</span>
									)}
								</div>
							</div>
						</div>
						:
						<div key={index.toString()}>
							<div className="ObsItem">
								<div className="label">
									<span>{resposta.descricao_resposta}</span>
								</div>
								{resposta.preco_produto > 0 ?
									<span>+ {formatValue(resposta?.preco_produto)}</span> :
									null
								}
								{(!ficha || info.apenas_visualizar_produtos_smart !== 1) &&
									<input
										type="checkbox"
										checked={resposta.isChecked}
										// onChange={() => handleToggle(resposta.id_pergunta_item)}
										onChange={() =>
											handleAnswerToogle(
												item.answers,
												index,
												item.obrigatorio,
												item.maximo_escolhas ? item.maximo_escolhas : 0,
												item.minimo_escolhas ? item.minimo_escolhas : 0
											)
										}
									/>}
							</div>
							<div className="ObsItem">
								<div className="label">
									{resposta?.obs_resposta && (
										<span style={{ fontSize: "11px" }}>({resposta.obs_resposta})</span>
									)}
								</div>
							</div>
						</div>
				))}
			</div>
		</div>
	);
};

export default QuestionItem;
