import { useEffect, useState } from 'react'

function useGeoLocation() {
    const [locationGeo, setLocationGeo] = useState({
      loadead: false,
      coordinates: {lat: -23.533773, lng: -46.625290}
    })

    const onSuccess = (location) => {
      setLocationGeo({
        loadead: true,
        coordinates: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        }
      })
    }
    const onError = (error: any) => {
      setLocationGeo({
        loadead: true,
        /* @ts-ignore */
        error,
      })
    }

    useEffect(()=>{
      if(!("geolocation" in navigator)){
        onError({
          code: 0,
          message: "Geolocation not supported"
        })
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    },[])

  return locationGeo
}

export default useGeoLocation