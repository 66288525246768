import React from "react";

type TagsProductProps = { label: string; };

const TagsProduct: React.FC<TagsProductProps> = ({ label  }) => {
	return (
		<div className="container-tags">
			<span className="">
				{ label }
			</span>
		</div>
	);
};

export default TagsProduct;
