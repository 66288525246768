import React from "react";
import { BiShoppingBag } from "react-icons/bi";
import { FaMotorcycle } from "react-icons/fa";
import { formatHour } from "../utils/functions";

type RowHoursProps = {
	label: string | any;
	horario1: string | any;
	horario2: string | any;
	entrega: number;
	retirada: number;
	focus: boolean;
};

const RowHours: React.FC<RowHoursProps> = ({ label, horario1, horario2, entrega, retirada, focus }) => {
	let condition = {}
	if(focus)	{
		condition = {
			fontFamily: 'default-b',
			color: 'var(--color-gray)'
		}
	}
	return (
		<div className="linhas-horario">
			<div>
				<span className="dia-da-semana" style={{...condition}}>{label !== '' ? `${label}:` : ''}</span>
			</div>
			<div>
				<span className="horarios" style={{...condition}}>
					{horario1 !== "fechado"
						? `${formatHour(horario1)} às ${formatHour(horario2)}`
						: "FECHADO"}
				</span>
			</div>
			<div>
				{entrega === 1 && (
            <span className="badge body horario" style={{...condition}}>
              <FaMotorcycle size={15} color={`${focus ? '#58585a' : '#7E7E7E'}`} style={{marginRight: 5}}/>
              DELIVERY
            </span>
          )}
			</div>
			<div>
			{retirada === 1 && (
            <span className="badge body horario" style={{...condition}}> 
              <BiShoppingBag size={15} color={`${focus ? '#58585a' : '#7E7E7E'}`} style={{marginRight: 5}}/>
              RETIRADA
            </span>
        )}
			</div>
		</div>
	);
};

export default RowHours;
